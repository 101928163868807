import React, { useState } from 'react'
import CategoryWise from './CategoryWise'
import ProductWise from './ProductWise'

const ClosedHistory = () => {
  const [value, setValue] = useState(
    parseInt(localStorage.getItem('templateType') || 0)
  )
  return (
    <>
      {value === 1 ? (
        <CategoryWise setValue={setValue} />
      ) : value === 2 ? (
        <ProductWise setValue={setValue} />
      ) : null}
    </>
  )
}

export default ClosedHistory
