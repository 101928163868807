import React from "react";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './Inventory/Inventory.css'

const IFrame = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const handleMessage = (event) => {
      console.log(event.origin, "orgin");
      if (
        event.origin.toString() === "https://dev.fobesoft.com" ||
        event.origin.toString() === "https://devclone.fobesoft.com"
      ) {
        ValidateTokens(event.data);
      }
    };
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const ValidateTokens = (token) => {
    const header = {
      Authorization: "Bearer " + token,
    };
    axios
      .get("https://testapi.fobesoft.com/Api/User/GetUserClaims", {
        headers: header,
      })
      .then((res) => {
        localStorage.setItem("RestId", res.data.Restaurant_id);
        localStorage.setItem("auth-token", token);
        localStorage.setItem("UserClaims", JSON.stringify(res.data));
        setLoader(false);
        navigate("/");
      })
      .catch((err) => {
        setLoader(false);
        navigate("/");
      });
  };
  return (
    <>
      {loader &&
        <div className="loader-img" style={{ height: '100vh', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
          <img src="https://d2a7u30t6wbkim.cloudfront.net/assets/img/preloader.gif" alt="preloader" />
        </div>
      }
    </>
  );
};

export default IFrame;
