import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
// import "../Inventory/Inventory.css";
import '../Inventory.css'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { DatePicker, Button, Input, Tooltip, Modal } from 'antd'
import { FaTrash, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { NumericFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DataGrid } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { mainAxios } from '../../middleware/interceptor'
import { Logger } from '../../middleware/Logger'
import 'react-toastify/dist/ReactToastify.css'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { CircularProgress } from '@mui/material'
import { StarFilled, HistoryOutlined } from '@ant-design/icons'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Category } from '@mui/icons-material'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#19A73F'
    }
  }
})

const ProductWise = () => {
  const { Search } = Input
  const dateFormat = 'MM/DD/YYYY'
  const [modalopen, setmodelopen] = useState(false)
  const [load, setload] = useState(false)
  const [categoryValues, setCategoryValues] = useState([])
  const [progressBar, setProgressBar] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [InventoryItems, setInventoryItems] = useState([])
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const [nodata, setNodata] = useState(false)
  const { Inventory_date } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')
  const [inventoryDate, setInventoryDate] = useState(dayjs(Inventory_date))
  const [total, setTotal] = useState(0)
  const [BackupData, setBackupData] = useState([])
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  useEffect(() => {
    LoadInventoryItems(RestID, Inventory_date)
  }, [])

  const DeleteInventory = () => {
    setIsModalOpenOne(false)
    mainAxios
      .post('/api/Inventory/delete/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          toast.success('Count sheet Deleted Successfully')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: ''
          }
          Logger(RestID, 'Count sheet Deleted', Details, inventoryId)
          setTimeout(() => {
            navigate('/')
          }, [1500])
        } else {
          toast.info(res.data.Error)
        }
      })
      .catch(err => {
        toast.error('Error while Deleting countsheet')
        console.log(err)
      })
  }
  const handleOkone = () => {
    setIsModalOpenOne(false)
  }
  const handleCancelone = () => {
    setIsModalOpenOne(false)
  }
  const convertDateFormat = inputDate =>
    inputDate
      ? new Date(inputDate).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
      : ''

  const LoadInventoryItems = (RestID, Inventory_date) => {
    setProgressBar(true)
    mainAxios
      .get('/api/Inventory/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          setInventoryId(res.data.Data.InventoryId)
          setIsLoading(false)
          const updated = res.data.Data.InventoryDetails.map(d => {
            return d.Items.map(e => {
              return {
                ...e,
                category: d.CatName,
                catId: d.CatId
              }
            })
          })

          console.log(
            updated.flat().sort((a, b) => {
              if (a.itemorderid && b.itemorderid) {
                return a.itemorderid - b.itemorderid
              } else {
                return 0
              }
            }),
            'fhuhuu'
          )
          setCategoryValues(updated.flat())
          setBackupData(updated.flat())
          if (updated.flat().length == 0) {
            setNodata(true)
          } else {
            setNodata(false)
          }
          setProgressBar(false)
        } else {
          setIsLoading(false)
          setCategoryValues([])
          setBackupData([])
          setProgressBar(false)
          setNodata(true)
        }
      })
      .catch(err => {
        setIsLoading(false)
        setCategoryValues([])
        setBackupData([])
        setProgressBar(false)
        setNodata(true)
        console.log(err)
      })
  }
  function getRowId(row) {
    return row.id
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const CountUpdateOnFocusOut = async (e, currentRow) => {
    //const PreviousCount = await PreviousCountChecker(currentRow)
    const CountUpdates = {
      itemId: currentRow.id,
      catId: currentRow.catId,
      catName: currentRow.category,
      itemName: currentRow.Name,
      itemUnit: currentRow.Unit,
      itemAmount: currentRow.Amount,
      itemCount:
        e.target.value == '0.'
          ? '0'
          : e.target.value == '.'
            ? null
            : e.target.value
    }
    if (currentRow.Count != undefined && currentRow.Count != null) {
      mainAxios
        .post(
          '/api/Inventory/updateItem/' + RestID + '/' + Inventory_date,
          CountUpdates,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        )
        .then(res => {
          if (res.data?.status?.toString() == '1') {
          } else {
          }
        })
        .catch(err => {
          console.log('Count Change API Error : ', err)
        })
    }
  }
  function handleClick(event) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
  }
  const CountValueUpdateonChange = (value, params) => {
    const index = categoryValues.findIndex(
      y => y.id === params?.row?.original.id
    )
    let temp = [...categoryValues]
    const isValidInput = /^(?:\d*\.)?\d{0,5}$/.test(value)
    if (isValidInput) {
      temp[index].Count = value

      const parsedValue = parseFloat(value)
      const parsedPrice = parseFloat(params?.row?.original.Price)

      temp[index].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)
    }
    setCategoryValues(temp)
    CountValueUpdateBackupData(value, params)
  }
  const CountValueUpdateBackupData = (value, params) => {
    const index = BackupData.findIndex(y => y.id === params?.row?.original.id)
    let temp = [...BackupData]
    const isValidInput = /^(?:\d*\.)?\d{0,5}$/.test(value)
    if (isValidInput) {
      temp[index].Count = value

      const parsedValue = parseFloat(value)
      const parsedPrice = parseFloat(params?.row?.original.Price)

      temp[index].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)
    }
    setBackupData(temp)
  }

  const PreviousCountChecker = currentRow => {
    return new Promise((resolve, reject) => {
      const BackUpTemp = [...BackupData]
      const requiredobject = BackUpTemp.filter(obj =>
        obj.Items.some(item => item.id === currentRow.id)
      )

      const categoryIndex = BackUpTemp.findIndex(
        cat => cat.CatId === requiredobject[0].CatId
      )
      const RowIndex = BackUpTemp[categoryIndex].Items.findIndex(
        item => item.id === currentRow.id
      )

      const PrevCount = BackUpTemp[categoryIndex].Items[RowIndex].Count
      BackUpTemp[categoryIndex].Items[RowIndex].Count = currentRow.Count
      setBackupData(BackUpTemp)

      resolve(PrevCount)
    })
  }
  const navigate = useNavigate()
  const showModalforSameDateRestrict = () => {
    setIsModalOpen(true)
  }
  const UpdateDateforInventory = (date, datestring) => {
    let updatedDateObject = {
      CurrentDate: Inventory_date,
      updatedDate: dayjs(datestring).format('YYYY-MM-DD')
    }

    mainAxios
      .post('/api/Inventory/updateDate/' + RestID, updatedDateObject, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          setInventoryDate(dayjs(datestring))
          toast.success('Count sheet Date Updated Successfully')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: updatedDateObject
          }
          Logger(RestID, 'Count sheet Date Updated', Details, inventoryId)
          setTimeout(() => {
            navigate('/countsheet/' + dayjs(datestring).format('YYYY-MM-DD'))
          }, [1500])
        } else {
          if (res.data.Error == 'Inventory Already Exist for this Date') {
            showModalforSameDateRestrict()
            setInventoryDate(dayjs(Inventory_date))
          } else if (res.data.Error === 'Body Required') {
            toast.info('Date required.')
          } else {
            toast.info(res.data.Error)
          }
        }
      })
      .catch(err => {
        toast.error('Error while updating Count sheet Date')
        console.log(err)
      })
  }
  const sortedCategoryValues = Array.isArray(categoryValues)
    ? [...categoryValues].sort((a, b) => {
      if (a.itemorderid && b.itemorderid) {
        return a.itemorderid - b.itemorderid
      } else {
        return 0
      }
    })
    : []
  const columns = [
    {
      accessorKey: 'category',
      header: 'Category',
      size: 125,
      muiTableHeadCellProps: {
        align: 'left'
      },
      muiTableBodyCellProps: {
        align: 'left'
      }
    },
    {
      accessorKey: 'SubCatName',
      header: 'Sub Category',
      flex: 1,
      size: 160
    },
    {
      accessorKey: 'Name',
      header: 'Item',
      flex: 1,
      size: 300,
      Cell: row => {
        if (row.row.original.CreatedAt !== null) {
          const createddate = dayjs(row.row.original.CreatedAt)

          const nextmonth = createddate.add(31, 'day')

          if (dayjs().isBefore(nextmonth)) {
            return (
              <>
                <span>{row.row.original.Name}</span> &nbsp; &nbsp;{' '}
                <StarFilled style={{ color: '#ffd700' }} />
              </>
            )
          } else {
            return (
              <>
                <span>{row.row.original.Name}</span>
              </>
            )
          }
        } else {
          return (
            <>
              <span>{row.row.original.Name}</span>
            </>
          )
        }
      }
    },
    {
      accessorKey: 'Unit',
      header: 'Unit',
      flex: 1,
      size: 100,
      Cell: params => {
        console.log('aa', params)
        return (
          <>
            {params?.row?.original.Name != '' ? (
              <span>{params?.row?.original.Unit}</span>
            ) : (
              <span>
                {params?.row?.original.multiName}(
                {params?.row?.original.multiQnty}&nbsp;
                {params?.row?.original.Unit})
              </span>
            )}
          </>
        )
      }
    },

    {
      accessorKey: 'Price',
      header: 'Price',
      size: 100,
      align: 'left',
      Cell: params => {
        // const formattedPrice = parseFloat(params?.row?.original.Price).toFixed(
        //   2
        // )
        // const formattedNewPrice = params?.row?.original?.NewPrice
        //   ? parseFloat(params?.row?.original.NewPrice).toFixed(2)
        //   : null
        // const isAmountGreaterThanPrice =
        //   params?.row?.original.Amount > params?.row?.original.PreAmount
        // const DifferenceAmount =
        //   (params?.row?.original.Amount == '' ||
        //   params?.row?.original.Amount == null ||
        //   params?.row?.original.Amount == undefined
        //     ? 0.0
        //     : parseFloat(params?.row?.original.Amount).toFixed(2)) -
        //   (params?.row?.original.PreAmount == '' ||
        //   params?.row?.original.PreAmount == null ||
        //   params?.row?.original.PreAmount == undefined
        //     ? 0.0
        //     : parseFloat(params?.row?.original.PreAmount).toFixed(2))
        // const isAmountLessThanPrice =
        //   params?.row?.original.Amount < params?.row?.original.PreAmount

        // const pricecheckhigh =
        //   parseFloat(params?.row?.original.NewPrice) >
        //   parseFloat(params?.row?.original.Price)
        // const pricechecklow =
        //   parseFloat(params?.row?.original.NewPrice) <
        //   parseFloat(params?.row?.original.Price)
        // const Diffprice = params?.row?.original.DiffAmount
        console.log(params.row.original, 'diff')
        return (
          <>
            {currencyFormatter.format(parseFloat(params.row.original?.Price))}
            {params.row.original?.DiffPrice && parseFloat(params.row.original?.DiffPrice) != 0 ?
              (
                params.row.original?.DiffPrice < 0 ?
                  (
                    <Tooltip
                      title={
                        <span style={{ color: '#22af41' }}>
                          ${' '}
                          {parseFloat(params.row.DiffPrice)
                            ? '0.00'
                            : Math.abs(parseFloat(params.row.original?.DiffPrice)).toFixed(2).toString()}
                        </span>
                      }
                    >
                      <SouthIcon
                        style={{
                          fontSize: '15px',
                          marginLeft: ' 2px',
                          color: '#2BAB47',
                          marginTop: '-3px',
                          cursor: 'pointer'
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <span style={{ color: '#ff5653' }}>
                          ${' '}
                          {parseFloat(params.row.original?.DiffPrice).toFixed(2).toString() ==
                            '0'
                            ? '0.00'
                            : parseFloat(params.row.original?.DiffPrice)
                              .toFixed(2)
                              .toString()}
                        </span>
                      }
                    >
                      <NorthIcon
                        style={{
                          fontSize: '15px',
                          marginLeft: ' 2px',
                          color: '#FF5653',
                          marginTop: '-3px',
                          cursor: 'pointer'
                        }}
                      />
                    </Tooltip>)
              ) : null}
          </>
        )
      }
    },
    {
      accessorKey: 'PreCount',
      header: 'Pre Count',
      size: 130,
      flex: 1,
      align: 'left',
      Cell: params => {
        return (
          <>
            {params?.row?.original.Name != '' ? (
              <div>
                {params?.row?.original.PreCount == null ||
                  isNaN(params?.row?.original.PreCount) ||
                  params?.row?.original.PreCount == ''
                  ? '0'
                  : parseFloat(params?.row?.original.PreCount).toFixed(2)}
              </div>
            ) : (
              <div></div>
            )}
          </>
        )
      }
    },
    {
      accessorKey: 'Count',
      header: 'Count',
      size: 130,
      flex: 1,
      Cell: params => {
        return (
          <>
            {params?.row?.original.Name !== '' ? (
              <NumericFormat
                className='custom-input'
                style={{
                  textAlign: 'left',
                  borderWidth: '0.01px',
                  borderStyle: 'double',
                  width: '100%'
                }}
                value={
                  params?.row?.original.Count == null ||
                    isNaN(params?.row?.original.Count)
                    ? ''
                    : params?.row?.original.Count
                }
                onValueChange={e => CountValueUpdateonChange(e.value, params)}
                onBlur={e =>
                  params?.row?.original.Count != null ||
                    params?.row?.original.Count !== '' ||
                    params?.row?.original.Count !== '0'
                    ? CountUpdateOnFocusOut(e, params?.row?.original)
                    : ''
                }
                decimalScale={2}
                allowLeadingZeros={false}
                allowNegative={false}
                valueIsNumericString={true}
              />
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      accessorKey: 'Amount',
      header: 'Value',
      size: 130,
      flex: 1,
      align: 'left',
      Cell: params => {
        return (
          <>
            {params?.row?.original.Name != '' ? (
              <span style={{ fontWeight: '600' }}>
                $
                {params?.row?.original.Count == null ||
                  params?.row?.original.Count == '' ||
                  isNaN(params?.row?.original.Count)
                  ? '0.00'
                  : (
                    parseFloat(params?.row?.original.Price).toFixed(2) *
                    parseFloat(params?.row?.original.Count)
                  ).toFixed(2)}
              </span>
            ) : (
              ''
            )}
          </>
        )
      }
    }
  ]
  const table = useMaterialReactTable({
    columns,
    data: sortedCategoryValues,
    getRowId,
    enablePagination: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableColumnPinning: false,
    autoResetPageIndex: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePinning: false,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableRowSelection: false,
    enableTopToolbar: false,
    columnPinning: false,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 50, 100]
    },
    initialState: { density: 'compact', pagination: { pageSize: 10 } },
    layoutMode: 'semantic',
    state: {
      isLoading
    }
  })
  const [inventoryId, setInventoryId] = useState()

  //const showDrawer = () => {
  //setOpenDrawer(true)
  //GetOpenCountsheetHistory(inventoryId)
  //}
  const [isModalOpenone, setIsModalOpenOne] = useState(false)
  const showModalForConfirmingDelete = () => {
    setIsModalOpenOne(true)
  }
  const onSearchItems = (value, _e, info) => {
    setSearchedValue(value)
    if (value !== '' && value !== undefined && value !== null) {
      const filteredData = BackupData.filter(x =>
        x.Name?.toLocaleLowerCase()?.includes(value?.toLocaleLowerCase())
      )
      if (filteredData.length == 0) {
        setNodata(true)
      } else {
        setNodata(false)
      }
      setCategoryValues(filteredData)
    } else {
      if (BackupData.length == 0) {
        setNodata(true)
      } else {
        setNodata(false)
      }
      setCategoryValues(BackupData)
    }
  }
  const SaveandCloseInventory = () => {
    setload(true)
    mainAxios
      .post('/api/Inventory/status/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.status == '1') {
          setmodelopen(false)
          toast.success(' Inventory has been successfully saved and closed.')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: ''
          }
          Logger(RestID, 'Count sheet Saved and Closed', Details, inventoryId)
          setTimeout(() => {
            navigate('/')
          }, [1500])
        } else {
          setmodelopen(false)
          setload(false)
          toast.info(res.data.Error)
        }
      })
      .catch(err => {
        setmodelopen(false)
        toast.error('Error while Saving and Closing Countsheet')
        console.log(err)
      })
  }
  const handlesaveclosecancel = () => {
    setmodelopen(false)
  }
  const handlesaveclose = () => {
    setmodelopen(true)
  }
  return (
    <>
      <div className='startinventory'>
        <div role='presentation' onClick={handleClick}>
          <Breadcrumbs aria-label='breadcrumb' className='breadcrumb'>
            <Link className='backinvent' href='/' onClick={() => navigate('/')}>
              Inventories
            </Link>
            <Typography className='currentsheet'>New Count Sheet</Typography>
          </Breadcrumbs>
        </div>
        <div className='levelone'>
          <p className='datesheet'>New Count Sheet</p>
          <span
            className='closedbutton'
            style={{ backgroundColor: '#336B97', borderRadius: '50px' }}
          >
            New
          </span>
        </div>
        <div className='flexsearchbut flexsearchbut1'>
          <div className='searchbar searchbar1'>
            <p className='datename'>Inventory Date</p>
            <DatePicker
              style={{ width: '250px' }}
              defaultValue={dayjs(Inventory_date)}
              value={inventoryDate}
              onChange={UpdateDateforInventory}
              format={dateFormat}
            />
          </div>
          <div className='newcountbuttondot newcountbuttondot2'>
            {/* <Button
              style={{
                background: '#22AF41',
                color: '#fff',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={showDrawer}
              icon={
                <HistoryOutlined
                  style={{ fontSize: '15px', marginTop: '1px' }}
                />
              }
            >
              History
            </Button> */}
            <Button
              style={{
                background: '#fff',
                border: '1px solid #E54643',
                color: '#E54643',
                display: 'flex',
                alignItems: 'center'
              }}
              icon={<FaTrash style={{ fontSize: '12px' }} />}
              onClick={() => showModalForConfirmingDelete()}
            >
              Delete
            </Button>
          </div>
        </div>
        {nodata && (
          <div
            className=''
            style={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <img style={{ width: '5em' }} src='/no-item.png' />
              <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
            </div>
          </div>
        )}
        {!nodata && (
          <div
            className='inventory-open'
            style={{
              width: '100%',
              marginTop: '1.5rem',
              marginBottom: '1.5rem'
            }}
          >
            <ThemeProvider theme={customTheme}>
              <MaterialReactTable table={table} />
            </ThemeProvider>

            {/* <DataGrid
            style={{
              borderBottom: '0px',
              borderRight: '0px',
              borderRadius: '0px'
            }}
            rows={
              Array.isArray(categoryValues)
                ? [...categoryValues].sort((a, b) => {
                    if (a.itemorderid && b.itemorderid) {
                      return a.itemorderid - b.itemorderid
                    } else {
                      return 0
                    }
                  })
                : []
            }
            columns={columns}
            getRowId={getRowId}
            disableRowSelectionOnClick
            disableColumnMenu
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } }
            }}
            pageSizeOptions={[5, 10, 25, 100]}
            density='compact'          
          /> */}
          </div>
        )}
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          width: '100%',
          backgroundColor: '#fff',
          zIndex: '9999'
        }}
      >
        {!isModalOpen && <div className='onetwoline'>
          <div className='footercountsheet'>
            <div className='totalfoot'>
              <div>
                <p className='footpara'>
                  Total : $
                  {BackupData.reduce((acc, curr) => {
                    const amount = parseFloat(curr?.Amount)
                    if (!isNaN(amount)) {
                      return acc + amount
                    } else {
                      return acc
                    }
                  }, 0).toFixed(2)}
                </p>
              </div>
              <div>
                <Search
                  className='search'
                  placeholder='Search'
                  onChange={e => onSearchItems(e.target.value)}
                  enterButton
                  allowClear={true}
                />
              </div>
              <div className='cursorPointer'>
                <FaAngleLeft
                  style={{
                    color: '#22AF41',
                    marginTop: '-4px',
                    marginRight: '1px'
                  }}
                />
              </div>
              <div className='cursorPointer'>
                <FaAngleRight
                  style={{
                    color: '#22AF41',
                    marginTop: '-4px',
                    marginLeft: '1px'
                  }}
                />
              </div>
            </div>
            <div className='threebuttons'>
              <Button
                style={{
                  border: '1px solid #DDD',
                  backgroundColor: '#F5F5F5',
                  color: '#212529'
                }}
                onClick={() => toast.success('Count sheet has been updated')}
                disabled={nodata}
              >
                Save
              </Button>
              <Button
                style={{
                  border: '1px solid #2BAB47',
                  backgroundColor: '#2BAB47',
                  color: '#fff'
                }}
                onClick={() => setmodelopen(true)}
                loading={load}
                disabled={nodata}
              >
                Save and Close
              </Button>
              <Button
                style={{
                  border: '1px solid #DDD',
                  backgroundColor: '#F5F5F5',
                  color: '#212529'
                }}
                onClick={() => navigate('/')}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>}
      </div>
      <Modal
        title='Delete'
        open={isModalOpenone}
        onOk={handleOkone}
        onCancel={handleCancelone}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancelone}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={DeleteInventory}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Confirm deletion of the inventory taken on{' '}
          {Inventory_date
            ? Inventory_date
              ? convertDateFormat(Inventory_date)
              : ''
            : ''}
          ?
        </p>
      </Modal>
      <Modal
        title='Warning!'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
            onClick={handleOk}
          >
            Got It
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Cannot save two inventories on the same date. Please select a
          different date.
        </p>
      </Modal>
      <Modal
        title='Close Inventory'
        open={modalopen}
        onOk={handlesaveclose}
        onCancel={handlesaveclosecancel}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handlesaveclosecancel}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={SaveandCloseInventory}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Confirm closing of the inventory taken on{' '}
          {Inventory_date
            ? Inventory_date
              ? convertDateFormat(Inventory_date)
              : ''
            : ''}
          ?
        </p>
      </Modal>
    </>
  )
}
export default ProductWise
