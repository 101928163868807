import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  PlusCircleFilled,
  SearchOutlined,
  UploadOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import { Input, Button, Modal, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from '@mui/material/Typography'
import { FaEllipsisV } from "react-icons/fa";
import { mainAxios } from "../middleware/interceptor";
import {Logger} from "../middleware/Logger"
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "antd";
import Print from './Print';
const { RangePicker } = DatePicker;

const data = [];
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#19A73F",
    },
  },
});
const HistoryTable = () => {
  const UserClaims = localStorage.getItem('UserClaims')
  const [Budget_Exist_Status, setBudget_Exist_Status] = useState(JSON.parse(UserClaims).Budget_Exist_Status);
  const[IsModalOpenbudget,setIsModalOpenbudget]=useState(false);
  const items = [
    {
      key: "1",
      label: (
        <span className="dotoption" onClick={Budget_Exist_Status==1?() => handleExcelDownload():()=>setIsModalOpenbudget(true)}>
          <UploadOutlined
            style={{ fontSize: "15px", color: "#22AF41", marginRight: "15px" }}
          />{" "}
          Export
        </span>
      ),
    },

    {
      key: "2",
      label: (
        <span className='dotoption' onClick={Budget_Exist_Status==1?() => printRef.current.handlePrint():() =>setIsModalOpenbudget(true)}>
          <PrinterFilled
            style={{ fontSize: "15px", color: "#22AF41", marginRight: "15px" }}
          />{" "}
          Print
        </span>
      ),
    },
  ];

  const currentDate = new Date();

  const Inventory_date =
    currentDate.getFullYear() +
    "-" +
    ("0" + (currentDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + currentDate.getDate()).slice(-2);
const[inventory,setinventory]=useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenone, setIsModalOpenOne] = useState(false);
  const[IsModalOpentwo,setIsModalOpentwo]=useState(false);
  const [HistoryData, setHistoryData] = useState(data);
  const [HistoryDate, setHistoryDate] = useState([]);
  const [backupData, setBackupData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [load, setload] = useState(false);
  const [RestID, setRestId] = useState(localStorage.getItem("RestId"));
  const [columnFilters, setColumnFilters] = useState();
  const navigate = useNavigate();
  const printRef = useRef();

  useEffect(() => {
    IsInventoryFeatureAllowed();
    GetHistoryData();
    GetTemplateType();
  }, []);

  const GetTemplateType =()=>{
    mainAxios
    .get('/api/Inventory/GetInventoryTemplate/' + RestID)
    .then(res => {
      console.log("ressss",res)
      if (res.data?.Status.toString() === '1') {
        localStorage.setItem("templateType",res.data?.CurrentTemplate)
      } else {
        if(res.data?.message == "No Template Type Defined Yet")
        {
          ChangeTemplateType(1)
        }
        else{
          toast.error('Failed to fetch Template')
        }
      }
    })
    .catch(() => {
      toast.error('Failed to fetch Template')
    })
  }

  const ChangeTemplateType =(TemplateType)=>{
    mainAxios
    .post('/api/Inventory/ChangeInventoryTemplate/' + RestID+'/'+TemplateType)
    .then(res => {
      console.log("ressss",res)
      if (res.data?.Status.toString() === '1') {
        localStorage.setItem("templateType",res.data?.CurrentTemplate)
      } else {
        toast.error('Failed to Change Template type')
      }
    })
    .catch(() => {
      toast.error('Failed to Change Template type')
    })
  }

  const GetHistoryData = () => {
    if(Budget_Exist_Status==1)
    { 
    mainAxios
      .post("/api/Inventory/History/" + RestID + "/" + Inventory_date)
      .then((response) => {
        if (response.data.status == 1) {
          let backup = [];
          response.data.history_details.forEach((item) => {
            backup.push({
              ...item,
              date: dayjs(item.date).format("MM/DD/YYYY"),
            });
          });
          setHistoryData(backup);
          setBackupData(backup);
          setIsLoading(false);
          setHistoryDate(backup);
        } else {
          setBackupData([]);
          setHistoryData([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setBackupData([]);
        setHistoryData([]);
        setIsLoading(false);
        console.log(err);
      });}
  };

  const handleExcelDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("HistoryDetails");

    const headerRow = worksheet.addRow([
      "Date",
      "Status",
      "Food",
      "Others",
      "Total",
    ]);
    headerRow.font = { bold: true };
    HistoryData.forEach((item) => {
      worksheet.addRow([
        item.date,
        item.status,
        item.food,
        item.others,
        item.total,
      ]);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "InventoryHistory.xlsx";
      link.click();
    });
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  const handleSearch = (e) => {
    if(Budget_Exist_Status==1){
    if (e !== "" && e !== undefined && e !== null) {
      const filteredData = backupData.filter(
        (x) =>
          x.date.includes(e) ||
          x.status.includes(e) ||
          x.food.toString().includes(e) ||
          x.others.toString().includes(e) ||
          x.total.toString().includes(e) ||
          x.status.toLowerCase().includes(e.toLowerCase())
      );

      setHistoryData(filteredData);
    } else {
      setHistoryData(backupData);
    }}
    else{
      setIsModalOpenbudget(true)
    }
  };

  const IsInventoryFeatureAllowed = () => {
    let latdata=[]
    if(Budget_Exist_Status==1){
      setIsModalOpenbudget(false);
    mainAxios
      .get("/api/Inventory/null/" + RestID)
      .then((res) => {
        setIsLoading(false);
       res.data.Data.InventoryDetails.forEach(inventories => {
       inventories.Items.forEach((item) => {
       return latdata.push(item)
      })

        })
        console.log(data,'data')
        console.log(latdata,'latdata')
        setinventory(latdata)
                if (latdata.length == 0) {
          setIsModalOpentwo(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    }
    else{
        setIsModalOpenbudget(true)
    }
  };

  const CreateNewCountSheet = () => {
    const isOpen = backupData.filter((item) => item.status === "Open").length;
    if (isOpen === 0) {
      setload(true);
      const osVersionMatch = navigator.userAgent.match(
        /(Windows NT|iPhone|iPad|Android)[^\d]*([\d._]+)/
      );

      console.log(navigator.userAgent)
      

      let DeviceInfo = {
        DeviceDetails: {
          app_ver: navigator.appVersion,
          api_url: mainAxios.defaults.baseURL,
          device_id: "From Web",
          device_os: navigator.platform,
          os_version: osVersionMatch && osVersionMatch[2]  ? osVersionMatch[2] : null,
        },
      };

      mainAxios
        .post(
          "/api/Inventory/CreateInventory/" + Inventory_date + "/" + RestID,
          DeviceInfo,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.data.status == 1) {
            let Details ={
              InventoryDate : Inventory_date,
              Payload : DeviceInfo
           }
           
           Logger(RestID,"Countsheet Created",Details,res.data.inventoryid)
            setTimeout(() => {
              setload(false);
              navigate("/countsheet/" + Inventory_date);
            }, [1500]);
          } else {
            setload(false);
            setIsModalOpenOne(true);
            ///comment this after binding
            // setTimeout(() => {
            //   navigate("/countsheet/" + Inventory_date)
            // }, [1500])
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.log(err);
          setload(false);
        });
    } else {
      showModal();
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOkprod = () => {
    setIsModalOpentwo(false);
  };

  const handleOkprodbudget = () => {
    setIsModalOpenbudget(false);
  };

  const handleCancelprod = () => {
    setIsModalOpentwo(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkone = () => {
    setIsModalOpenOne(false);
  };

  const handleCancelone = () => {
    setIsModalOpenOne(false);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const RowClickNavigator = (row) => {
    if (row.original.status === "Closed") {
      navigate("/History/" + dayjs(row.original.date).format("YYYY-MM-DD"));
    } else {
      navigate("/countSheet/" + dayjs(row.original.date).format("YYYY-MM-DD"));
    }
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.date,
        id: "date",
        header: "Date",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },

        muiTableBodyCellProps: {
          align: "left",
        },
        filterFn: "contains",
        Cell: ({ row }) => dayjs(row.original.date).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterVariant: "select",
        filterSelectOptions: [
          { label: "Select", value: "" },
          { label: "Open", value: "Open" },
          { label: "Closed", value: "Closed" },
        ],
        Cell: ({ row }) => {
          return (
            <span
              style={{
                cursor: "pointer",
                color: row.original.status === "Closed" ? "#22AF41" : "#9B9B9B",
              }}
            >
              {row.original.status}
            </span>
          );
        },
      },
      {
        accessorKey: "food",
        header: "Food",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          return <span>{currencyFormatter.format(row.original.food)}</span>;
        },
      },
      {
        accessorKey: "others",
        header: "Others",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          return <span>{currencyFormatter.format(row.original.others)}</span>;
        },
      },
      {
        accessorKey: "total",
        header: "Total",
        size: 150,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => {
          return (
            <span style={{ fontWeight: "600" }}>
              {currencyFormatter.format(row.original.total)}
            </span>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: HistoryData,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding:false,
    enableGlobalFilter:false,
    initialState: { density: "compact", pagination: { pageSize: 10 } },
    layoutMode: "semantic",
    muiPaginationProps: {
      rowsPerPageOptions: [10, 50, 100],
    },

    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => RowClickNavigator(row),
      sx: { cursor: "pointer" },
    }),
    state: {
      isLoading,
    },
  });

  const disabledDate = (current) => {
    const sixtyDaysAgo = dayjs().subtract(60, "days");
    return current && current.isBefore(sixtyDaysAgo);
  };

  //const [value, setValue] = useState(null)

  // const FilterDate = () => {
  //   const datas = HistoryDate.filter(item => {
  //     const startdate = dayjs(value[0]).format('MM/DD/YYYY')
  //     const Enddate = dayjs(value[1]).format('MM/DD/YYYY')
  //     return item.date >= startdate && item.date <= Enddate
  //   })
  //   setHistoryData(datas)
  //   console.log(datas, 'chk')
  // }

  // useEffect(() => {
  //   FilterDate()
  // }, [value])

  return (
    <div>
      <div className="flexsearchbut">
        <div className="searchbar">
          <Input
            placeholder="Search"
            style={{ width: "280px" }}
            prefix={
              <SearchOutlined style={{ fontSize: "20px", color: "#D3D2D6" }} />
            }
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="newcountbuttondot">
          <Button
            style={{
              background: "#22AF41",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              cursor:inventory.length==0?"not-allowed":"pointer",
            }}
            onClick={CreateNewCountSheet}
            icon={<PlusCircleFilled />}
            loading={load}
            disabled={inventory?.length==0?true:false}
          >
            New Count Sheet
          </Button>
          <Dropdown
            menu={{
              items,
            }}
          >
            <div
              style={{
                borderRadius: "3px",
                border: "1px solid #C4C8CB",
                padding: "0px 5px",
                height: "32px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <FaEllipsisV style={{ color: "#C4C8CB", marginTop: "-3px" }} />
              </a>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="overallhistory">
      <>
       {Budget_Exist_Status==0?   
         <div className='nodatahis'>
          {/* <div>
            <Typography className='nodata-text'> No Count Sheet Available </Typography>
          </div> */}
          <div className='no-inventory' style={{height:'60vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
             <div style={{textAlign:'center'}}>
             <img style={{ width: '5em' }} src='/no-inv.png' />
             <h2 style={{fontSize:'18px'}}>No Inventory Found</h2>
             {/* <Typography style={{fontSize:'14px'}}>No inventory found. Let's start taking inventory.</Typography> */}
             </div>
          </div>
         
          </div>
          :
          <ThemeProvider theme={customTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>}
        </> 
      </div>
      <Modal

title="Set Up Your Budget"
open={IsModalOpenbudget}
footer={[
  <Button
    key="submit"
    onClick={handleOkprodbudget}
    style={{ backgroundColor: "#2BAB47", color: "#fff" }}
  >
    Got It!
  </Button>,
]}
>
<p className="addnewcount1">
Please establish a budget to activate the Inventory Feature.         
</p>
</Modal>

      <Modal
        title="Add Inventory Products"
        open={IsModalOpentwo}
        onOk={handleOkprod}
        onCancel={handleCancelprod
        }
        footer={[
          <Button
            key="submit"
            onClick={handleOkprod}
            style={{ backgroundColor: "#2BAB47", color: "#fff" }}
          >
            Got It!
          </Button>,
        ]}
      >
        <p className="addnewcount1">
        Navigate to the Products tab to add your Inventory Products. Consider uploading  recent invoices to assist in setting up your inventory. 
        </p>
      </Modal>
      <Modal
            title="Open Inventory Warning"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            onClick={handleOk}
            style={{ backgroundColor: "#2BAB47", color: "#fff" }}
          >
            Got It
          </Button>,
        ]}
      >
        <p className="addnewcount1">
          An ongoing inventory process is active (not in a closed state).<br />
           Maintain only one open inventory. Close the ongoing
          inventory before initiating a new one.
        </p>
      </Modal>
      <Modal
        title="Warning!"
        open={isModalOpenone}
        onOk={handleOkone}
        onCancel={handleCancelone}
        footer={[
          <Button
            key="submit"
            style={{ backgroundColor: "#2BAB47", color: "#fff" }}
            onClick={handleOkone}
          >
            Got It
          </Button>,
        ]}
      >
        <p className="addnewcount1">
          Cannot save two inventories on the same date. Please select a
          different date.
        </p>
      </Modal>
      <div style={{ position: 'absolute', left: '-5000px', top: '-5000px'}}>
        <Print ref={printRef} historyData={HistoryData}  />
      </div>
      
    </div>
  );
};

export default HistoryTable;
