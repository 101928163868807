import React from "react";
import Header from "../Header/Header";
import { Tabs } from "antd";
import "../Inventory/Inventory.css";
import HistoryTable from "./HistoryTable";
import Products from "./Products";
//import Template from './Template'
import MasterTemplate from "./MasterTemplates/MasterTemplate";
import Settings from "./Settings";
function Inventory() {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Inventories",
      children: <HistoryTable />,
    },
    {
      key: "2",
      label: "Master Sheet",
      children: <MasterTemplate />,
    },
    {
      key: "3",
      label: "Products",
      children: <Products />,
    },
    {
      key: '4',
      label: 'Settings',
      children: <Settings />
    }
  ];

  return (
    <>
      {/* <Header /> */}
      <div className="startinventory">
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </>
  );
}
export default Inventory;
