import React, { useEffect, useState } from 'react'
import CategoryWise from './CategoryWise'
import ProductWise from './ProductWise'
import { Button, Modal, Select } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'
import Checkbox from '@mui/material/Checkbox'
import CancelIcon from '@mui/icons-material/Cancel'
import { mainAxios } from '../../middleware/interceptor'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toast } from 'react-toastify'
const selectDropdownStyle = `
.ant-select-dropdown{
  padding: 0px !important;
}
.ant-select-item{
  padding: 5px 0px 0px 0px !important;
  border-bottom: 1px solid #F5EDED;
  border-radius: 0px !important;
}
`
const MasterTemplate = () => {
  const [value, setValue] = useState(
    parseInt(localStorage.getItem('templateType') || 0)
  )
  const [RestId, setRestId] = useState(
    parseInt(localStorage.getItem('RestId') || 0)
  )

  const [triggerApi, setTriggerApi] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ProductList, setProductList] = useState([])
  const [dropDownOptions, setDropDownOptions] = useState([])
  const [dropDownValue, setDropDownValue] = useState()
  const[open,setopen]=useState(false)

  const handleSave = () => {
    console.log(dropDownValue.split('~'))
    const statusItemData = {
      restid: RestId,
      catId: dropDownValue.split('~')[3],
      catName: dropDownValue.split('~')[2],
      itemId: dropDownValue.split('~')[4],
      status: 1,
      itemName: dropDownValue.split('~')[0]
    }
    mainAxios
      .post('/api/Inventory/updateItemStatus', statusItemData)
      .then(res => {
        toast.success('Item Successfully Added to Master Sheet')
        setDropDownValue()
        setTriggerApi(prev => !prev)
        setIsModalOpen(false)
        console.log(res)
      })
      .catch(err => {
        toast.error('Failed to Add Item')
        setIsModalOpen(false)
        console.log(err)
      })
  }

  const onChange = value => {
    setDropDownValue(value)
    setopen(false)

  }
  const onSearch = value => {
    console.log('search:', value)
    value==''?setopen(false):
setopen(true)
  }


  useEffect(() => {
    const formattedArray = ProductList?.Data?.InventoryDetails?.flatMap(vj => {
      return Object?.entries(
        Object.groupBy(
          vj.Items?.filter(y => parseInt(y?.Status) === 0),
          ({ SubCatName }) => {
            if (SubCatName == null) {
              return 'Uncategorized'
            } else {
              return SubCatName
            }
          }
        )
      )?.flatMap(([categoryKey, categoryValues]) => {
        return categoryValues.map(item => {
          return {
            subCat: categoryKey,
            catName: vj?.CatName,
            unit: item?.Unit ? item?.Unit : '',
            value: `${item?.Name}~${categoryKey}~${vj?.CatName}~${vj?.CatId}~${item?.id}`,
            itemName: item?.Name
          }
        })
      })
    })
    setDropDownOptions(formattedArray)
  }, [ProductList])
  const filterOption = (input, option) =>
    (option?.itemName ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: selectDropdownStyle }} />
      {value === 1 && (
        <div
          className='newcountbuttondot'
          style={{ justifyContent: 'end', top: '-10px', margin: '0' }}
        >
          <Button
            style={{
              background: '#22AF41',
              color: '#fff',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              setDropDownValue()
              setTimeout(()=>setIsModalOpen(true),500)
            }}
            icon={<PlusCircleFilled />}
          >
            Add Products
          </Button>
        </div>
      )}
      {value === 2 && (
        <div
          className='newcountbuttondot'
          style={{ justifyContent: 'end', top: '51px' }}
        >
          <Button
            style={{
              background: '#22AF41',
              color: '#fff',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              setDropDownValue()
              setTimeout(()=>setIsModalOpen(true),500)
            }}
            icon={<PlusCircleFilled />}
          >
            Add Products
          </Button>
        </div>
      )}
      {value === 1 ? (
        <CategoryWise setProductList={setProductList} trigger={triggerApi} />
      ) : value === 2 ? (
        <ProductWise setProductList={setProductList} trigger={triggerApi} />
      ) : null}

<Modal
        className='product-popup'
        title='Add Products to Master Sheet'
        open={isModalOpen}
        maskClosable={false}
        onOk={() => {
          setIsModalOpen(false)
          }}
         onCancel={() => {
          !open?setIsModalOpen(false):setopen(false); setTimeout(()=>setIsModalOpen(false),1210)
        }}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              disabled={!dropDownValue}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        ]}
      >
        <div className='col-md-12'>
          <div className='row'>
            <div className='master-select'>
              <p
                style={{ fontSize: '16px', color: '#222', marginBottom: '5px' }}
              >
                Product
              </p>
              <Select
                className='product-select'
                showSearch
                //mode='multiple'
                style={{ width: '100%' }}
                value={dropDownValue || null}
                placeholder='Select a item'
                optionLabelProp='label2'
                open={open}
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={dropDownOptions?.map((item, index) => {
                  console.log(item?.unit, 'arthi22')
                  return {
                    ...item,

                    label2: item.itemName,
                    label: (
                      <>
                        <div style={{ padding: '0px 15px' }}>
                          <span
                            style={{
                              fontSize: '12px',
                              color: '#9C9C9C',
                              display: 'flex',
                              gap: '5px'
                            }}
                          >
                            <p style={{ margin: '0' }}>{item?.catName}</p>
                            <p style={{ margin: '0' }}>{'>>'}</p>
                            <p style={{ margin: '0' }}>{item?.subCat}</p>
                          </span>
                          <p
                            style={{
                              fontSize: '15px',
                              color: '#222',
                              marginBottom: '5px'
                            }}
                          >
                            {item?.itemName} - {item?.unit}
                          </p>
                        </div>
                      </>
                    )
                  }
                })}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MasterTemplate
