import { useEffect, useMemo, useRef } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import '../../Inventory/Inventory.css'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { Input, Button, Dropdown, Modal, Drawer, Tooltip, Timeline } from 'antd'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  SearchOutlined,
  UploadOutlined,
  PrinterFilled,
  HistoryOutlined
} from '@ant-design/icons'
import { FaTrash } from 'react-icons/fa'
import ExcelJS from 'exceljs'
import { FaEllipsisV } from 'react-icons/fa'
import { useState } from 'react'
import { mainAxios } from '../../middleware/interceptor'
import { Logger } from '../../middleware/Logger'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import Print from '../Print'
const data = []
const data1 = [
  {
    item: 'ROTEL TOMATO DICED W/GRN CHILE',
    oldcount: '50',
    newcount: '60'
  },
  {
    item: 'ZATARAIN BREADING MIX FISH NEW ORLEANS',
    oldcount: '50',
    newcount: '60'
  },
  {
    item: 'NAT FRSH DRESSING MIX RANCH',
    oldcount: '50',
    newcount: '60'
  },
  {
    item: 'ROTELLAS BUN BRIOCHE 4 T&S',
    oldcount: '50',
    newcount: '60'
  },
  {
    item: 'MCCAIN POTATO CHIP ORGNL 1853 FLAT F',
    oldcount: '50',
    newcount: '60'
  }
]

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#19A73F'
    }
  }
})

const ProductWise = ({ setValue }) => {
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const navigate = useNavigate()
  const params = useParams()
  const printRef = useRef()
  const [inventoryId, setInventoryId] = useState()
  const [detailedData, setDetailedData] = useState()
  const [inventoryData, setInventoryData] = useState(data)
  const [actualData, setActualData] = useState(data)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState()
  const [countSheetHistory, setCountSheetHistory] = useState()
  const DeleteInventory = () => {
    setIsModalOpenOne(false)
    mainAxios
      .post('/api/Inventory/delete/' + date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          toast.success('Inventory Deleted Successfully')
          let Details = {
            InventoryDate: date,
            Payload: ''
          }
          Logger(RestID, 'Closed Countsheet Deleted', Details, inventoryId)
          setTimeout(() => {
            navigate('/')
          }, [1500])
        } else {
          toast.error('Failed')
        }
      })
      .catch(err => {
        toast.error('Error while Deleting inventory')
        console.log(err)
      })
  }
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (params?.Date) {
      setDate(params.Date)
      getHistoryDetails(params.Date)
    }
  }, [])
  //////////////////////2024-01-22//////////////////
  // useEffect(() => {
  //   if (detailedData?.InventoryDetails?.length > 0) {
  //     const formattedArray = detailedData?.InventoryDetails.map(cat => {
  //       return {
  //         category: `${cat.CatName} (${cat.Items.length})`,
  //         item: '',
  //         unit: '',
  //         precount: cat.Items.reduce((sum, currentVal) => {
  //           let value =
  //             currentVal.PreCount === null || currentVal.PreCount === undefined
  //               ? 0
  //               : +currentVal.PreCount
  //           return sum + value
  //         }, 0),
  //         prevalue: cat.Items.reduce((sum, currentVal) => {
  //           let value =
  //             currentVal.PreAmount === null ||
  //             currentVal.PreAmount === undefined
  //               ? 0
  //               : +currentVal.PreAmount
  //           return sum + value
  //         }, 0),
  //         count: cat.Items.reduce((sum, currentVal) => {
  //           let value =
  //             currentVal.Count === null || currentVal.Count === undefined
  //               ? 0
  //               : +currentVal.Count
  //           return sum + value
  //         }, 0),
  //         value: cat.totalAmount,
  //         valuechange:
  //           cat.totalAmount -
  //           cat.Items.reduce((sum, currentVal) => {
  //             let value =
  //               currentVal.PreAmount === null ||
  //               currentVal.PreAmount === undefined
  //                 ? 0
  //                 : +currentVal.PreAmount
  //             return sum + value
  //           }, 0),
  //         countchange:
  //           cat.Items.reduce((sum, currentVal) => {
  //             let value = currentVal.Count === null ? 0 : +currentVal.Count
  //             return sum + value
  //           }, 0) -
  //           cat.Items.reduce((sum, currentVal) => {
  //             let value =
  //               currentVal.PreCount === null ||
  //               currentVal.PreCount === undefined
  //                 ? 0
  //                 : +currentVal.PreCount
  //             return sum + value
  //           }, 0),
  //         catId: cat.CatId,
  //         subRows: cat.Items.map(itm => {
  //           if (itm?.Multiple_Unit && itm?.Multiple_Unit?.length > 0) {
  //             console.log(itm, 'LPLPL')
  //             let json = {
  //               category: cat.CatName,
  //               item: itm.Name,
  //               unit: itm?.Unit ? itm.Unit : '',
  //               precount: itm?.PreCount ? itm.PreCount : '0',
  //               prevalue: itm?.PreAmount ? itm.PreAmount : '',
  //               price: itm?.Price ? itm.Price : '',
  //               NewPrice: itm?.NewPrice ? itm.NewPrice : '',
  //               count: itm?.Count ? itm.Count : '0',
  //               value: itm?.Amount ? itm?.Amount : '0',
  //               change: itm?.DiffAmount ?? '',
  //               countchange:
  //                 (itm.Count ? +itm.Count : 0) -
  //                 (itm.PreCount ? +itm.PreCount : 0)
  //             }
  //             let temp = itm?.Multiple_Unit?.map(mUnit => {
  //               return {
  //                 category: '',
  //                 item: '',
  //                 unit: mUnit?.UnitName
  //                   ? `${mUnit.UnitName}(${mUnit.Name})`
  //                   : '',
  //                 precount: '',
  //                 prevalue: '',
  //                 count: mUnit.Quantity,
  //                 value: mUnit.Price,
  //                 change: '',
  //                 countchange: ''
  //               }
  //             })

  //             temp.unshift(json)
  //             return temp
  //           } else {
  //             return {
  //               category: cat.CatName,
  //               item: itm.Name,
  //               unit: itm?.Unit ? itm.Unit : '',
  //               precount: itm?.PreCount ? itm.PreCount : '0',
  //               prevalue: itm?.PreAmount ? itm.PreAmount : '',
  //               price: itm?.Price ? itm.Price : '',
  //               NewPrice: itm?.NewPrice ? itm.NewPrice : '',
  //               count: itm?.Count ? itm.Count : '0',
  //               value: itm?.Amount ? itm?.Amount : '0',
  //               change: itm?.DiffAmount ?? '',
  //               countchange:
  //                 (itm.Count ? +itm.Count : 0) -
  //                 (itm.PreCount ? +itm.PreCount : 0)
  //             }
  //           }
  //         }).flat()
  //       }
  //     })
  //     setInventoryData(formattedArray)
  //     setIsLoading(false)
  //     setActualData(formattedArray)
  //   }
  // }, [detailedData])
  /////////////////////////////////////////////////

  //for grouping subcategory

  useEffect(() => {
    if (detailedData?.InventoryDetails?.length > 0) {
      const formattedArray = detailedData?.InventoryDetails.flatMap(cat => {
        const subRows = cat?.Items?.map(val => {
          return {
            category: cat.CatName,
            subcategory: val?.SubCatName,
            item: val.Name,
            unit: val?.Unit ? val.Unit : '',
            precount: val?.PreCount ? val.PreCount : '0',
            prevalue: val?.PreAmount ? val.PreAmount : '',
            count: val?.Count ? val.Count : '0',
            value: val?.Amount ? val?.Amount : '0',
            change: val?.DiffAmount ?? '',
            countchange:
              (val.Count ? +val.Count : 0) - (val.PreCount ? +val.PreCount : 0),
            DiffPrice: val?.DiffPrice ? val?.DiffPrice : '',
            itemorderid: val?.itemorderid
              ? parseInt(val?.itemorderid)
              : undefined
          }
        })

        return subRows
      }).sort((a, b) => {
        if (a.itemorderid && b.itemorderid) {
          return a.itemorderid - b.itemorderid
        } else {
          return 0
        }
      })

      setInventoryData(formattedArray)
      setIsLoading(false)
      setActualData(formattedArray)
    }
  }, [detailedData])
  const getHistoryDetails = date => {
    mainAxios
      .get(`/api/Inventory/${date}/${RestID}`)
      .then(response => {
        if (response.data.Status == 1) {
          setInventoryId(response.data.Data.InventoryId)
          const updated = response.data.Data.InventoryDetails.map(d => {
            if (isNaN(d?.totalAmount) || d?.totalAmount == null) {
              let Items = d.Items.map(u => {
                if (isNaN(u?.Amount) || u?.Amount == null) {
                  u = { ...u, Amount: 0 }
                }
                if (isNaN(u?.PreAmount) || u?.PreAmount == null) {
                  u = { ...u, PreAmount: 0 }
                }
                if (isNaN(u?.Count) || u?.Count == null) {
                  u = { ...u, Count: 0 }
                }
                if (isNaN(u?.PreCount) || u?.PreCount == null) {
                  u = { ...u, PreCount: 0 }
                }
                return u
              })
              d.totalAmount = Items.reduce((accumulator, currentItem) => {
                const amount = isNaN(currentItem?.Amount)
                  ? 0
                  : parseFloat(currentItem.Amount)
                return parseFloat(accumulator) + parseFloat(amount)
              }, 0)
              d.Items = Items

              return d
            } else {
              let Items = d.Items.map(u => {
                if (isNaN(u?.Amount) || u?.Amount == null) {
                  u = { ...u, Amount: 0 }
                }
                if (isNaN(u?.PreAmount) || u?.PreAmount == null) {
                  u = { ...u, PreAmount: 0 }
                }
                if (isNaN(u?.Count) || u?.Count == null) {
                  u = { ...u, Count: 0 }
                }
                if (isNaN(u?.PreCount) || u?.PreCount == null) {
                  u = { ...u, PreCount: 0 }
                }
                return u
              })
              d.Items = Items
            }

            return d
          })
          console.log(updated, 'ININININNINI')
          response.data.Data['InventoryDetails'] = updated.filter(
            filtereditem => {
              return filtereditem.Items.length > 0
            }
          )
          
          if (response.data.Data && response.data.Data['TemplateType']) {
            setValue(response.data.Data['TemplateType']);
          } else {
            const templateTypeFromLocalStorage = localStorage.getItem('TemplateType');
            if (templateTypeFromLocalStorage) {
              setValue(templateTypeFromLocalStorage);
            } else {
              console.error('TemplateType not available in API response or local storage');
            }
          }
          setDetailedData(response.data.Data)
          getDetailsOfHistoryButton(response.data.Data.InventoryId)
        } else {
          setDetailedData()
        }
      })
      .catch(err => {
        setDetailedData()
        console.log(err)
      })
  }
  const getDetailsOfHistoryButton = IdOfInventory => {
    mainAxios
      .post(`/api/Inventory/GetCountSheetHistory/${RestID}/${IdOfInventory}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        if (response.status == 200) {
          setCountSheetHistory(response.data.CountsheetHistory)
        }
      })
      .catch(err => {
        setCountSheetHistory([])
        console.log(err)
      })
  }

  const handleSearch = searchedValue => {
    console.log('actt', actualData)
    if (
      searchedValue !== '' &&
      searchedValue !== undefined &&
      searchedValue !== null
    ) {
      const filteredData = actualData.filter(x =>
        x.item
          ?.toLocaleLowerCase()
          ?.includes(searchedValue?.toLocaleLowerCase())
      )
      // if(filteredData.length==0){
      //   setNodata(true)
      // }
      // else{
      //   setNodata(false)
      // }
      setInventoryData(filteredData)
    } else {
      // if(actualData.length==0){
      //   setNodata(true)
      // }
      // else{
      //   setNodata(false)
      // }
      setInventoryData(actualData)
    }
  }

  // const handleSearch = searchedValue => {
  //   searchedValue.length > 0 ? setExpanded(true) : setExpanded(false)
  //   const filteredData =
  //     searchedValue.length > 0
  //       ? actualData
  //           .map(data => ({
  //             ...data,
  //             subRows: data?.subRows?.filter(
  //               item2 =>
  //                 item2?.category
  //                   ?.toLowerCase()
  //                   ?.includes(searchedValue?.toLowerCase()) ||
  //                 item2.item
  //                   ?.toLowerCase()
  //                   ?.includes(searchedValue?.toLowerCase())
  //             ),
  //             category: `${data.category.split(' ')[0]} (${
  //               data?.subRows?.filter(
  //                 item2 =>
  //                   item2?.category
  //                     ?.toLowerCase()
  //                     ?.includes(searchedValue?.toLowerCase()) ||
  //                   item2?.item
  //                     ?.toLowerCase()
  //                     ?.includes(searchedValue?.toLowerCase())
  //               ).length
  //             })`
  //           }))
  //           .filter(item => item.subRows.length)
  //       : actualData

  //   setInventoryData(filteredData)
  // }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenone, setIsModalOpenOne] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [columnFilters, setColumnFilters] = useState([])
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOkone = () => {
    DeleteInventory()
  }
  const handleCancelone = () => {
    setIsModalOpenOne(false)
  }

  const columns1 = useMemo(
    () => [
      {
        accessorKey: 'item',
        header: 'ITEM',
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'oldcount',
        header: 'OLD COUNT',
        size: 60,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                borderRadius: '2px',
                background: '#FFCCCC',
                padding: '5px 12px'
              }}
            >
              <span>50</span>
            </div>
          )
        }
      },
      {
        accessorKey: 'newcount',
        header: 'NEW COUNT',
        size: 60,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                borderRadius: '2px',
                background: '#BBFFD5',
                padding: '5px 12px'
              }}
            >
              <span>100</span>
            </div>
          )
        }
      }
    ],
    []
  )

  const logtable = useMaterialReactTable({
    columns: columns1,
    data: data1,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnResizing: true,
    initialState: { density: 'compact' },
    layoutMode: 'semantic',
    muiTableBodyCellProps: {
      sx: {
        fontSize: '13px !important'
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '13px !important'
      }
    }
  })

  useEffect(() => {
    columnFilters.length > 0 ? setExpanded(true) : setExpanded(false)
  }, [columnFilters])
  const columns = useMemo(
    () => [
      {
        accessorKey: 'category',
        header: 'Category',
        size: 125,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        filterVariant: 'select',
        filterSelectOptions: inventoryData.map(item => ({
          label: item.category.split('(')[0],
          value: item.category.split('(')[0]
        }))
      },
      {
        accessorKey: 'subcategory',
        header: 'subcategory',
        size: 95,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'item',
        header: 'Item',
        size: 375,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        filterFn: 'contains'
      },
      {
        accessorKey: 'unit',
        header: 'Unit',
        size: 95,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'precount',
        header: 'Pre Count',
        size: 130,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => parseFloat(row.original.precount).toFixed(2)
      },
      {
        accessorKey: 'prevalue',
        header: 'Pre Value',
        size: 124,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => currencyFormatter.format(row.original.prevalue)
      },
      {
        accessorKey: 'count',
        header: 'Count',
        size: 110,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => parseFloat(row.original.count).toFixed(2)
      },
      {
        accessorKey: 'value',
        header: 'Value',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => {
          const Diffprice = row.original?.DiffPrice

          let pricecheckhigh = Diffprice?.toString()
          if (Diffprice == 0 || Diffprice == undefined || isNaN(Diffprice)) {
            pricecheckhigh = false
          } else {
            pricecheckhigh = true
          }

          const pricechecklow = Diffprice.toString().includes('-')
          console.log(pricechecklow, pricecheckhigh, 'fromclosed')
          return (
            <>
              {/* ${formattedPrice} {(params.row.Amount === "0" || params.row.Amount === null) && (params.row.PreAmount === "" || params.row.PreAmount === null) ? "" : isAmountGreaterThanPrice ? '?' : isAmountLessThanPrice ? <i className="bi bi-arrow-up"></i> : ''} */}
              {currencyFormatter.format(row.original.value)}{' '}
              {Diffprice == undefined || Diffprice == null ? (
                ''
              ) : pricechecklow ? (
                <Tooltip
                  title={
                    <span style={{ color: '#22af41' }}>
                      ${' '}
                      {Diffprice?.toFixed(2).toString() === '0'
                        ? '0.00'
                        : Math.abs(Diffprice?.toFixed(2)).toString()}
                    </span>
                  }
                >
                  <SouthIcon
                    style={{
                      fontSize: '15px',
                      marginLeft: ' 2px',
                      color: '#2BAB47',
                      marginTop: '-3px',
                      cursor: 'pointer'
                    }}
                  />
                </Tooltip>
              ) : pricecheckhigh ? (
                <Tooltip
                  title={
                    <span style={{ color: '#ff5653' }}>
                      ${' '}
                      {Diffprice?.toFixed(2).toString() == '0'
                        ? '0.00'
                        : Diffprice?.toFixed(2).toString()}
                    </span>
                  }
                >
                  <NorthIcon
                    style={{
                      fontSize: '15px',
                      marginLeft: ' 2px',
                      color: '#FF5653',
                      marginTop: '-3px',
                      cursor: 'pointer'
                    }}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'countchange',
        header: 'Count Change',
        size: 140,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => {
          const countchange = row.original.countchange
          const isPositive = countchange >= 0

          return (
            <span
              style={{
                textAlign: 'right',
                fontWeight: '600',
                color: isPositive ? 'green' : 'red'
              }}
            >
              {isPositive
                ? countchange.toFixed(2)
                : `(${Math.abs(countchange).toFixed(2)})`}
            </span>
          )
        }
      },
      {
        accessorKey: 'valuechange',
        header: 'Value Change',
        size: 140,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        Cell: ({ row }) => {
          const valueChange = row.original.valuechange ?? row.original.change
          const isPositive = +valueChange >= 0

          return (
            <span
              style={{
                textAlign: 'right',
                fontWeight: '600',
                color: isPositive ? 'green' : 'red'
              }}
            >
              {isPositive
                ? currencyFormatter.format(valueChange)
                : `(${currencyFormatter.format(Math.abs(valueChange))})`}
            </span>
          )
        }
      }
    ],
    [inventoryData]
  )

  const handleExcelDownload = () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('ClosedHistory')

    const headerRow = worksheet.addRow([
      'Category',
      'Item',
      'Unit',
      'Pre Count',
      'Pre Value',
      ' Count',
      'Value',
      'Count Change',
      'Value Change'
    ])
    headerRow.font = { bold: true }
    inventoryData.forEach(item => {
      worksheet.addRow([
        item.category,
        item.item == '' ? '-' : item.item,
        item.unit == '' ? '-' : item.unit,
        item.precount,
        item.prevalue,
        item.count,
        item.value,
        item.countchange,
        item.valuechange
      ])
    })

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'ClosedHistory.xlsx'
      link.click()
    })
  }

  const convertDateFormat = inputDate =>
    inputDate
      ? new Date(inputDate).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      })
      : ''

  const items = [
    {
      key: '1',
      label: (
        <span className='dotoption' onClick={handleExcelDownload}>
          <UploadOutlined
            style={{ fontSize: '15px', color: '#22AF41', marginRight: '15px' }}
          />{' '}
          Export
        </span>
      )
    },
    {
      key: '2',
      label: (
        <span
          className='dotoption'
          onClick={() => printRef.current.handlePrint()}
        >
          <PrinterFilled
            style={{ fontSize: '15px', color: '#22AF41', marginRight: '15px' }}
          />{' '}
          Print
        </span>
      )
    }
  ]

  const table = useMaterialReactTable({
    columns,
    data: inventoryData,

    enableTopToolbar: true,
    enableColumnResizing: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableColumnPinning: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableGlobalFilter: false,
    enablePinning: false,
    //layoutMode: 'semantic',
    //paginateExpandedRows: false,
    // filterFromLeafRows: true,
    onColumnFiltersChange: setColumnFilters,
    // muiTableBodyCellProps: ({ cell, column, row, table }) => {
    //   if (
    //     (row.original?.catId && !isNaN(Number(row.original.catId))) ||
    //     row?.original?.subcatrow
    //   ) {
    //     if (column.id === 'category') {
    //       return {
    //         sx: {
    //           color: '#2bab47 !important'
    //         }
    //       }
    //     }
    //     if (column.id === 'subcategory') {
    //       return {
    //         sx: {
    //           fontWeight: '600 !important'
    //         }
    //       }
    //     }
    //     if (column.id === 'value') {
    //       return {
    //         sx: {
    //           fontWeight: '600 !important'
    //         }
    //       }
    //     }
    //     if (column.id === 'prevalue') {
    //       return {
    //         sx: {
    //           fontWeight: '600 !important'
    //         }
    //       }
    //     }
    //     if (column.id === 'change') {
    //       return {
    //         sx: {
    //           fontWeight: '600 !important'
    //         }
    //       }
    //     }
    //   }
    // },
    state: {
      isLoading,
      // expanded,
      columnFilters
    },
    // onExpandedChange: setExpanded,
    initialState: { density: 'compact' },

    renderBottomToolbar: ({ table }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <p className='bottomtotal'>
          Total -
          <span style={{ color: '#222' }}>
            {currencyFormatter.format(
              inventoryData.reduce((sum, currentValue) => {
                if (currentValue.value && parseFloat(currentValue.value)) {
                  return sum + parseFloat(currentValue?.value)
                } else {
                  return sum
                }
              }, 0)
            )}
          </span>
        </p>
      </div>
    )
  })
  return (
    <div>
      <div className='startinventory'>
        <div role='presentation'>
          <Breadcrumbs aria-label='breadcrumb' className='breadcrumb'>
            <Link className='backinvent' to='/' replace>
              Inventories
            </Link>
            <Typography className='currentsheet'>
              Count Sheet{' '}
              {detailedData
                ? detailedData?.Date
                  ? `${detailedData.Date.split('-')[1]}/${detailedData.Date.split('-')[2]
                  }/${detailedData.Date.split('-')[0]}`
                  : ''
                : ''}
            </Typography>
          </Breadcrumbs>
        </div>
        <div className='levelone'>
          <p className='datesheet'>
            Count Sheet{' '}
            {detailedData
              ? detailedData?.Date
                ? `${detailedData.Date.split('-')[1]}/${detailedData.Date.split('-')[2]
                }/${detailedData.Date.split('-')[0]}`
                : ''
              : ''}
          </p>
          <span
            className='closedbutton'
            style={{ backgroundColor: '#2BAB47D6', borderRadius: '50px' }}
          >
            {detailedData
              ? detailedData.Status == '1'
                ? 'Closed'
                : 'Open'
              : ''}
          </span>
        </div>
        <div className='flexsearchbut'>
          <div className='searchbar'>
            <Input
              placeholder='Search'
              style={{ width: '280px' }}
              onChange={e => handleSearch(e.target.value)}
              prefix={
                <SearchOutlined
                  style={{ fontSize: '20px', color: '#D3D2D6' }}
                />
              }
            />
          </div>
          <div className='newcountbuttondot newcountbuttondot1'>
            {detailedData?.Status == '1' && (
              <>
                <Button
                  style={{
                    background: '#22AF41',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={showDrawer}
                  icon={
                    <HistoryOutlined
                      style={{ fontSize: '15px', marginTop: '1px' }}
                    />
                  }
                >
                  History
                </Button>
                <Button
                  style={{
                    background: '#fff',
                    border: '1px solid #E54643',
                    color: '#E54643',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  icon={<FaTrash style={{ fontSize: '12px' }} />}
                  onClick={() => setIsModalOpenOne(true)}
                >
                  Delete
                </Button>
              </>
            )}
            <Dropdown
              menu={{
                items
              }}
            >
              <div
                style={{
                  borderRadius: '3px',
                  border: '1px solid #C4C8CB',
                  padding: '0px 5px',
                  height: '32px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <a onClick={e => e.preventDefault()}>
                  <FaEllipsisV
                    style={{ color: '#C4C8CB', marginTop: '-3px' }}
                  />
                </a>
              </div>
            </Dropdown>
          </div>
        </div>
        <ThemeProvider theme={customTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
      <Modal
        title='Reopen'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={handleOk}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Are you sure you want to Reopen the Inventory taken on: Dec 1, 2021?
        </p>
      </Modal>

      <Drawer
        title={
          <div>
            <p style={{ margin: '0' }}>{dayjs(date).format('MM/DD/YYYY')}</p>
            <p style={{ margin: '0', color: '#19a73f', fontSize: '13px' }}>
              Closed
            </p>
          </div>
        }
        // title=""
        width={800}
        onClose={onClose}
        open={open}
        closeIcon={
          <KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />
        }
      >
        <Timeline
          items={countSheetHistory?.map((historyItem, index) => {
            const dateObject = new Date(historyItem?.createdAt)
            const formattedDate = dateObject?.toLocaleString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })

            return {
              children: (
                <div key={index}>
                  <p className='drawpara'>
                    {historyItem?.Action == 'Countsheet Date Updated'
                      ? historyItem?.Action +
                      ' from ' +
                      dayjs(
                        historyItem?.Details?.Payload?.CurrentDate
                      ).format('MM/DD/YYYY') +
                      ' to ' +
                      dayjs(
                        historyItem?.Details?.Payload?.updatedDate
                      ).format('MM/DD/YYYY')
                      : historyItem?.Action}
                    {' on '}
                    {formattedDate}
                    {' by '}
                    <span style={{ color: '#19a73f' }}>
                      {historyItem?.RestaurantName}
                    </span>
                  </p>
                </div>
              )
            }
          })}
        />
      </Drawer>

      <Modal
        className='delete-inventory-popup'
        title='Delete Inventory'
        open={isModalOpenone}
        onOk={handleOkone}
        onCancel={handleCancelone}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancelone}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={DeleteInventory}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Confirm deletion of the inventory taken on{' '}
          {detailedData
            ? detailedData?.Date
              ? convertDateFormat(detailedData?.Date)
              : ''
            : ''}{' '}
          ?
        </p>
      </Modal>
      <div style={{ position: 'absolute', left: '-5000px', top: '-5000px' }}>
        <Print ref={printRef} detailedData={detailedData} />
      </div>
    </div>
  )
}

export default ProductWise
