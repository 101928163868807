import { mainAxios } from './interceptor'

export const Logger = (RestID, Action, Details,invid) => {
  const UserClaims = localStorage.getItem('UserClaims')
  const RestaurantName = JSON.parse(UserClaims).restaurent_name

  let LogObject = {
    RestaurantName: RestaurantName,
    Action: Action,
    Details: Details,
    InventoryId: invid==""?null:invid,
  }

  mainAxios
    .post('/api/Inventory/ActionLogger/' + RestID, LogObject, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      if (res.data.status == 1) {
        console.log('Log Added ', LogObject)
      } else {
        console.log('Log Added info : ', LogObject)
      }
    })
    .catch(err => {
      console.log(err)
    })
}
