import React, { useEffect, useState } from 'react'
import './Settings.css'
import { toast } from 'react-toastify'
import { mainAxios } from '../middleware/interceptor'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Settings = () => {
  const [value, setValue] = useState(
    parseInt(localStorage.getItem('templateType')) || 0
  )
  const [RestID, setRestId] = useState(localStorage.getItem('RestId') || 0)
  const handleImageClick = e => {
    setValue(e)
    ChangeTemplateType(e)
  }

  const GetTemplateType = () => {
    mainAxios
      .get('/api/Inventory/GetInventoryTemplate/' + RestID)
      .then(res => {
        console.log('ressss', res)
        if (res.data?.Status.toString() === '1') {
          localStorage.setItem('templateType', res.data?.CurrentTemplate)
        } else {
          if (res.data?.message == 'No Template Type Defined Yet') {
            ChangeTemplateType(1)
          } else {
            toast.error('Failed to fetch Template')
          }
        }
      })
      .catch(() => {
        toast.error('Failed to fetch Template')
      })
  }

  const ChangeTemplateType = TemplateType => {
    mainAxios
      .post(
        '/api/Inventory/ChangeInventoryTemplate/' + RestID + '/' + TemplateType
      )
      .then(res => {
        console.log('ressss', res)
        if (res.data?.Status.toString() === '1') {
          localStorage.setItem('templateType', res.data?.CurrentTemplate)
        } else {
          toast.error('Failed to Change Template type')
        }
      })
      .catch(() => {
        toast.error('Failed to Change Template type')
      })
  }
  useEffect(() => {
    GetTemplateType()
  }, [])

  return (
    <div className='App'>
      <div className='TemplateGrid'>
        <div className='TemplateGrid_head'>
          <div className='TemplateGrid_head-box'>
            <h4 style={{ fontSize: '16px', color: '#26723A', margin: '0' }}>
              Customize Templates
            </h4>
          </div>
        </div>
        <div className='TemplateGrid_body'>
          <div className='images'>
            <div className='image-container'>
              <h5
                style={{
                  fontSize: '16px',
                  color: '#7B7B7B',
                  marginBottom: '0px'
                }}
              >
                Product List Template
              </h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '-10px'
                }}
              >
                {value === 2 && (
                  <CheckCircleIcon
                    style={{
                      color: '#2bab47',
                      fontSize: '30px',
                      position: 'relative',
                      top: '20px',
                      background: '#fff',
                      right: '-18px'
                    }}
                  />
                )}
              </div>

              <img
                src='/productView.png'
                alt='Template - ItemWise'
                style={{
                  ...imageStyle,
                  border: value === 2 ? '2px solid #2BAB47' : 'none'
                }}
                onClick={() => handleImageClick(2)}
              />
            </div>
            <div className='image-container'>
              <h5
                style={{
                  fontSize: '16px',
                  color: '#7B7B7B',
                  marginBottom: '0px'
                }}
              >
                Product Category Template
              </h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '-10px'
                }}
              >
                {value === 1 && (
                  <CheckCircleIcon
                    style={{
                      color: '#2bab47',
                      fontSize: '30px',
                      position: 'relative',
                      top: '20px',
                      background: '#fff',
                      right: '-18px'
                    }}
                  />
                )}
              </div>
              <img
                src='/categoryView.png'
                alt='Template - CategoryWise'
                style={{
                  ...imageStyle,
                  border: value === 1 ? '2px solid #2BAB47' : 'none'
                }}
                onClick={() => handleImageClick(1)}
              />
            </div>
          </div>
        </div>
     

      </div>
    </div>
  )
}
const imageStyle = {
  width: '200px',
  height: '130px',
  padding: '10px',
  cursor: 'pointer',
  borderRadius:'10px'
}

export default Settings
