import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
//import '../Inventory/Inventory.css'
import '../Inventory.css'
import Typography from '@mui/material/Typography'
import { Button, Input, Modal, Tooltip } from 'antd'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { mainAxios } from '../../middleware/interceptor'
import { Logger } from '../../middleware/Logger'
import 'react-toastify/dist/ReactToastify.css'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { CircularProgress } from '@mui/material'
import { StarFilled } from '@ant-design/icons'
import CancelIcon from '@mui/icons-material/Cancel'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#19A73F'
    }
  }
})

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '0px',
  gap: '5px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '0px'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #22AF41'
}))

const CategoryWise = ({ setProductList, trigger }) => {
  const UserClaims = localStorage.getItem('UserClaims')
  const [Budget_Exist_Status, setBudget_Exist_Status] = useState(
    JSON.parse(UserClaims).Budget_Exist_Status
  )
  const [InventoryItems, setInventoryItems] = useState([])
  const [IsModalOpenbudget, setIsModalOpenbudget] = useState(false)
  const [isTableview, setTableView] = useState(false)
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const { Inventory_date } = useParams()
  const [last, setlast] = useState([])
  const [inventoryId, setInventoryId] = useState()
  const [expanded, setExpanded] = useState('panel1')
  const [searchedValue, setSearchedValue] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const [IsModalOpentwo, setIsModalOpentwo] = useState(false)
  const [currentAccordionIndex, setCurrentAccordionIndex] = useState(0)
  const [subvalue, setsubvalue] = useState([])
  const [expandedsub, setExpandedsub] = useState('')
  const [BackupData, setBackupData] = useState([])
  const [nodata, setNodata] = useState(false)
  const [tempholder, setTempHolder] = useState([])
  const [progressBar, setProgressBar] = useState(false)

  useEffect(() => {
    console.log(trigger, 'trigger')
    setTimeout(() => {
      setTableView(true)
    }, 300)
    if (Budget_Exist_Status == 1) {
      LoadInventoryItems(RestID)
    } else {
      setIsModalOpenbudget(true)
    }
  }, [trigger])

  let finalresult = []
  let finalsub = []

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    setExpandedsub(false)
    finalresult = last.filter(item => item !== panel)
    setlast(finalresult)
    setHighlightedIndex(0)
    const newIndex = InventoryItems.findIndex(
      category => category.CatId === panel
    )
    setCurrentAccordionIndex(newIndex !== -1 ? newIndex : 0)
  }

  const LoadInventoryItems = RestID => {
    setProgressBar(true)
    mainAxios
      .get('/api/Inventory/null/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          setProductList(res.data)
          setAddMasterSheetLoader(false)
          setProgressBar(false)
          setInventoryId(res.data.Data.InventoryId)
          setTempHolder(res.data.Data.InventoryDetails)
          if (res.data.Data.InventoryDetails.length == 0) {
            setNodata(true)
            setIsModalOpentwo(true)
          } else {
            const data = res.data.Data.InventoryDetails.map(cat =>
              cat.Items?.filter(item => parseInt(item.Status) == 1)
            ).flat().length
            if (data == 0) {
              setNodata(true)
            } else {
              setNodata(false)
            }
          }
          //setInventoryItems(res.data.Data.InventoryDetails)
          // setBackupData(
          //   JSON.parse(JSON.stringify(res.data.Data.InventoryDetails))
          // );
        } else {
          setProductList([])
          setProgressBar(false)
          setTempHolder([])
          setNodata(true)
        }
      })
      .catch(err => {
        setProductList([])
        setProgressBar(false)
        setTempHolder([])
        setNodata(true)

        console.log(err)
      })
  }
  const { Search } = Input

  const removeLineItem = params => {
    console.log('params', params)
    let statusItemData = {
      restid: RestID, //
      catId: params.row.original.catId,
      catName: params.row.original.catName,
      itemId: params.row.original.id, //
      status: 0, //
      itemName: params.row.original.Name
    }
    mainAxios
      .post('/api/Inventory/updateItemStatus', statusItemData)
      .then(res => {
        if (res.data?.status.toString() === '1') {
          setModalVisible(false)
          toast.success('Item Successfully Removed from Master Sheet')
          LoadInventoryItems(RestID)
        } else {
          setModalVisible(false)
          toast.error('Failed to Remove Item')
        }
      })
      .catch(err => {
        setModalVisible(false)
        toast.error('Failed to Remove Item')
      })
  }

  const onSearchItems = (value, _e, info) => {
    if (Budget_Exist_Status == 1) {
      setSearchedValue(value)
      if (value != '') {
        const searchTerm = value.toLowerCase()
        var index = 0
        const searchResults = BackupData.reduce((acc, category) => {
          const filteredItems = category.Items.filter(
            item =>
              item?.Name.toLowerCase().includes(searchTerm) &&
              parseInt(item.Status) == 1
          )

          category?.groupBy &&
            Object.keys(category?.groupBy).length > 0 &&
            acc.push({
              ...category,
              groupBy: {
                ...category?.groupBy,
                ...Object.keys(category?.groupBy).reduce((newGroupBy, key) => {
                  newGroupBy[key] = category?.groupBy[key]?.filter(item =>
                    item.Name.toLowerCase().includes(searchTerm)
                  )
                  index++
                  return { ...newGroupBy, subid: index }
                }, {})
              },
              Items: filteredItems
            })

          if (acc?.length == 0) {
            setNodata(true)
          } else {
            const isEmpty = acc.every(
              item => Array.isArray(item.Items) && item.Items.length === 0
            )

            if (isEmpty) {
              setNodata(true)
            } else {
              setNodata(false)
            }
          }
          // calculateTotal(BackupData);
          return acc
        }, [])
        searchResults?.forEach(item => {
          item.Items.forEach(item1 => {
            finalsub.push(item1.Name)
          })
        })
        setsubvalue(finalsub)

        const backupresultcat = searchResults
          .map(
            item =>
              item?.groupBy &&
              Object.keys(item?.groupBy).length > 0 &&
              Object.keys(item.groupBy)
                .filter(item1 => item.groupBy[item1].length > 0)
                .map(filteredItem1 => item.CatId)
          )
          .filter(backupItem => backupItem)

        const backupresult = searchResults
          .map(
            item =>
              item?.groupBy &&
              Object.keys(item?.groupBy).length > 0 &&
              Object.keys(item.groupBy)
                .filter(item1 => item.groupBy[item1].length > 0)
                .map(filteredItem1 => item)
          )
          .filter(backupItem => backupItem)
        const uniqueArray = [...new Set(backupresult.flat())]
        const uniqueArraycatid = [...new Set(backupresultcat.flat())]

        if (finalresult?.length == 0) {
          finalresult = [...uniqueArraycatid]
          setlast(finalresult)
        } else {
          uniqueArraycatid?.forEach(item =>
            finalresult.push(
              finalresult.filter(item1 => item1.CatId !== item.CatId)
            )
          )
        }

        setInventoryItems(uniqueArray)
      } else {
        setInventoryItems(BackupData)
        setNodata(false)
        setsubvalue([])
      }
      setlast(finalresult)
    } else {
      setIsModalOpenbudget(true)
    }
  }

  const handlePreviousValue = () => {
    if (searchedValue?.length > 0 && searchedValue != '') {
      setHighlightedIndex(prevIndex => {
        const isFirstItemInAccordion = prevIndex === 0

        if (isFirstItemInAccordion) {
          const newIndex =
            currentAccordionIndex === 0
              ? InventoryItems?.length - 1
              : currentAccordionIndex - 1
          setCurrentAccordionIndex(newIndex)
          return InventoryItems[newIndex].Items?.length - 1
        } else {
          return prevIndex - 1
        }
      })
    }
  }

  const handleNextValue = () => {
    if (searchedValue?.length > 0) {
      setHighlightedIndex(prevIndex => {
        const totalItems = InventoryItems[currentAccordionIndex].Items?.length
        const isLastItemInAccordion = (prevIndex + 1) % totalItems === 0

        if (isLastItemInAccordion) {
          const newIndex =
            currentAccordionIndex === InventoryItems?.length - 1
              ? 0
              : currentAccordionIndex + 1
          setCurrentAccordionIndex(newIndex)
          return 0
        } else {
          return prevIndex + 1
        }
      })
    }
  }

  //group by subcategory
  useEffect(() => {
    const checking = tempholder.map(bycategory => {
      const result = Object.groupBy(bycategory.Items, ({ SubCatName }) => {
        if (SubCatName == null) {
          return 'Uncategorized'
        } else {
          return SubCatName
        }
      })
      return { ...bycategory, groupBy: result }
    })

    setInventoryItems(checking)
    setBackupData(JSON.parse(JSON.stringify(checking)))
  }, [tempholder])
  const handleCancelprod = () => {
    setIsModalOpentwo(false)
  }
  const handleOkprod = () => {
    setIsModalOpentwo(false)
  }
  const handlesubChange = (panel, catval) => (event, newExpanded) => {
    // console.log(panel, newExpanded, catval, "panl");
    const name = catval.map(item => item.Name)
    finalresult = subvalue.filter(item => !name.includes(item))
    // const finalresult1 = subvalue.filter((item)=>!finalresult.includes(item))
    // console.log(finalresult, "finalresult");
    setsubvalue(finalresult)
    setExpandedsub(newExpanded ? panel : false)
  }

  const [isModalVisible, setModalVisible] = useState(false)
  const [datatoberemoved, setDataToBeRemoved] = useState()

  const handleIconClick = data => {
    setDataToBeRemoved(data)
    setModalVisible(true)
  }

  const handleOkprodbudget = () => {
    setIsModalOpenbudget(false)
  }

  const handleModalClose = () => {
    setModalVisible(false)
  }

  const columns1 = useMemo(() => [
    {
      accessorKey: 'Name',
      header: 'Item',
      size: 100,
      muiTableHeadCellProps: {
        align: 'left'
      },
      muiTableBodyCellProps: {
        align: 'left'
      },
      Cell: row => {
        if (row.row.original.CreatedAt !== null) {
          const createddate = dayjs(row.row.original.CreatedAt)
          //console.log(row.row.original.Name);
          const nextmonth = createddate.add(31, 'day')
          //console.log(nextmonth);
          if (dayjs().isBefore(nextmonth)) {
            return (
              <>
                <span>{row.row.original.Name}</span> &nbsp; &nbsp;{' '}
                <StarFilled style={{ color: '#ffd700' }} />
              </>
            )
          } else {
            return (
              <>
                <span>{row.row.original.Name}</span>
              </>
            )
          }
        } else {
          return (
            <>
              <span>{row.row.original.Name}</span>
            </>
          )
        }
      }
    },
    {
      accessorKey: 'Unit',
      header: 'Unit',
      size: 100,
      muiTableHeadCellProps: {
        align: 'left'
      },
      muiTableBodyCellProps: {
        align: 'left'
      }
    },
    {
      accessorKey: 'Price',
      header: 'Price',
      size: 100,
      muiTableHeadCellProps: {
        align: 'left'
      },
      muiTableBodyCellProps: {
        align: 'left'
      },
      Cell: params => {
        const formattedPrice = parseFloat(params.row.original.Price).toFixed(2)
        const formattedNewPrice = params.row?.original.NewPrice
          ? parseFloat(params.row.original.NewPrice).toFixed(2)
          : null
        const isAmountGreaterThanPrice =
          params.row.original.Amount > params.row.original.PreAmount
        const DifferenceAmount =
          (params.row.original.Amount == '' ||
          params.row.original.Amount == null ||
          params.row.original.Amount == undefined
            ? 0.0
            : parseFloat(params.row.original.Amount).toFixed(2)) -
          (params.row.original.PreAmount == '' ||
          params.row.original.PreAmount == null ||
          params.row.original.PreAmount == undefined
            ? 0.0
            : parseFloat(params.row.original.PreAmount).toFixed(2))
        const isAmountLessThanPrice =
          params.row.original.Amount < params.row.original.PreAmount

        const pricecheckhigh =
          parseFloat(params.row.original.NewPrice) >
          parseFloat(params.row.original.Price)
        const pricechecklow =
          parseFloat(params.row.original.NewPrice) <
          parseFloat(params.row.original.Price)
        const Diffprice = params.row.original.DiffAmount

        return (
          <>
            {/* ${formattedPrice} {(params.row.Amount === "0" || params.row.Amount === null) && (params.row.PreAmount === "" || params.row.PreAmount === null) ? "" : isAmountGreaterThanPrice ? '↑' : isAmountLessThanPrice ? <i className="bi bi-arrow-up"></i> : ''} */}
            ${formattedNewPrice ? formattedNewPrice : formattedPrice}{' '}
            {Diffprice == undefined || Diffprice == null ? (
              ''
            ) : pricechecklow ? (
              <Tooltip
                title={
                  <span style={{ color: '#22af41' }}>
                    ${' '}
                    {Diffprice.toFixed(2).toString() === '0'
                      ? '0.00'
                      : Math.abs(Diffprice.toFixed(2)).toString()}
                  </span>
                }
              >
                <SouthIcon
                  style={{
                    fontSize: '15px',
                    marginLeft: ' 2px',
                    color: '#2BAB47',
                    marginTop: '-3px',
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            ) : pricecheckhigh ? (
              <Tooltip
                title={
                  <span style={{ color: '#ff5653' }}>
                    ${' '}
                    {Diffprice.toFixed(2).toString() == '0'
                      ? '0.00'
                      : Diffprice.toFixed(2).toString()}
                  </span>
                }
              >
                <NorthIcon
                  style={{
                    fontSize: '15px',
                    marginLeft: ' 2px',
                    color: '#FF5653',
                    marginTop: '-3px',
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 100,
      muiTableHeadCellProps: {
        align: 'left'
      },
      muiTableBodyCellProps: {
        align: 'left'
      },
      Cell: params => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Tooltip title='Remove Item'>
            <CancelIcon
              // onClick={() => {
              //   removeLineItem(params)
              // }}
              onClick={() => handleIconClick(params)}
              style={{ color: 'red', fontSize: '17px', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      )
    }
  ])

  function getRowId (row) {
    return row.id
  }

  const handleOrderChange = orderedData => {
    const payload = orderedData.reduce((acc, item) => {
      const existingCategory = acc.find(
        category =>
          category.catId === item.catId && category.catName === item.catName
      )

      if (existingCategory) {
        const existingItem = existingCategory.items.find(
          existingItem => existingItem.itemId === item.id
        )

        if (existingItem) {
          existingItem.orderid = item.orderid
        } else {
          existingCategory.items.push({
            itemId: item.id,
            orderid: item.orderid
          })
        }
      } else {
        acc.push({
          catId: item.catId,
          catName: item.catName,
          items: [{ itemId: item.id, orderid: item.orderid }]
        })
      }

      return acc
    }, [])

    mainAxios
      .post('/api/Inventory/UpdateMasterSheetOrders/' + RestID, payload)
      .then(res => {
        if (res.data?.Status.toString() === '1') {
          toast.success('Orders Saved Successfully.')
        } else {
          toast.error('Failed Ordering Item.')
        }
      })
      .catch(() => {
        toast.error('Failed Ordering Item.')
      })

    //console.log("Payload", payload[0]);
  }
  const [rowSelection, setRowSelection] = useState({})

  const [addMasterSheetLoader, setAddMasterSheetLoader] = useState(false)
  const handleMultipleCheckBox = () => {
    const arr = []
    setAddMasterSheetLoader(true)
    console.log(rowSelection, 'UUHUHUh')
    for (const key in rowSelection) {
      if (rowSelection.hasOwnProperty(key)) {
        if (rowSelection[key]) {
          let d = key.split('~')
          let json = {
            restid: RestID,
            catId: d[2],
            catName: d[1],
            itemId: d[0],
            status: 0
          }
          arr.push(json)
        }
      }
    }
    console.log(arr)
    mainAxios
      .post('api/Inventory/AddToMasterCountsheet', arr)
      .then(e => {
        setAddMasterSheetLoader(false)
        setRowSelection({})
        LoadInventoryItems(RestID)

        toast.success('Selected Items Removed from Master Countsheet')
      })
      .catch(e => {
        setAddMasterSheetLoader(false)
        console.log(e)
        toast.error('Error while Removing selected items from masterCountsheet')
      })
  }
  return (
    <>
      <div>
        <div>
          {progressBar && !addMasterSheetLoader && (
            <CircularProgress
              className='ExceptionalCircularProgress'
              color='success'
              disableShrink
              style={{ margin: '0 auto', display: 'block' }}
            />
          )}
          {(nodata || Budget_Exist_Status == 0) && (
            <div
              className=''
              style={{
                height: '60vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '5em' }} src='/no-item.png' />
                <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
                {/* <Typography style={{fontSize:'14px'}}>Start setting up your Master Sheet for seamless Inventory management.</Typography> */}
              </div>
            </div>
          )}
          {isTableview &&
            InventoryItems.map((category, catIndex) => {
              return (
                <>
                  {category.Items.filter(y => y.Status == '1').length > 0 && (
                    <Accordion
                      key={category.CatId}
                      className='mainCategory'
                      expanded={
                        last.includes(category.CatId) ||
                        expanded === category.CatId
                      }
                      onChange={handleChange(category.CatId)}
                    >
                      <AccordionSummary
                        aria-controls={`panel${category.CatId}d-content`}
                        id={`panel${category.CatId}d-header`}
                      >
                        <Typography className='accordiantitle'>
                          {category.CatName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {category.groupBy
                          ? Object?.entries(category?.groupBy)?.map(
                              ([categoryKey, categoryValues]) => {
                                //console.log(categoryKey, categoryValues, "cat");

                                return (
                                  <>
                                    {Array.isArray(categoryValues) &&
                                      categoryValues?.filter(
                                        y => y.Status == '1'
                                      ).length > 0 && (
                                        <Accordion
                                          className='subCategory'
                                          key={categoryKey}
                                          expanded={
                                            categoryValues?.some(item =>
                                              subvalue.includes(item.Name)
                                            ) || expandedsub === categoryKey
                                          }
                                          onChange={handlesubChange(
                                            categoryKey,
                                            categoryValues
                                          )}
                                        >
                                          <AccordionSummary
                                            aria-controls={`panel${categoryKey}d-content`}
                                            id={`panel${categoryKey}d-header`}
                                          >
                                            <Typography className='accordiantitleone'>
                                              {categoryKey}
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <div
                                              className='temp1-master'
                                              style={{
                                                width: '100%',
                                                paddingLeft: '15px',
                                                paddingRight: '15px',
                                                maxHeight: '310px',
                                                overflow: 'auto'
                                              }}
                                            >
                                              <ThemeProvider
                                                theme={customTheme}
                                              >
                                                <ReorderData
                                                  fields={columns1}
                                                  rows={categoryValues
                                                    .filter(
                                                      y => y.Status == '1'
                                                    )
                                                    .map(v => {
                                                      return {
                                                        ...v,
                                                        catId: category.CatId,
                                                        catName:
                                                          category.CatName
                                                      }
                                                    })}
                                                  onOrderChange={
                                                    handleOrderChange
                                                  }
                                                  rowSelection={rowSelection}
                                                  setRowSelection={
                                                    setRowSelection
                                                  }
                                                />
                                              </ThemeProvider>
                                            </div>
                                          </AccordionDetails>
                                        </Accordion>
                                      )}
                                  </>
                                )
                              }
                            )
                          : null}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )
            })}
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            backgroundColor: '#fff',
            marginLeft: '-40px'
          }}
        >
          <div className='onetwoline'>
            <div className='footercountsheet'>
              <div className='totalfoot'>
                <div>
                  <Search
                    className='search'
                    placeholder='Search'
                    onChange={e => onSearchItems(e.target.value)}
                    enterButton
                    allowClear={true}
                  />
                </div>
                {/* <div
                  className='cursorPointer'
                  onClick={
                    Budget_Exist_Status == 1
                      ? () => handlePreviousValue
                      : () => setIsModalOpenbudget(true)
                  }
                >
                  <FaAngleLeft
                    style={{
                      color: '#22AF41',
                      marginTop: '-4px',
                      marginRight: '1px'
                    }}
                  />
                </div>
                <div
                  className='cursorPointer'
                  onClick={
                    Budget_Exist_Status == 1
                      ? () => handleNextValue
                      : () => setIsModalOpenbudget(true)
                  }
                >
                  <FaAngleRight
                    style={{
                      color: '#22AF41',
                      marginTop: '-4px',
                      marginLeft: '1px'
                    }}
                  />
                </div> */}
              </div>
              {Object.keys(rowSelection).length !== 0 && (
                <div className='threebuttons'>
                  <Button
                    style={{
                      border: '1px solid #E54643',
                      backgroundColor: 'rgba(229, 70, 67, 0.11)',
                      color: '#E54643'
                    }}
                    loading={addMasterSheetLoader}
                    onClick={() => {
                      handleMultipleCheckBox()
                    }}
                  >
                    Remove From Master Sheet
                  </Button>
                  <Button
                    style={{
                      border: '1px solid #DDD',
                      backgroundColor: '#F5F5F5',
                      color: '#212529'
                    }}
                    onClick={() => {
                      setRowSelection({})
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Remove Item'
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={handleModalClose}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={() => removeLineItem(datatoberemoved)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        {/* Your modal content goes here */}
        <p className='addnewcount1'>
          Please confirm the removal of the item '
          <span>
            {datatoberemoved ? datatoberemoved.row.original.Name : ''}
          </span>
          ' from the Master Sheet.
        </p>
      </Modal>
      <Modal
        title='Set Up Your Budget'
        open={IsModalOpenbudget}
        onCancel={() => setIsModalOpenbudget(false)}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprodbudget}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Please establish a budget to activate the Inventory Feature.
        </p>
      </Modal>
      <Modal
        title='Add Inventory Products'
        open={IsModalOpentwo}
        onOk={handleOkprod}
        onCancel={handleCancelprod}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprod}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Navigate to the Products tab to add your Inventory Products. Consider
          uploading recent invoices to assist in setting up your inventory.
        </p>
      </Modal>
    </>
  )
}

export default CategoryWise

const ReorderData = ({
  fields,
  rows,
  onOrderChange,
  rowSelection,
  setRowSelection
}) => {
  const [data, setData] = useState(rows)


  const handleRowReorder = (draggingRow, hoveredRow) => {
    console.log(draggingRow, hoveredRow,'dragggg')
    if (hoveredRow && draggingRow) {
      const updatedData = [...data]
      const movedItem = updatedData.splice(draggingRow.index, 1)[0]
      updatedData.splice(hoveredRow.index, 0, movedItem)
      updatedData.map((item, index) => {
        item.catorderid = index + 1
      })
      console.log(updatedData, 'updatedData')
      setData(updatedData)
      if (onOrderChange) {
        onOrderChange(
          updatedData.map((item, index) => ({ ...item, orderid: index + 1 }))
        )
      }
    }
  }
  
  useEffect(() => {
    console.log(data, 'Data')
    ///Sort data based on catorderid if it exists otherwise it returns 0
    const sortedData = [...data].sort((a, b) => {
      if (a.catorderid && b.catorderid) {
        return a.catorderid - b.catorderid
      } else {
        return 0
      }
    })
    setData(sortedData)
  }, [rows])

  return (
    <>
      {data && data.length > 0 && (
        <MaterialReactTable
          columns={fields}
          data={data || []}
          enableTopToolbar={false}
          enableColumnResizing={false}
          enablePagination={false}
          enableRowOrdering={true}
          enableColumnFilter={false}
          enableColumnFilterModes={false}
          enableColumnMenu={false}
          initialState={{
            density: 'compact'
          }}
          enableRowSelection={true}
          getRowId={row => {
            return `${row.id}~${row.catName}~${row.catId}`
          }}
          layoutMode='semantic'
          muiRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState()
              handleRowReorder(draggingRow, hoveredRow)
            }
          })}
          onRowSelectionChange={setRowSelection}
          state={{
            rowSelection
          }}
        />
      )}
    </>
  )
}
