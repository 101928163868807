import React, { useState, useEffect, useMemo, useRef } from 'react'
//import '../Inventory/Inventory.css'
import '../Inventory.css'
import { Button, Input, Modal, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { mainAxios } from '../../middleware/interceptor'
import { Logger } from '../../middleware/Logger'
import 'react-toastify/dist/ReactToastify.css'
import { StarFilled } from '@ant-design/icons'
import CancelIcon from '@mui/icons-material/Cancel'
import { SearchOutlined } from '@ant-design/icons'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#19A73F'
    }
  }
})

const ProductWise = ({ setProductList, trigger }) => {
  const UserClaims = localStorage.getItem('UserClaims')
  const [IsModalOpentwo, setIsModalOpentwo] = useState(false)
  const [Budget_Exist_Status, setBudget_Exist_Status] = useState(
    JSON.parse(UserClaims).Budget_Exist_Status
  )
  const [addMasterSheetLoader, setAddMasterSheetLoader] = useState(false)
  const [isTableview, setTableView] = useState(false)
  const rowVirtualizerInstanceRef = useRef(null)
  const [progressBar, setProgressBar] = useState(false)
  const [nodata, setNodata] = useState(false)
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const [backupData, setBackupData] = useState([])
  const [isModalVisible, setModalVisible] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')
  const [data, setData] = useState([])
  const [IsModalOpenbudget, setIsModalOpenbudget] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [datatoberemoved, setDataToBeRemoved] = useState()
  const [dynamoData, setDynamoData] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const handleOkprodbudget = () => {
    setIsModalOpenbudget(false)
  }

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0)
    } catch (error) {
      console.error(error)
    }
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTableView(true)
  //   }, 300)
  // }, [])

  const handleIconClick = data => {
    setDataToBeRemoved(data)
    setModalVisible(true)
  }
  const handleSearch = e => {
    setSearchedValue(e)
    if (Budget_Exist_Status == 0) {
      setIsModalOpenbudget(true)
    } else {
      if (e !== '' && e !== undefined && e !== null) {
        const filteredData = backupData.filter(x =>
          x.search?.toLocaleLowerCase()?.includes(e?.toLocaleLowerCase())
        )
        setData(filteredData)
      } else {
        setData(backupData)
      }
    }
  }
  const handleCancelprod = () => {
    setIsModalOpentwo(false)
  }
  const handleOkprod = () => {
    setIsModalOpentwo(false)
  }
  const removeLineItem = params => {
    let statusItemData = {
      restid: RestID, //
      catId: params.row.original.catId,
      catName: params.row.original.category,
      itemId: params.row.original.id, //
      status: 0, //
      itemName: params.row.original.item
    }
    mainAxios
      .post('/api/Inventory/updateItemStatus', statusItemData)
      .then(res => {
        if (res.data?.status.toString() === '1') {
          setModalVisible(false)
          getData()
          toast.success('Item Successfully Removed from Master Sheet')
        } else {
          setModalVisible(false)
          toast.error('Failed to Remove Item')
        }
      })
      .catch(err => {
        setModalVisible(false)
        toast.error('Failed to Remove Item')
      })
  }

  const getData = () => {
    setProgressBar(true)
    setTableView(true)
    mainAxios
      .get('/api/Inventory/null/' + RestID)
      .then(res => {
        setIsLoading(false)
        setDynamoData(res.data)
        setProductList(res.data)
        setProgressBar(false)
        if (res.data.Data.InventoryDetails.length == 0) {
          setNodata(true)
          setIsModalOpentwo(true)
        } else {
          const data = res.data.Data.InventoryDetails.map(cat =>
            cat.Items?.filter(item => parseInt(item.Status) == 1)
          ).flat().length
          if (data == 0) {
            setNodata(true)
          } else {
            setNodata(false)
          }
        }
      })
      .catch(err => {
        setProductList([])
        setIsLoading(false)
        setDynamoData([])
        setProgressBar(false)
        console.log(err)
      })
  }

  useEffect(() => {
    getData()
  }, [trigger])
  useEffect(() => {
    if (dynamoData?.Data) {
      if (
        dynamoData.Data?.InventoryDetails &&
        dynamoData.Data?.InventoryDetails?.length > 0
      ) {
        const temp = []
        const da = dynamoData?.Data?.InventoryDetails.map(cat => {
          temp.push({
            label: cat.CatName,
            value: `${cat.CatName}~${cat.CatId}`
          })
          return cat.Items.map(item => {
            return {
              category: cat.CatName,
              subCategory: item?.SubCatName,
              catId: cat.CatId,
              item: item.Name,
              unitPrice: item.Price,
              unit: item.Unit,
              id: item.id,
              Status: item.Status.toString(),
              CreatedAt: item.CreatedAt
                ? dayjs(item.CreatedAt).format('MM/DD/YYYY')
                : '',
              Multiple_Unit: item.Multiple_Unit ? item.Multiple_Unit : [],
              search: item.Name,
              orderid: item?.orderid,
              itemorderid: item?.itemorderid
            }
          })
        })
        setData(
          [
            ...da
              .flat()
              .flat()
              .filter(z => z.Status === '1')
          ].sort((a, b) => {
            if (a.itemorderid && b.itemorderid) {
              return a.itemorderid - b.itemorderid
            } else {
              return 0
            }
          })
        )

        setIsLoading(false)
        setBackupData(
          [
            ...da
              .flat()
              .flat()
              .filter(z => z.Status === '1')
          ].sort((a, b) => {
            if (a.itemorderid && b.itemorderid) {
              return a.itemorderid - b.itemorderid
            } else {
              return 0
            }
          })
        )
      }
    }
  }, [dynamoData])
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const columns = useMemo(
    () => [
      {
        accessorKey: 'category', //access nested data with dot notation
        header: 'Category',
        size: 130,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'subCategory', //access nested data with dot notation
        header: 'Sub Category',
        size: 155,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'item',
        header: 'Item',
        size: 430,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          if (row.original.CreatedAt !== '') {
            const createddate = dayjs(row.original.CreatedAt)

            const nextmonth = createddate.add(31, 'day')

            if (dayjs().isBefore(nextmonth)) {
              return (
                <>
                  <span>{row.original.item}</span> &nbsp; &nbsp;
                  <StarFilled style={{ color: '#ffd700' }} />
                </>
              )
            } else {
              return (
                <>
                  <span>{row.original.item}</span>
                </>
              )
            }
          } else {
            return (
              <>
                <span>{row.original.item}</span>
              </>
            )
          }
        }
      },
      {
        accessorKey: 'unit', //normal accessorKey
        header: 'Unit',
        size: 110,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.category != '' ? (
                <span>{row?.original.unit}</span>
              ) : (
                <span>
                  {row?.original.multiName}({row.original.multiQnty}&nbsp;
                  {row.original.unit})
                </span>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'unitPrice',
        header: 'Unit Price',
        size: 130,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        sortDescFirst: true,
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.unitPrice != '' ? (
                currencyFormatter.format(row?.original.unitPrice)
              ) : (
                <div></div>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: params => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Tooltip title='Remove Item'>
              <CancelIcon
                // onClick={() => {
                //   removeLineItem(params)
                // }}
                onClick={() => handleIconClick(params)}
                style={{ color: 'red', fontSize: '17px', cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        )
      }
    ],
    []
  )

  const handleOrderChange = orderedData => {
    const payload = orderedData.reduce((acc, item) => {
      const existingCategory = acc.find(
        category =>
          category.catId === item.catId && category.catName === item.category
      )

      if (existingCategory) {
        const existingItem = existingCategory.items.find(
          existingItem => existingItem.itemId === item.id
        )

        if (existingItem) {
          existingItem.orderid = item.orderid
        } else {
          existingCategory.items.push({
            itemId: item.id,
            orderid: item.orderid
          })
        }
      } else {
        acc.push({
          catId: item.catId,
          catName: item.category,
          items: [{ itemId: item.id, orderid: item.orderid }]
        })
      }

      return acc
    }, [])

    mainAxios
      .post('/api/Inventory/UpdateMasterSheetOrders/' + RestID, payload)
      .then(res => {
        //console.log("ressss",res)
        if (res.data?.Status.toString() === '1') {
          getData()
          toast.success('Orders saved Successfully')
        } else {
          toast.error('Failed Ordering Item.')
        }
      })
      .catch(() => {
        toast.error('Failed Ordering Item.')
      })
  }

  const handleModalClose = () => {
    setModalVisible(false)
  }

  const handleRowReorder = (draggingRow, hoveredRow) => {
    if (hoveredRow && draggingRow) {
      const updatedData = [...data]
      const movedItem = updatedData.splice(draggingRow.index, 1)[0]
      updatedData.splice(hoveredRow.index, 0, movedItem)
      setData(updatedData)

      handleOrderChange(
        updatedData.map((item, index) => ({ ...item, orderid: index + 1 }))
      )
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableRowSelection: true,
    getRowId: row => {
      return `${row.id}~${row.category}~${row.catId}`
    },
    enablePagination: false,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableColumnPinning: false,
    autoResetPageIndex: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableRowOrdering: true,
    enablePinning: false,
    muiRowDragHandleProps: table => {
      return {
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.table.getState()
          handleRowReorder(draggingRow, hoveredRow)
        }
      }
    },
    initialState: { density: 'compact' },
    onRowSelectionChange: setRowSelection,
    layoutMode: 'semantic',
    state: {
      rowSelection,
      isLoading
    }
  })
  const handleMultipleCheckBox = () => {
    const arr = []
    setAddMasterSheetLoader(true)
    for (const key in rowSelection) {
      if (rowSelection.hasOwnProperty(key)) {
        if (rowSelection[key]) {
          let d = key.split('~')
          let json = {
            restid: RestID,
            catId: d[2],
            catName: d[1],
            itemId: d[0],
            status: 0
          }
          arr.push(json)
        }
      }
    }
    console.log(arr)
    mainAxios
      .post('api/Inventory/AddToMasterCountsheet', arr)
      .then(e => {
        setAddMasterSheetLoader(false)
        setRowSelection({})
        getData()

        toast.success('Selected Item Removed From Master Countsheet')
      })
      .catch(e => {
        setAddMasterSheetLoader(false)
        console.log(e)
        toast.error('Error while removing selected items from masterCountsheet')
      })
  }

  return (
    <>
      {/* {progressBar && (
          <CircularProgress
            className='ExceptionalCircularProgress'
            color='success'
            disableShrink
            style={{ margin: '0 auto', display: 'block' }}
          />
        )} */}
      {nodata && (
        <div
          className=''
          style={{
            height: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img style={{ width: '5em' }} src='/no-item.png' />
            <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
          </div>
        </div>
      )}
      {!nodata && (
        <div className='flexsearchbut'>
          <div className='searchbarpro'>
            {isTableview && (
              <Input
                placeholder='Search'
                style={{ width: '280px' }}
                value={searchedValue}
                onChange={e => {
                  handleSearch(e.target.value)
                }}
                prefix={
                  <SearchOutlined
                    style={{ fontSize: '20px', color: '#D3D2D6' }}
                  />
                }
              />
            )}
          </div>
        </div>
      )}
      <div
        className='overallhistory product-table'
        style={{ paddingBottom: '25px' }}
      >
        <>
          {' '}
          {Budget_Exist_Status == 0 ? (
            <div
              className='no-inventory'
              style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '5em' }} src='/no-item.png' />
                <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
              </div>
            </div>
          ) : (
            !nodata && (
              <ThemeProvider theme={customTheme}>
                {isTableview && <MaterialReactTable table={table} />}
              </ThemeProvider>
            )
          )}
        </>
      </div>
      {Object.keys(rowSelection).length !== 0 && (
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            backgroundColor: '#fff',
            marginLeft: '-40px',
            zIndex: '99999'
          }}
        >
          <div className='onetwoline'>
            <div
              className='footercountsheet'
              style={{ justifyContent: 'flex-end' }}
            >
              <div className='threebuttons'>
                <Button
                  style={{
                    border: '1px solid #E54643',
                    backgroundColor: 'rgba(229, 70, 67, 0.11)',
                    color: '#E54643'
                  }}
                  loading={addMasterSheetLoader}
                  onClick={() => {
                    handleMultipleCheckBox()
                  }}
                >
                  Remove From Master Sheet
                </Button>
                <Button
                  style={{
                    border: '1px solid #DDD',
                    backgroundColor: '#F5F5F5',
                    color: '#212529'
                  }}
                  onClick={() => {
                    setRowSelection({})
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title='Add Inventory Products'
        open={IsModalOpentwo}
        onOk={handleOkprod}
        onCancel={handleCancelprod}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprod}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Navigate to the Products tab to add your Inventory Products. Consider
          uploading recent invoices to assist in setting up your inventory.
        </p>
      </Modal>
      <Modal
        title='Set Up Your Budget'
        open={IsModalOpenbudget}
        onCancel={() => setIsModalOpenbudget(false)}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprodbudget}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Please establish a budget to activate the Inventory Feature.
        </p>
      </Modal>
      <Modal
        title='Remove Item'
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={handleModalClose}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={() => removeLineItem(datatoberemoved)}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        {/* Your modal content goes here */}
        <p className='addnewcount1'>
          Please confirm the removal of the item '
          <span>
            {datatoberemoved ? datatoberemoved.row.original.item : ''}
          </span>
          ' from the Master Sheet.
        </p>
      </Modal>
    </>
  )
}

export default ProductWise
