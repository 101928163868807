import React, { useState } from "react";
import CategoryWise from "./CategoryWise";
import ProductWise from "./ProductWise";

const CountSheet = () => {
  const [value, setValue] = useState(
    parseInt(localStorage.getItem("templateType") || 0)
  );
  return (
    <>{value === 1 ? <CategoryWise /> : value === 2 ? <ProductWise /> : null}</>
  );
};

export default CountSheet;
