import React, { forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import { PDFExport } from "@progress/kendo-react-pdf";

const Print = forwardRef((props, ref) => {
  const { detailedData, historyData, productsData} = props;
  const userClaims = localStorage.getItem('UserClaims');


  let pdfExportComponent;

  const pageTemplate = (props) => {
    return (
      <>
        <div
          className="row col-md-11 position-absolute mx-auto bottom-20 top-0 left-0 right-0"
          style={{
            fontSize: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop:10,
            marginBottom:10
          }}
        >
          <table className="toptable">
            <tbody>
              <td
                className='col-6'
                style={{ fontSize: 10, fontWeight: 'bold' }}
              >
                {JSON.parse(userClaims)?.restaurent_name}
              </td>
              <td
                className='col-6'
                style={{ fontSize: 10, textAlign:'right' }}
              >
                Printed on: {dayjs().format('MM/DD/YYYY hh:mmA')}
              </td>
            </tbody>
          </table>
        </div>
        <div
            className="position-absolute bottom-2 start-50 translate-middle-x"
            style={{fontSize: 10}}
          >
            Page {props.pageNum} of {props.totalPages}
        </div>
      </>
    )
  }

  const handlePrint = () => {
    pdfExportComponent && pdfExportComponent.save();
  };

  useImperativeHandle(ref, () => ({
    handlePrint
  }));

  return (
    <>
      {detailedData &&
       <PDFExport
       paperSize="A4"
       landscape={false}
       margin="1cm"
       scale={0.8}
       ref={(pdfExport) => (pdfExportComponent = pdfExport)}
       pageTemplate={pageTemplate}
       fileName='InventoryClosedCountsheet'
       repeatHeaders={true}
     >
       <div className="Generatedpdf">
         <div className="mx-4">
           <div className="row justify-content-between">
             <div className="col-md-12 fw-semibold text-end">
               Inventory Date: {dayjs(detailedData?.Date).format('MM/DD/YYYY')}
             </div>
           </div>
     
           {/* Header outside the loop */}
           <table className="pdfTable">
             
           </table>
     
           {detailedData?.InventoryDetails?.map(data => {
             if (data?.Items && data?.Items.length > 0) {
               return (
                 <>
                   <div className="my-3 fw-medium">{data?.CatName}</div>
                   <table className="pdfTable">
                   <thead>
               <tr>
                 <th className="theadBackground" style={{ width: '62%'}}>Item</th>
                 <th className="theadBackground" style={{ width: '12%'}}>Unit</th>
                 <th className="theadBackground" >Price</th>
                 <th className="theadBackground">Count</th>
                 <th className="theadBackground" >Total</th>
               </tr>
             </thead>
                     <tbody>
                       {data?.Items.map(tableData => {
                         return (
                           <tr key={tableData?.Name}>
                             <td
                               className="px-1"
                               style={{
                                 wordBreak: 'break-word',
                                 wordWrap: 'break-word',
                               }}
                             >
                               {tableData?.Name}
                             </td>
                             <td>{tableData?.Unit}</td>
                             <td className="text-end">
                               ${parseFloat(tableData?.Price)?.toFixed(2)}
                             </td>
                             <td className="text-end">{tableData?.Count ? tableData?.Count : 0}</td>
                             <td className="text-end">
                               ${tableData?.Amount ? parseFloat(tableData?.Amount).toFixed(2) : parseFloat(0).toFixed(2)}
                             </td>
                           </tr>
                         )
                       })}
                     </tbody>
                   </table>
                   <div
                     className="text-end fw-semibold"
                     style={{ fontSize: 10 }}
                   >
                     {data?.CatName}: ${data?.totalAmount?.toFixed(2)}
                   </div>
                 </>
               )
             } else {
               return null;
             }
           })}
           <div
             className="col-md-12 fw-semibold text-end mt-3"
             style={{ fontSize: 12 }}
           >
             Inventory Total: ${detailedData?.InventoryDetails?.reduce((sum, currentVal) => sum + currentVal?.totalAmount, 0)}
           </div>
         </div>
       </div>
     </PDFExport>
      }
      {historyData &&
        <PDFExport
          paperSize="A4"
          landscape={false}
          margin="1cm"
          scale={0.8}
          ref={(pdfExport) => (pdfExportComponent = pdfExport)}
          pageTemplate={pageTemplate}
          fileName='InventoryHistory'
          repeatHeaders={true}
        >
          <div className="Generatedpdf">
            <div className="mx-4">
              <table className="pdfTable">
                <thead>
                  <tr>
                    <th className="theadBackground">Date</th>
                    <th className="theadBackground">Status</th>
                    <th className="theadBackground">Food</th>
                    <th className="theadBackground">Others</th>
                    <th className="theadBackground">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData?.map(data => {
                    return (
                      <tr>
                        <td>{data?.date}</td>
                        <td>{data?.status}</td>
                        <td style={{ textAlign: 'right' }}>${data?.food}</td>
                        <td style={{ textAlign: 'right' }}>${data?.others}</td>
                        <td style={{ textAlign: 'right' }}>${data?.total}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </PDFExport>
      }
      {productsData &&
        <PDFExport
          paperSize="A4"
          landscape={false}
          margin="1cm"
          scale={0.8}
          ref={(pdfExport) => (pdfExportComponent = pdfExport)}
          pageTemplate={pageTemplate}
          fileName='InventoryProducts'
          repeatHeaders={true}
        >
          <div className="Generatedpdf">
            <div className="mx-4">
              <table className="pdfTable">
                <thead>
                  <tr>
                    <th className="theadBackground">Category</th>
                    <th className="theadBackground">Item</th>
                    <th className="theadBackground">Unit</th>
                    <th className="theadBackground">Unit Price</th>
                    <th className="theadBackground">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {productsData?.map(data => {
                    return (
                      <tr>
                        <td>{data?.category}</td>
                        <td>{data?.item}</td>
                        <td>{data?.unit}</td>
                        <td style={{ textAlign: 'right' }}>{data?.unitPrice}</td>
                        <td>{data?.Status === "1" ? "Active" : "Deactive"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </PDFExport>
      }
    </>
  )
})

export default Print;
