import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
// import "../Inventory/Inventory.css";
import '../Inventory.css'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { DatePicker, Button, Input, Modal, Tooltip } from 'antd'
import { FaTrash, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { styled } from '@mui/material/styles'
import { NumericFormat } from 'react-number-format'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { DataGrid } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { mainAxios } from '../../middleware/interceptor'
import { Logger } from '../../middleware/Logger'
import 'react-toastify/dist/ReactToastify.css'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { CircularProgress } from '@mui/material'
import { StarFilled, HistoryOutlined } from '@ant-design/icons'
import { useCallback } from 'react'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '0px',
  gap: '5px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '0px'
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #22AF41'
}))

const CategoryWise = () => {
  const dateFormat = 'MM/DD/YYYY'
  const navigate = useNavigate()
  const [InventoryItems, setInventoryItems] = useState([])
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const [BackupData, setBackupData] = useState([])
  const [dynamoData, setDynamoData] = useState([])
  const { Inventory_date } = useParams()
  const [last, setlast] = useState([])
  const [load, setload] = useState(false)
  const [inventoryDate, setInventoryDate] = useState(dayjs(Inventory_date))
  const [inventoryId, setInventoryId] = useState()
  const [expanded, setExpanded] = useState('panel1')
  const [expandedsub, setExpandedsub] = useState('')
  const [total, setTotal] = useState(0)
  const [progressBar, setProgressBar] = useState(false)
  const [nodata, setNodata] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')
  const [subvalue, setsubvalue] = useState([])
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const [currentAccordionIndex, setCurrentAccordionIndex] = useState(0)
  const [openCountSheetHistory, setOpenCountSheetHistory] = useState()
  const [data, setData] = useState()
  const [modalopen, setmodelopen] = useState(false)
  const [itemdata, setItemsData] = useState()
  const [previousCount, setPreviousCount] = useState('')

  useEffect(() => {
    LoadInventoryItems(RestID, Inventory_date)
  }, [])
  let finalresult = []
  let finalsub = []
  const onSearchItems = (value, _e, info) => {
    setSearchedValue(value)
    if (value != '') {
      const searchTerm = value.toLowerCase()
      var index = 0
      const searchResults = BackupData.reduce((acc, category) => {
        const filteredItems = category.Items.filter(item =>
          item?.Name.toLowerCase().includes(searchTerm)
        )

        category?.groupBy &&
          Object.keys(category?.groupBy).length > 0 &&
          acc.push({
            ...category,
            groupBy: {
              ...category?.groupBy,
              ...Object.keys(category?.groupBy).reduce((newGroupBy, key) => {
                newGroupBy[key] = category?.groupBy[key]?.filter(item =>
                  item.Name.toLowerCase().includes(searchTerm)
                )
                index++
                return { ...newGroupBy, subid: index }
              }, {})
            },
            Items: filteredItems
          })

        // if (filteredItems?.length > 0) {
        //   acc.push({
        //     ...category,
        //     Items: filteredItems,
        //   })
        //          }

        // calculateTotal(BackupData);
        return acc
      }, [])
      searchResults?.forEach(item => {
        item.Items.forEach(item1 => {
          finalsub.push(item1.Name)
        })
      })

      setsubvalue(finalsub)
      if (finalsub.flat()?.length == 0) {
        setNodata(true)
      } else {
        setNodata(false)
      }
      const backupresultcat = searchResults
        .map(
          item =>
            item?.groupBy &&
            Object.keys(item?.groupBy).length > 0 &&
            Object.keys(item.groupBy)
              .filter(item1 => item.groupBy[item1].length > 0)
              .map(filteredItem1 => item.CatId)
        )
        .filter(backupItem => backupItem)

      const backupresult = searchResults
        .map(
          item =>
            item?.groupBy &&
            Object.keys(item?.groupBy).length > 0 &&
            Object.keys(item.groupBy)
              .filter(item1 => item.groupBy[item1].length > 0)
              .map(filteredItem1 => item)
        )
        .filter(backupItem => backupItem)
      const uniqueArray = [...new Set(backupresult.flat())]
      const uniqueArraycatid = [...new Set(backupresultcat.flat())]

      if (finalresult?.length == 0) {
        finalresult = [...uniqueArraycatid]
        setlast(finalresult)
      } else {
        uniqueArraycatid?.forEach(item =>
          finalresult.push(
            finalresult.filter(item1 => item1.CatId !== item.CatId)
          )
        )
      }

      setInventoryItems(uniqueArray)
    } else {
      let check = []
      setInventoryItems(BackupData)
      BackupData?.forEach(item => {
        item.Items?.forEach(item1 => {
          check.push(item1.Name)
        })
      })

      check.length > 0 ? setNodata(false) : setNodata(true)
      setsubvalue([])
    }
    setlast(finalresult)
  }
  const handleKeyDown = useCallback(e => {
    if (searchedValue?.length > 0) {
      if (e.key === 'ArrowDown') {
        handleNextValue()
      } else if (e.key === 'ArrowUp') {
        handlePreviousValue()
      }
    }
  })

  useEffect(() => {
    if (searchedValue?.length > 0) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  ///////////////////////////22-01-2024//////////////////////////
  // useEffect(() => {
  //   //     if (dynamoData?.Data) {
  //   //       if (
  //   //         dynamoData.Data?.InventoryDetails &&
  //   //         dynamoData.Data?.InventoryDetails?.length > 0
  //   //       ) {
  //   //         const temp = []

  //   //         console.log()
  //   //         const da = dynamoData?.Data?.InventoryDetails.map(cat => {

  //   //           return cat.Items.map(item => {
  //   //             if (item?.Multiple_Unit && item?.Multiple_Unit?.length > 0) {
  //   //               console.log(item, 'LPLPL')
  //   //               let temp = item?.Multiple_Unit?.map(mUnit => {
  //   //                 return {
  //   //                   category: "",
  //   //                   catId: cat.CatId,
  //   //                   item: "",
  //   //                   unitPrice:currencyFormatter.format(mUnit.Price),
  //   //                   unit: mUnit.Name,
  //   //                   multiQnty:mUnit.Quantity,
  //   //                   multiPrice:mUnit.price,
  //   //                   multiName:mUnit.UnitName,
  //   //                   id: item.id,
  //   //                   Status: item.Status,
  //   //                   CreatedAt:"",
  //   //                   Multiple_Unit: [],
  //   //                   Amount:item.Amount,
  //   //                   CreatedAt:item.CreatedAt,
  //   //                   PreAmount:item.PreAmount,
  //   //                   PreCount:item.PreCount,
  //   //                   Price:item.Price,
  //   //                   Count:item.Count,
  //   //                   Name:"",
  //   //                  Status:item.Status

  //   //                 }
  //   //               })

  //   //               let json = {
  //   //                 category: cat.CatName,
  //   //                 catId: cat.CatId,
  //   //                 item: item.Name,
  //   //                 unitPrice: currencyFormatter.format(item.Price),
  //   //                 unit: item.Unit,
  //   //                 id: item.id,
  //   //                 Status: item.Status,
  //   //                 CreatedAt: item.CreatedAt
  //   //                   ? dayjs(item.CreatedAt).format('MM/DD/YYYY')
  //   //                   : '',
  //   //                 Multiple_Unit: item.Multiple_Unit?item.Multiple_Unit:[],
  //   //                 Amount:item.Amount,
  //   //                 CreatedAt:item.CreatedAt,
  //   //                 PreAmount:item.PreAmount,
  //   //                 PreCount:item.PreCount,
  //   //                 Price:item.Price,
  //   //                 Count:item.Count,
  //   //                 Name:item.Name,
  //   //                Status:item.Status
  //   //               }
  //   //              // temp.push(json)
  //   //               return [json,...temp]
  //   //             } else {
  //   //               return {
  //   //                 category: cat.CatName,
  //   //                 catId: cat.CatId,
  //   //                 item: item.Name,
  //   //                 unitPrice: currencyFormatter.format(item.Price),
  //   //                 unit: item.Unit,
  //   //                 id: item.id,
  //   //                 Status: item.Status,
  //   //                 CreatedAt: item.CreatedAt
  //   //                   ? dayjs(item.CreatedAt).format('MM/DD/YYYY')
  //   //                   : '',
  //   //                   Multiple_Unit: item.Multiple_Unit?item.Multiple_Unit:[],
  //   //                   Amount:item.Amount,
  //   //                   CreatedAt:item.CreatedAt,
  //   //                   PreAmount:item.PreAmount,
  //   //                   PreCount:item.PreCount,
  //   //                   Price:item.Price,
  //   //                   Count:item.Count,
  //   //                   Name:item.Name,
  //   //                  Status:item.Status
  //   //               }
  //   //             }
  //   //           })
  //   //         })
  //   //         console.log(da.flat().flat(), 'Looop')
  //   //         //setCategoryDropDown(temp)
  //   //         setData(da.flat().flat())
  //   // console.log(InventoryItems,"console")
  //   // console.log(da.flat().flat(),"console1")

  //   //         setBackupData(da.flat().flat())
  //   //       }
  //   //     }

  //   // Assuming 'data' is your original data object

  //   // Assuming 'data' is your original data object

  //   console.log(dynamoData, 'dynamodataF')
  //   const modifiedData = {
  //     InventoryDetails: dynamoData.map(inventoryDetail => ({
  //       ...inventoryDetail,
  //       Items: inventoryDetail.Items.flatMap(item => {
  //         const hasMultipleUnits =
  //           item?.Multiple_Unit && item.Multiple_Unit.length > 0

  //         if (!hasMultipleUnits) {
  //           // If no Multiple_Unit, just return the original item
  //           return item
  //         }

  //         // Map over both the original item and the items in the Multiple_Unit array
  //         return [
  //           item,
  //           ...item.Multiple_Unit.map((mUnit, index) => ({
  //             ...item,
  //             Unit: mUnit.Name,
  //             multiQnty: mUnit.Quantity,
  //             multiPrice: mUnit.multiPrice,
  //             multiName: mUnit.UnitName,
  //             id: `${item.id}_${index}`, // Adding a unique identifier
  //             Status: item.Status,
  //             CreatedAt: item.CreatedAt,
  //             Amount: item.Amount,
  //             PreAmount: item.PreAmount,
  //             PreCount: '',
  //             Price: mUnit.Price,
  //             Count: item.Count,
  //             Name: ''
  //           }))
  //         ]
  //       })
  //     }))
  //   }

  //   // Now 'modifiedData' contains the updated data with the new attribute and additional fields based on Multiple_Unit array length

  //   // Now 'modifiedData' contains the updated data with the new attribute and additional fields based on Multiple_Unit array length
  //   setInventoryItems(modifiedData.InventoryDetails)

  //   console.log(modifiedData.InventoryDetails, 'modifiedData.InventoryDetails')
  //   setBackupData(JSON.parse(JSON.stringify(modifiedData.InventoryDetails)))
  //   calculateTotal(modifiedData.InventoryDetails)
  // }, [dynamoData])
  ////////////////////////////////////////////////////////////////
  const handlePreviousValue = () => {
    if (
      searchedValue?.length > 0 &&
      searchedValue.replace(/\s/g, '') != '' &&
      nodata !== true
    ) {
      setHighlightedIndex(prevIndex => {
        const isFirstItemInAccordion = prevIndex === 0

        if (isFirstItemInAccordion) {
          const newIndex =
            currentAccordionIndex === 0
              ? InventoryItems?.length - 1
              : currentAccordionIndex - 1
          setCurrentAccordionIndex(newIndex)
          return InventoryItems[newIndex].Items?.length - 1
        } else {
          return prevIndex - 1
        }
      })
    }
  }

  const handleNextValue = () => {
    if (
      searchedValue?.length > 0 &&
      searchedValue.replace(/\s/g, '') != '' &&
      nodata !== true
    ) {
      setHighlightedIndex(prevIndex => {
        const totalItems = InventoryItems[currentAccordionIndex].Items?.length
        const isLastItemInAccordion = (prevIndex + 1) % totalItems === 0

        if (isLastItemInAccordion) {
          const newIndex =
            currentAccordionIndex === InventoryItems?.length - 1
              ? 0
              : currentAccordionIndex + 1
          setCurrentAccordionIndex(newIndex)
          return 0
        } else {
          return prevIndex + 1
        }
      })
    }
  }

  const handleChange = panel => (event, newExpanded) => {
  console.log(panel,newExpanded,"panl")
    setExpanded(newExpanded ? panel : false)
    newExpanded&&setExpandedsub(false)
    finalresult = last.filter(item => item !== panel)
    setlast(finalresult)
    setHighlightedIndex(0)
    const newIndex = InventoryItems.findIndex(
      category => category.CatId === panel
    )
    setCurrentAccordionIndex(newIndex !== -1 ? newIndex : 0)
  }

  const handlesubChange = (panel, catval) => (event, newExpanded) => {
    const name = catval.map(item => item.Name)
    finalresult = subvalue.filter(item => !name.includes(item))

    setsubvalue(finalresult)
    setExpandedsub(newExpanded ? panel : false)
  }

  const calculateTotal = ResponseArray => {
    let sum = 0
    ResponseArray?.forEach(category => {
      category?.Items?.forEach(item => {
        if (item.Amount == null || item.Amount == '' || isNaN(item.Amount)) {
          // sum += parseFloat(item.Price);
        } else {
          sum += parseFloat(item.Amount)
        }
      })
    })
    setTotal(sum)
  }

  const LoadInventoryItems = (RestID, Inventory_date) => {
    setProgressBar(true)
    mainAxios
      .get('/api/Inventory/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          setInventoryId(res.data.Data.InventoryId)
          setDynamoData(
            JSON.parse(JSON.stringify(res.data.Data.InventoryDetails))
          )
          if (res.data.Data.InventoryDetails.length == 0) {
            setNodata(true)
          } else {
            const data = res.data.Data.InventoryDetails.map(cat =>
              cat.Items?.filter(item => parseInt(item.Status) == 1)
            ).flat().length
            if (data == 0) {
              setNodata(true)
            } else {
              setNodata(false)
            }
          }
          calculateTotal(res.data.Data.InventoryDetails)
          GetOpenCountsheetHistory(res.data.Data.InventoryId)
          setProgressBar(false)
        } else {
          setInventoryItems([])
          setProgressBar(false)
          setNodata(true)
        }
      })
      .catch(err => {
        setInventoryItems([])
        setProgressBar(false)
        setNodata(true)
        console.log(err)
      })
  }

  //group by subcategory
  useEffect(() => {
    const checking = dynamoData.map(bycategory => {
      const result = Object.groupBy(bycategory.Items, ({ SubCatName }) => {
        if (SubCatName == null) {
          return 'Uncategorized'
        } else {
          return SubCatName
        }
      })
      return { ...bycategory, groupBy: result }
    })

    setInventoryItems(checking)
    setBackupData(JSON.parse(JSON.stringify(checking)))
  }, [dynamoData])

  const GetOpenCountsheetHistory = IdOfInventory => {
    mainAxios
      .post(`/api/Inventory/GetCountSheetHistory/${RestID}/${IdOfInventory}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        setOpenCountSheetHistory(response.data.CountsheetHistory)
      })
      .catch(err => {
        setOpenCountSheetHistory([])
        console.log(err)
      })
  }

  const UpdateDateforInventory = (date, datestring) => {
    let updatedDateObject = {
      CurrentDate: Inventory_date,
      updatedDate: dayjs(datestring).format('YYYY-MM-DD')
    }

    mainAxios
      .post('/api/Inventory/updateDate/' + RestID, updatedDateObject, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          setInventoryDate(dayjs(datestring))
          toast.success('Count sheet Date Updated Successfully')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: updatedDateObject
          }
          Logger(RestID, 'Count sheet Date Updated', Details, inventoryId)
          setTimeout(() => {
            GetOpenCountsheetHistory(inventoryId)
            navigate('/countsheet/' + dayjs(datestring).format('YYYY-MM-DD'))
          }, [1500])
        } else {
          if (res.data.Error == 'Inventory Already Exist for this Date') {
            showModalforSameDateRestrict()
            setInventoryDate(dayjs(Inventory_date))
          } else if (res.data.Error === 'Body Required') {
            toast.info('Date required.')
          } else {
            toast.info(res.data.Error)
          }
        }
      })
      .catch(err => {
        toast.error('Error while updating Count sheet Date')
        console.log(err)
      })
  }

  const DeleteInventory = () => {
    setIsModalOpenOne(false)
    mainAxios
      .post('/api/Inventory/delete/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.Status == '1') {
          toast.success('Count sheet Deleted Successfully')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: ''
          }
          Logger(RestID, 'Count sheet Deleted', Details, inventoryId)
          setTimeout(() => {
            navigate('/')
          }, [1500])
        } else {
          toast.info(res.data.Error)
        }
      })
      .catch(err => {
        toast.error('Error while Deleting countsheet')
        console.log(err)
      })
  }

  const CountUpdateOnFocusOut = async (e, currentRow) => {
    const updatedData = [...InventoryItems]
    const requiredobject = updatedData.filter(obj =>
      obj.Items.some(item => item.id === currentRow.id)
    )

    const PreviousCount = await PreviousCountChecker(currentRow)

    const CountUpdates = {
      itemId: currentRow.id,
      catId: requiredobject[0].CatId,
      catName: requiredobject[0].CatName,
      itemName: currentRow.Name,
      itemUnit: currentRow.Unit,
      itemAmount: currentRow.Amount,
      itemCount:
        e.target.value == '0.'
          ? '0'
          : e.target.value == '.'
          ? null
          : e.target.value
    }

    if (currentRow.Count != undefined && currentRow.Count != null) {
      mainAxios
        .post(
          '/api/Inventory/updateItem/' + RestID + '/' + Inventory_date,
          CountUpdates,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        )
        .then(res => {
          if (res.data?.status?.toString() == '1') {
            //toast.success(res.data.message)
            //setBackupData(InventoryItems)
            let Details = {
              InventoryDate: Inventory_date,
              Payload: CountUpdates,
              PreviousCount: PreviousCount?.toString()
            }
            Logger(RestID, 'Count sheet Count Updated', Details, inventoryId)
          } else {
          }
        })
        .catch(err => {
          console.log('Count Change API Error : 0', err)
        })
    }
  }

  const CountValueUpdateonChange = (value, params) => {
    let SubCatName
    const updatedData = [...InventoryItems]
    const requiredobject = updatedData.filter(obj =>
      obj.Items.some(item => item.id === params.row.id)
    )

    const categoryIndex = updatedData.findIndex(
      cat => cat.CatId === requiredobject[0].CatId
    )
    const RowIndex = updatedData[categoryIndex].Items.findIndex(
      item => item.id === params.id
    )

    if (
      updatedData[categoryIndex].Items[RowIndex].SubCatName != null &&
      updatedData[categoryIndex].Items[RowIndex].SubCatName != ''
    ) {
      SubCatName = updatedData[categoryIndex].Items[RowIndex].SubCatName
    } else {
      SubCatName = 'Uncategorized'
    }

    const SubRowIndex = updatedData[categoryIndex].groupBy[
      SubCatName
    ].findIndex(item => item.id === params.id)

    const isValidInput = /^(?:\d*\.)?\d{0,5}$/.test(value)

    if (isValidInput) {
      updatedData[categoryIndex].Items[RowIndex].Count = value
      updatedData[categoryIndex].groupBy[SubCatName][SubRowIndex].Count = value

      const parsedValue = parseFloat(value)
      const parsedPrice = parseFloat(params.row.Price)

      updatedData[categoryIndex].Items[RowIndex].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)

      updatedData[categoryIndex].groupBy[SubCatName][SubRowIndex].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)
    }

    setInventoryItems(updatedData)
    CountValueUpdateBackupData(value, params)
  }

  const CountValueUpdateBackupData = (value, params) => {
    let SubCatName
    const updatedData = [...BackupData]
    const requiredobject = updatedData.filter(obj =>
      obj.Items.some(item => item.id === params.row.id)
    )

    const categoryIndex = updatedData.findIndex(
      cat => cat.CatId === requiredobject[0].CatId
    )
    const RowIndex = updatedData[categoryIndex].Items.findIndex(
      item => item.id === params.id
    )

    if (
      updatedData[categoryIndex].Items[RowIndex].SubCatName != null &&
      updatedData[categoryIndex].Items[RowIndex].SubCatName != ''
    ) {
      SubCatName = updatedData[categoryIndex].Items[RowIndex].SubCatName
    } else {
      SubCatName = 'Uncategorized'
    }

    const SubRowIndex = updatedData[categoryIndex].groupBy[
      SubCatName
    ].findIndex(item => item.id === params.id)

    const isValidInput = /^(?:\d*\.)?\d{0,5}$/.test(value)

    if (isValidInput) {
      updatedData[categoryIndex].Items[RowIndex].Count = value
      updatedData[categoryIndex].groupBy[SubCatName][SubRowIndex].Count = value

      const parsedValue = parseFloat(value)
      const parsedPrice = parseFloat(params.row.Price)

      updatedData[categoryIndex].Items[RowIndex].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)

      updatedData[categoryIndex].groupBy[SubCatName][SubRowIndex].Amount =
        isNaN(parsedValue) || isNaN(parsedPrice)
          ? '0.00'
          : (parsedPrice * parsedValue).toFixed(2)
    }

    setBackupData(updatedData)
    calculateTotal(updatedData)
  }

  const PreviousCountChecker = currentRow => {
    return new Promise((resolve, reject) => {
      const BackUpTemp = [...BackupData]
      const requiredobject = BackUpTemp.filter(obj =>
        obj.Items.some(item => item.id === currentRow.id)
      )

      const categoryIndex = BackUpTemp.findIndex(
        cat => cat.CatId === requiredobject[0].CatId
      )
      const RowIndex = BackUpTemp[categoryIndex].Items.findIndex(
        item => item.id === currentRow.id
      )

      const PrevCount = BackUpTemp[categoryIndex].Items[RowIndex].Count
      BackUpTemp[categoryIndex].Items[RowIndex].Count = currentRow.Count
      setBackupData(BackUpTemp)

      resolve(PrevCount)
    })
  }

  const SaveandCloseInventory = () => {
    setload(true)
    mainAxios
      .post('/api/Inventory/status/' + Inventory_date + '/' + RestID, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => {
        if (res.data.status == '1') {
          setmodelopen(false)
          toast.success(' Inventory has been successfully saved and closed.')
          let Details = {
            InventoryDate: Inventory_date,
            Payload: ''
          }
          Logger(RestID, 'Count sheet Saved and Closed', Details, inventoryId)
          setTimeout(() => {
            navigate('/')
          }, [1500])
        } else {
          setmodelopen(false)
          setload(false)
          toast.info(res.data.Error)
        }
      })
      .catch(err => {
        setmodelopen(false)
        toast.error('Error while Saving and Closing Countsheet')
        console.log(err)
      })
  }

  const [isModalOpenone, setIsModalOpenOne] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  const showModalforSameDateRestrict = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showModalForConfirmingDelete = () => {
    setIsModalOpenOne(true)
  }
  const handleOkone = () => {
    setIsModalOpenOne(false)
  }
  const handlesaveclose = () => {
    setmodelopen(true)
  }
  const handlesaveclosecancel = () => {
    setmodelopen(false)
  }
  const handleCancelone = () => {
    setIsModalOpenOne(false)
  }

  const showDrawer = () => {
    setOpenDrawer(true)
    GetOpenCountsheetHistory(inventoryId)
  }
  const onClose = () => {
    setOpenDrawer(false)
  }

  const convertDateFormat = inputDate =>
    inputDate
      ? new Date(inputDate).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
      : ''

  const { Search } = Input

  const columns = [
    {
      field: 'Name',
      headerName: 'Item',
      flex: 1,
      minWidth: 400,
      renderCell: row => {
        if (row.row.CreatedAt !== null) {
          const createddate = dayjs(row.row.CreatedAt)

          const nextmonth = createddate.add(31, 'day')

          if (dayjs().isBefore(nextmonth)) {
            return (
              <>
                <span>{row.row.Name}</span> &nbsp; &nbsp;{' '}
                <StarFilled style={{ color: '#ffd700' }} />
              </>
            )
          } else {
            return (
              <>
                <span>{row.row.Name}</span>
              </>
            )
          }
        } else {
          return (
            <>
              <span>{row.row.Name}</span>
            </>
          )
        }
      }
    },
    // {
    //   field: 'SubCatName',
    //   headerName: 'Sub Category',
    //   flex: 1,
    //   width: 100
    // },
    {
      field: 'Unit',
      headerName: 'Unit',
      flex: 1,
      width: 100,
      renderCell: params => {
        return (
          <>
            {params?.row.Name != '' ? (
              <span>{params?.row.Unit}</span>
            ) : (
              <span>
                {params?.row.multiName}({params.row.multiQnty}&nbsp;
                {params?.row.Unit})
              </span>
            )}
          </>
        )
      }
    },

    {
      field: 'Price',
      headerName: 'Price',
      width: 150,
      align: 'left',
      renderCell: params => {
        return (
          <>
            {currencyFormatter.format(parseFloat(params.row?.Price))}
            {params.row?.DiffPrice && params.row?.DiffPrice != 0 ? (
              params.row.DiffPrice < 0 ? (
                <Tooltip
                  title={
                    <span style={{ color: '#22af41' }}>
                      ${' '}
                      {parseFloat(params.row.DiffPrice)
                        .toFixed(2)
                        .toString() === '0'
                        ? '0.00'
                        : Math.abs(parseFloat(params.row.DiffPrice))
                            .toFixed(2)
                            .toString()}
                    </span>
                  }
                >
                  <SouthIcon
                    style={{
                      fontSize: '15px',
                      marginLeft: ' 2px',
                      color: '#2BAB47',
                      marginTop: '-3px',
                      cursor: 'pointer'
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <span style={{ color: '#ff5653' }}>
                      ${' '}
                      {parseFloat(params.row.DiffPrice).toFixed(2).toString() ==
                      '0'
                        ? '0.00'
                        : parseFloat(params.row.DiffPrice)
                            .toFixed(2)
                            .toString()}
                    </span>
                  }
                >
                  <NorthIcon
                    style={{
                      fontSize: '15px',
                      marginLeft: ' 2px',
                      color: '#FF5653',
                      marginTop: '-3px',
                      cursor: 'pointer'
                    }}
                  />
                </Tooltip>
              )
            ) : null}
          </>
        )
      }
      // const formattedPrice = parseFloat(params.row.Price).toFixed(2)
      // const formattedNewPrice = params.row?.NewPrice
      //   ? parseFloat(params.row.NewPrice).toFixed(2)
      //   : null
      // // const isAmountGreaterThanPrice =
      // //   params.row.Amount > params.row.PreAmount
      // // const DifferenceAmount =
      // //   (params.row.Amount == '' ||
      // //   params.row.Amount == null ||
      // //   params.row.Amount == undefined
      // //     ? 0.0
      // //     : parseFloat(params.row.Amount).toFixed(2)) -
      // //   (params.row.PreAmount == '' ||
      // //   params.row.PreAmount == null ||
      // //   params.row.PreAmount == undefined
      // //     ? 0.0
      // //     : parseFloat(params.row.PreAmount).toFixed(2))
      // // const isAmountLessThanPrice = params.row.Amount < params.row.PreAmount

      // const Diffprice = params.row?.DiffPrice

      // let pricecheckhigh
      // if (Diffprice == 0 || Diffprice == undefined || isNaN(Diffprice)) {
      //   pricecheckhigh = false
      // } else {
      //   pricecheckhigh = true
      // }

      // const pricechecklow = Diffprice?.toString().includes('-')
      // // parseFloat(params.row.NewPrice) < parseFloat(params.row.Price)

      // return (
      //   <>
      //     {/* ${formattedPrice} {(params.row.Amount === "0" || params.row.Amount === null) && (params.row.PreAmount === "" || params.row.PreAmount === null) ? "" : isAmountGreaterThanPrice ? '↑' : isAmountLessThanPrice ? <i className="bi bi-arrow-up"></i> : ''} */}
      //     ${formattedNewPrice ? formattedNewPrice : formattedPrice}{' '}
      //     {Diffprice == undefined || Diffprice == null ? (
      //       ''
      //     ) : pricechecklow ? (
      //       <Tooltip
      //         title={
      //           <span style={{ color: '#22af41' }}>
      //             ${' '}
      //             {Diffprice?.toFixed(2).toString() === '0'
      //               ? '0.00'
      //               : Math.abs(Diffprice?.toFixed(2)).toString()}
      //           </span>
      //         }
      //       >
      //         <SouthIcon
      //           style={{
      //             fontSize: '15px',
      //             marginLeft: ' 2px',
      //             color: '#2BAB47',
      //             marginTop: '-3px',
      //             cursor: 'pointer'
      //           }}
      //         />
      //       </Tooltip>
      //     ) : pricecheckhigh ? (
      //       <Tooltip
      //         title={
      //           <span style={{ color: '#ff5653' }}>
      //             ${' '}
      //             {Diffprice?.toFixed(2).toString() == '0'
      //               ? '0.00'
      //               : Diffprice?.toFixed(2).toString()}
      //           </span>
      //         }
      //       >
      //         <NorthIcon
      //           style={{
      //             fontSize: '15px',
      //             marginLeft: ' 2px',
      //             color: '#FF5653',
      //             marginTop: '-3px',
      //             cursor: 'pointer'
      //           }}
      //         />
      //       </Tooltip>
      //     ) : (
      //       ''
      //     )}
      // {/* {(params.row.Amount === '0' || params.row.Amount === null) &&
      //   (params.row.PreAmount === '' || params.row.PreAmount === null) ? (
      //     ''
      //   ) : isAmountGreaterThanPrice ? (
      //     <Tooltip
      //       title={
      //         <span style={{ color: '#22af41' }}>
      //           ${' '}
      //           {DifferenceAmount.toFixed(2).toString() === '0'
      //             ? '0.00'
      //             : DifferenceAmount.toFixed(2).toString()}
      //         </span>
      //       }
      //     >
      //       <NorthIcon
      //         style={{
      //           fontSize: '15px',
      //           marginLeft: ' 2px',
      //           color: '#2BAB47',
      //           marginTop: '-3px',
      //           cursor: 'pointer'
      //         }}
      //       />
      //     </Tooltip>
      //   ) : isAmountLessThanPrice ? (
      //     <Tooltip
      //       title={
      //         <span style={{ color: '#ff5653' }}>
      //           ${' '}
      //           {DifferenceAmount.toFixed(2).toString() == '0'
      //             ? '0.00'
      //             : DifferenceAmount.toFixed(2).toString()}
      //         </span>
      //       }
      //     >
      //       <SouthIcon
      //         style={{
      //           fontSize: '15px',
      //           marginLeft: ' 2px',
      //           color: '#FF5653',
      //           marginTop: '-3px',
      //           cursor: 'pointer'
      //         }}
      //       />
      //     </Tooltip>
      //   ) : (
      //     ''
      //   )} */}
    },
    {
      field: 'PreCount',
      headerName: 'Pre Count',
      width: 180,
      flex: 1,
      align: 'left',
      renderCell: params => {
        return (
          <>
            {params?.row.Name != '' ? (
              <div>
                {params.row.PreCount == null ||
                isNaN(params.row.PreCount) ||
                params.row.PreCount == ''
                  ? '0'
                  : parseFloat(params.row.PreCount).toFixed(2)}
              </div>
            ) : (
              <div></div>
            )}
          </>
        )
      }
    },
    {
      field: 'Count',
      headerName: 'Count',
      width: 200,
      flex: 1,
      renderCell: params => {
        return (
          <>
            {params?.row.Name !== '' ? (
              <NumericFormat
                className='custom-input'
                style={{
                  textAlign: 'left',
                  borderWidth: '0.01px',
                  borderStyle: 'double',
                  width: '100%'
                }}
                value={
                  params.row.Count == null || isNaN(params.row.Count)
                    ? ''
                    : params.row.Count
                }
                onValueChange={e => CountValueUpdateonChange(e.value, params)}
                onBlur={e =>
                  params.row.Count != null ||
                  params.row.Count !== '' ||
                  params.row.Count !== '0'
                    ? CountUpdateOnFocusOut(e, params.row)
                    : ''
                }
                decimalScale={2}
                allowLeadingZeros={false}
                allowNegative={false}
                valueIsNumericString={true}
              />
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      field: 'Amount',
      headerName: 'Value',
      width: 200,
      flex: 1,
      align: 'left',
      renderCell: params => {
        return (
          <>
            {params?.row.Name != '' ? (
              <span style={{ fontWeight: '600' }}>
                $
                {params.row.Count == null ||
                params.row.Count == '' ||
                isNaN(params.row.Count)
                  ? '0.00'
                  : (
                      parseFloat(params.row.Price).toFixed(2) *
                      parseFloat(params.row.Count)
                    ).toFixed(2)}
              </span>
            ) : (
              ''
            )}
          </>
        )
      }
    }
  ]

  function handleClick (event) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
  }

  function getRowId (row) {
    return row.id
  }

  return (
    <>
      {/* <Header /> */}
      <div className='startinventory'>
        <div role='presentation' onClick={handleClick}>
          <Breadcrumbs aria-label='breadcrumb' className='breadcrumb'>
            <Link className='backinvent' href='/' onClick={() => navigate('/')}>
              Inventories
            </Link>
            <Typography className='currentsheet'>New Count Sheet</Typography>
          </Breadcrumbs>
        </div>
        <div className='levelone'>
          <p className='datesheet'>New Count Sheet</p>
          <span
            className='closedbutton'
            style={{ backgroundColor: '#336B97', borderRadius: '50px' }}
          >
            New
          </span>
        </div>
        <div className='flexsearchbut flexsearchbut1'>
          <div className='searchbar searchbar1'>
            <p className='datename'>Inventory Date</p>
            <DatePicker
              style={{ width: '250px' }}
              defaultValue={dayjs(Inventory_date)}
              value={inventoryDate}
              onChange={UpdateDateforInventory}
              format={dateFormat}
            />
          </div>
          <div className='newcountbuttondot newcountbuttondot2'>
              {/* <Button
                style={{
                  background: '#22AF41',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={showDrawer}
                icon={
                  <HistoryOutlined
                    style={{ fontSize: '15px', marginTop: '1px' }}
                  />
                }
              >
                History
              </Button> */}
              
              <Button
                style={{
                  background: '#fff',
                  border: '1px solid #E54643',
                  color: '#E54643',
                  display: 'flex',
                  alignItems: 'center'
                }}
                icon={<FaTrash style={{ fontSize: '12px' }} />}
                onClick={() => showModalForConfirmingDelete()}
              >
                Delete
              </Button>
            </div>
        </div>
        {progressBar && (
          <CircularProgress
            className='ExceptionalCircularProgress'
            color='success'
            disableShrink
            style={{ margin: '0 auto', display: 'block' }}
          />
        )}
        {nodata && (
          <div
            className=''
            style={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <img style={{ width: '5em' }} src='/no-item.png' />
              <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
              {/* <Typography style={{fontSize:'14px'}}>Setup your Master sheet to start taking the Inventory.</Typography> */}
            </div>
          </div>
        )}

        <div>
          {InventoryItems?.map((category, catIndex) => {
            return (
              <>
                {category.Items.length > 0 && (
                  <Accordion
                    className='mainCategory'
                    key={category.CatId}
                    expanded={
                      last.includes(category.CatId) 
                      || expanded === category.CatId
                    }
                    onChange={handleChange(category.CatId)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${category.CatId}d-content`}
                      id={`panel${category.CatId}d-header`}
                    >
                      <Typography className='accordiantitle'>
                        {category.CatName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {category.groupBy
                        ? Object?.entries(category?.groupBy)?.map(
                            ([categoryKey, categoryValues]) => {
                              return (
                                <>
                                  {categoryValues.length > 0 && (
                                    <Accordion
                                      className='subCategory'
                                      key={categoryKey}
                                      expanded={
                                       ( categoryValues?.some(item =>
                                          subvalue.includes(item.Name)
                                        ) || expandedsub === categoryKey)
                                      }
                                      onChange={handlesubChange(
                                        categoryKey,
                                        categoryValues
                                      )}
                                    >
                                      <AccordionSummary
                                        aria-controls={`panel${categoryKey}d-content`}
                                        id={`panel${categoryKey}d-header`}
                                      >
                                        <Typography className='accordiantitleone'>
                                          {categoryKey}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div
                                          style={{
                                            width: '100%',
                                            paddingLeft: '15px',
                                            paddingRight: '15px'
                                          }}
                                        >
                                          <DataGrid
                                            style={{
                                              borderBottom: '0px',
                                              borderRight: '0px',
                                              borderRadius: '0px'
                                            }}
                                            rows={
                                              Array.isArray(categoryValues)
                                                ? [...categoryValues].sort(
                                                    (a, b) => {
                                                      if (
                                                        a.catorderid &&
                                                        b.catorderid
                                                      ) {
                                                        return (
                                                          a.catorderid -
                                                          b.catorderid
                                                        )
                                                      } else {
                                                        return 0
                                                      }
                                                    }
                                                  )
                                                : []
                                            }
                                            columns={columns}
                                            getRowId={getRowId}
                                            hideFooterPagination
                                            hideFooter
                                            disableRowSelectionOnClick
                                            disableColumnMenu
                                            density='compact'
                                            getCellClassName={params => {
                                              if (searchedValue?.length > 0) {
                                                const cellText = params.value
                                                  ? params.value
                                                      .toString()
                                                      .toLowerCase()
                                                  : ''
                                                const searchQuery =
                                                  searchedValue.toLowerCase()
                                                const index =
                                                  category.Items.findIndex(
                                                    item =>
                                                      getRowId(item) ===
                                                      params.id
                                                  )

                                                if (
                                                  cellText.includes(
                                                    searchQuery
                                                  ) &&
                                                  index === highlightedIndex &&
                                                  catIndex ===
                                                    currentAccordionIndex
                                                ) {
                                                  return 'highlighted'
                                                }
                                              }
                                              return ''
                                            }}
                                          />
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                </>
                              )
                            }
                          )
                        : null}
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )
          })}
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          width: '100%',
          backgroundColor: '#fff'
        }}
      >
        {!isModalOpen && (
          <div className='onetwoline'>
            <div className='footercountsheet'>
              <div className='totalfoot'>
                <div>
                  <p className='footpara'>Total : ${total.toFixed(2)}</p>
                </div>
                <div>
                  <Search
                    className='search'
                    placeholder='Search'
                    onChange={e => onSearchItems(e.target.value)}
                    enterButton
                    allowClear={true}
                  />
                </div>
                <div className='cursorPointer' onClick={handlePreviousValue}>
                  <FaAngleLeft
                    style={{
                      color: '#22AF41',
                      marginTop: '-4px',
                      marginRight: '1px'
                    }}
                  />
                </div>
                <div className='cursorPointer' onClick={handleNextValue}>
                  <FaAngleRight
                    style={{
                      color: '#22AF41',
                      marginTop: '-4px',
                      marginLeft: '1px'
                    }}
                  />
                </div>
              </div>
              <div className='threebuttons'>
                <Button
                  style={{
                    border: '1px solid #DDD',
                    backgroundColor: '#F5F5F5',
                    color: '#212529'
                  }}
                  onClick={() => toast.success('Count sheet has been updated')}
                  disabled={nodata}
                >
                  Save
                </Button>
                <Button
                  style={{
                    border: '1px solid #2BAB47',
                    backgroundColor: '#2BAB47',
                    color: '#fff'
                  }}
                  onClick={() => setmodelopen(true)}
                  loading={load}
                  disabled={nodata}
                >
                  Save and Close
                </Button>
                <Button
                  style={{
                    border: '1px solid #DDD',
                    backgroundColor: '#F5F5F5',
                    color: '#212529'
                  }}
                  onClick={() => navigate('/')}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <Drawer
        title={
          <div>
            <p style={{ margin: '0' }}>
              {dayjs(Inventory_date).format('MM/DD/YYYY')}
            </p>
            <p style={{ margin: '0', color: '#19a73f', fontSize: '13px' }}>
              Opened
            </p>
          </div>
        }
        // title=""
        width={800}
        onClose={onClose}
        open={openDrawer}
        closeIcon={
          <KeyboardArrowRightOutlinedIcon style={{ color: '#19A73F' }} />
        }
      >
        <Timeline
          items={openCountSheetHistory?.map((historyItem, index) => {
            const dateObject = new Date(historyItem?.createdAt)
            const formattedDate = dateObject?.toLocaleString(undefined, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })

            return {
              children: (
                <div key={index}>
                  <p className='drawpara'>
                    {historyItem?.Action == 'Count sheet Date Updated'
                      ? historyItem?.Action +
                        ' from ' +
                        dayjs(historyItem?.CurrentDate).format('MM/DD/YYYY') +
                        ' to ' +
                        dayjs(historyItem?.updatedDate).format('MM/DD/YYYY')
                      : historyItem?.Action}
                    {' on '}
                    {formattedDate}
                    {' by '}
                    <span style={{ color: '#19a73f' }}>
                      {historyItem?.RestaurantName}
                    </span>
                  </p>
                  <p>
                    {historyItem?.Action == 'Count sheet Count Updated' ? (
                      <div className='logtablefont'>
                        <table
                          style={{ width: '100%', borderCollapse: 'collapse' }}
                        >
                          <thead>
                            <tr
                              style={{
                                backgroundColor: '#f2f2f2',
                                color: '#333'
                              }}
                            >
                              <th
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                ITEM
                              </th>
                              <th
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                PREVIOUS COUNT
                              </th>
                              <th
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                NEW COUNT
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ backgroundColor: '#fff' }}>
                              <td
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                {historyItem?.itemName}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                {historyItem?.PreviousCount == ''
                                  ? 0
                                  : historyItem?.PreviousCount}
                              </td>
                              <td
                                style={{
                                  padding: '8px',
                                  textAlign: 'center',
                                  borderBottom: '1px solid #ccc'
                                }}
                              >
                                {historyItem?.itemCount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              )
            }
          })}
        />
      </Drawer> */}
      <Modal
        title='Warning!'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
            onClick={handleOk}
          >
            Got It
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Cannot save two inventories on the same date. Please select a
          different date.
        </p>
      </Modal>
      <Modal
        title='Delete'
        open={isModalOpenone}
        onOk={handleOkone}
        onCancel={handleCancelone}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancelone}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={DeleteInventory}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Confirm deletion of the inventory taken on{' '}
          {Inventory_date
            ? Inventory_date
              ? convertDateFormat(Inventory_date)
              : ''
            : ''}
          ?
        </p>
      </Modal>
      <Modal
        title='Close Inventory'
        open={modalopen}
        onOk={handlesaveclose}
        onCancel={handlesaveclosecancel}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handlesaveclosecancel}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={SaveandCloseInventory}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>
          Confirm closing of the inventory taken on{' '}
          {Inventory_date
            ? Inventory_date
              ? convertDateFormat(Inventory_date)
              : ''
            : ''}
          ?
        </p>
      </Modal>
    </>
  )
}
export default CategoryWise
