import "./App.css";
import LogHistory from "./Inventory/LogHistory";
//import CountSheet from './Inventory/CountSheet'
import CountSheet from "./Inventory/Countsheets/CountSheet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClosedHistory from "./Inventory/ClosedHistory/ClosedHistory";
import IFrame from "./IFrame";
import Products from "./Inventory/Products";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <>
      <ToastContainer autoClose={1500} limit={0} />
      <Router>
        <Routes>
          <Route path="/" element={<LogHistory />} />
          <Route path="/countsheet/:Inventory_date" element={<CountSheet />} />
          <Route path="/history/:Date" element={<ClosedHistory />} />
          <Route path="/iframe" element={<IFrame />} />
          <Route path="/products" element={<Products />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
