import React, { useEffect, useMemo, useState, useRef } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import Typography from '@mui/material/Typography'
import 'bootstrap/dist/css/bootstrap.min.css'
import dayjs from 'dayjs'
import { NumericFormat } from 'react-number-format'
import {
  SearchOutlined,
  UploadOutlined,
  PrinterFilled
} from '@ant-design/icons'
import { FaTrash, FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { PlusCircleFilled } from '@ant-design/icons'
import {
  Input,
  Button,
  Modal,
  Tooltip,
  Dropdown,
  Select,
  InputNumber,
  Switch,
  Flex
} from 'antd'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import { StarFilled } from '@ant-design/icons'
import ExcelJS from 'exceljs'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { FaEllipsisV } from 'react-icons/fa'
import { mainAxios } from '../middleware/interceptor'
import { Logger } from '../middleware/Logger'
import { toast } from 'react-toastify'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Print from './Print'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { CircularProgress } from '@mui/material'
import { CircleOutlined } from '@mui/icons-material'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#19A73F'
    }
  }
})

const statusText = [
  [
    'Confirm activation of this item? Activated items will be shown on the count sheets. ',
    'Activate Item'
  ],
  [
    " Confirm deactivation of the item? Deactivated items won't be shown on the count sheets.",
    'Deactivate Item'
  ]
]
const initialPopupData = {
  itemName: '',
  category: '',
  unit: '',
  unitPrice: '',
  id: '',
  Multiple_Unit: '',
  subCategory: '',
  IsInMaster: false
}
const dropDownValue = [
  { value: 'Bottle', label: 'Bottle' },
  { value: 'Can', label: 'Can' },
  { value: 'Case', label: 'Case' },
  { value: 'Each', label: 'Each' },
  { value: 'Gallon', label: 'Gallon' },
  { value: 'Keg (1/2 BBL)', label: 'Keg (1/2 BBL)' },
  { value: 'Keg (1/4 BBL)', label: 'Keg (1/4 BBL)' },
  { value: 'Keg (1/6 BBL)', label: 'Keg (1/6 BBL)' },
  { value: 'Kilogram', label: 'Kilogram' },
  { value: 'Loaf', label: 'Loaf' },
  { value: 'Ounce', label: 'Ounce' },
  { value: 'Pound', label: 'Pound' },
  { value: 'Quart', label: 'Quart' }
]

const sampleSubCategory = [
  { label: 'Option1', value: 'option1' },
  { label: 'Option2', value: 'option2' },
  { label: 'Option3', value: 'option3' }
]

const Products = () => {
  const UserClaims = localStorage.getItem('UserClaims')

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTableView(true)
  //   }, 300)
  // }, [])

  const [data, setData] = useState([])
  const [counter, setCounter] = useState(0)
  const [dynamoData, setDynamoData] = useState([])
  const [progressBar, setProgressBar] = useState(false)
  const [nodata, setNodata] = useState(false)
  const [editOrCreate, setEditOrCreate] = useState('Create New Item')
  const [categoryDropdown, setCategoryDropDown] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [newSubCategoryName, setNewSubCategoryName] = useState('')
  const [rowSelection, setRowSelection] = useState({})
  const [RestID, setRestId] = useState(localStorage.getItem('RestId'))
  const [Budget_Exist_Status, setBudget_Exist_Status] = useState(
    JSON.parse(UserClaims).Budget_Exist_Status
  )
  const [isTableview, setTableView] = useState(false)
  const [IsModalOpentwo, setIsModalOpentwo] = useState(false)
  const [IsModalOpenbudget, setIsModalOpenbudget] = useState(false)
  const [popupData, setPopupData] = useState(initialPopupData)
  const [backupPopupData, setBackupPopupData] = useState(initialPopupData)
  const [subCategoryRawData, setSubCategoryRawData] = useState([])
  const [rowFieldErrors, setRowFieldErrors] = useState({})
  const [details, setDetails] = useState({})
  const [catName, setCatName] = useState()
  const [CatId, setCatId] = useState()
  const [itemid, setItemId] = useState()
  const [changeOfAction, setChangeOfAction] = useState(true)
  const [statusItemData, setItemStatusData] = useState({
    restid: '',
    catId: '',
    catName: '',
    itemId: '',
    status: ''
  })
  const [backupsaveData, setBackupsaveData] = useState([])
  const [backupData, setBackupData] = useState([])
  const [UnitDropDown, setUnitDropDown] = useState(dropDownValue)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [fieldErrors, setFieldErrors] = useState({})

  const [addMasterSheetLoader, setAddMasterSheetLoader] = useState(false)

  const [stsText, setStsText] = useState('')
  const [isError, setIsError] = useState(false)
  const [isItemExist, setIsItemExist] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [searchedValue, setSearchedValue] = useState('')
  const [idForAction, setIdForAction] = useState()
  const [allowrow, setallowrow] = useState(false)

  const printRef = useRef()

  const PoupuponChange = (key, value) => {
    if (key === 'category') {
      if (subCategoryRawData.length > 0) {
        const subcat = subCategoryRawData.filter(
          u =>
            u.CatId.toString() === value.split('~')[1].toString() &&
            u.CatName.toString().trim() ===
              value.split('~')[0].toString().trim()
        )

        if (subcat.length > 0) {
          const cat = subcat[0].SubCategories.map(x => ({
            label: x.SubCatName,
            value: x.SubCatName
          }))
          setSubCategories(cat)
          popupData.subCategory = ''
        } else {
          setSubCategories([])
        }
      } else {
        setSubCategories([])
      }
    }
    if (value !== null) {
      let temp = { ...popupData }
      temp[key] = value
      setPopupData(temp)

      const initial = data1.map((item, index) => {
        if (index == 0 && key == 'unit') {
          return { ...item, Name: value }
        } else if (index == 0 && key == 'unitPrice') {
          return { ...item, Price: value }
        } else {
          return item
        }
      })

      setData1(initial)
    }

    if (key === 'unit') {
      setUnitDropDown(dropDownValue.filter(option => option.value !== value))
    }
  }

  const formatUnitPrice = value => {
    return value !== null && !isNaN(+value)
      ? setPopupData({ ...popupData, unitPrice: parseFloat(value).toFixed(2) })
      : setPopupData({ ...popupData, unitPrice: parseFloat(0).toFixed(2) })
  }

  const formatMultipleUnitPrice = (value, index) => {
    const newData1 = data1.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          Price:
            value !== null && !isNaN(+value)
              ? parseFloat(value).toFixed(2)
              : parseFloat(0).toFixed(2)
        }
      } else {
        return item
      }
    })

    setData1(newData1)
  }

  const handleKeyDown = e => {
    // Allow only numbers (0-9) and allow 'Backspace', 'Delete', '.' and arrow keys
    if (
      e.key.match(/[^0-9]/) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowUp' &&
      e.key !== 'ArrowDown' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== '.'
    ) {
      e.preventDefault()
    }
  }

  const editFunction = () => {
    var pattern = /[^a-zA-Z0-9\s]/g
    const isItemNameExists = backupData.filter(
      item =>
        item?.category?.toLocaleLowerCase() ===
          popupData?.category?.split('~')[0]?.toLocaleLowerCase() &&
        item?.item?.toLocaleLowerCase()?.trim() ===
          popupData?.itemName?.toLocaleLowerCase()?.replace(pattern, '').trim()
    )
    const isValid = isItemNameExists.filter(x => x.id !== popupData.id).length

    const multi = data1.filter((item, index) => index !== 0)
    if (!isValid) {
      setIsError(false)
      setIsItemExist(false)
      // setIsClicked(true)
      const payData = {
        Quantity: data1[0].Quantity,
        restid: RestID,
        catId: popupData.category.split('~')[1],
        catName: popupData.category.split('~')[0],
        itemName: popupData.itemName.toString().trim(),
        itemUnit: popupData.unit ? popupData.unit : null,
        amount: parseFloat(popupData?.unitPrice).toFixed(2),
        itemId: popupData.id,
        Multiple_Unit: multi,
        subCatName: popupData.subCategory,
        status: popupData?.IsInMaster === true ? '1' : '0'
      }

      mainAxios
        .post('/api/Inventory/updateBaseItem', payData)
        .then(res => {
          if (res.data?.status.toString() === '1') {
            getSubCategories()
            toast.success('Item Updated Successfully.')
            getData()
            let Details = {
              oldItem: backupPopupData,
              EditedItemId: popupData.id.toString().trim(),
              EditedItem: popupData
            }
            Logger(RestID, 'Product Edited', Details, '')
          } else {
            toast.error('Failed to Update Item.')
          }
          setIsModalOpen(false)
          setIsClicked(false)
        })
        .catch(() => {
          // toast.error('Failed to Update Item.')
          setIsModalOpen(false)
          setIsClicked(false)
        })
    } else {
      setIsItemExist(true)
      setIsError(true)
    }
  }
  const handleCancelprod = () => {
    setIsModalOpentwo(false)
  }
  const handleOkprod = () => {
    setIsModalOpentwo(false)
  }
  const createFunction = () => {
    var pattern = /[^a-zA-Z0-9\s]+|\s+/g
    const multi = data1.filter((item, index) => index !== 0)
    const checkAllFields =
      popupData?.itemName.toString().trim()?.length > 0 &&
      popupData?.unit?.length > 0 &&
      popupData?.unitPrice?.toLocaleString()?.length > 0 &&
      popupData?.category?.length > 0
    let isItemNameExists
    if (popupData.subCategory) {
      isItemNameExists = backupData.filter(
        item =>
          item?.category?.toLocaleLowerCase() ===
            popupData?.category?.split('~')[0]?.toLocaleLowerCase() &&
          item?.subCategory?.toLocaleLowerCase() ===
            popupData?.subCategory?.toLocaleLowerCase() &&
          item?.item?.toLocaleLowerCase()?.replace(pattern, '').trim() ===
            popupData?.itemName?.toLocaleLowerCase().replace(pattern, '').trim()
      )?.length
    } else {
      isItemNameExists = backupData.filter(
        item =>
          item?.category?.toLocaleLowerCase() ===
            popupData?.category?.split('~')[0]?.toLocaleLowerCase() &&
          item?.item?.toLocaleLowerCase()?.replace(pattern, '').trim() ===
            popupData?.itemName?.toLocaleLowerCase().replace(pattern, '').trim()
      )?.length
    }

    if (checkAllFields) {
      if (isItemNameExists === 0) {
        setIsError(false)
        setIsItemExist(false)
        // setIsClicked(true)
        const payData = {
          restid: RestID,
          catId: popupData.category.split('~')[1],
          catName: popupData.category.split('~')[0],
          itemName: popupData.itemName,
          itemUnit: popupData.unit,
          amount: +popupData.unitPrice,
          Multiple_Unit: multi,
          subCatName: popupData.subCategory ? popupData?.subCategory : '',
          status: popupData?.IsInMaster === true ? '1' : '0'
        }
        mainAxios
          .post('/api/Inventory/addBaseItem', payData)
          .then(res => {
            if (res.data?.status.toString() === '1') {
              toast.success('Item Successfully Added to Master Sheet.')
              getSubCategories()
              let Details = {
                CreatedItem: popupData.itemName.toString().trim(),
                Payload: payData
              }
              Logger(RestID, 'Product Created', Details, '')
              getData()
              setIsModalOpen(false)
              setallowrow(false)
            } else {
              toast.error('Failed to Create Item.')
            }
            setIsClicked(false)
            setIsModalOpen(false)
          })
          .catch(() => {
            toast.error('Failed to Create Item.')
            setIsModalOpen(false)
            setIsClicked(false)
          })
      } else {
        setIsItemExist(true)
        setIsError(true)
      }
    } else {
      setIsError(true)
    }
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  const handleExcelDownload = () => {
    console.log('hihihi')
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Products')

    const headerRow = worksheet.addRow([
      'Category',
      'Item',
      'Unit',
      'Unit Price',
      'Status',
      'ProductId',
      'id'
    ])
    console.log(data, 'hiihihih')
    headerRow.font = { bold: true }
    data.forEach(item => {
      worksheet.addRow([
        item.category,
        item.item,
        item.unit,
        item.unitPrice,
        item.Status == 1 ? 'Active' : 'DeActive',
        item.ProductId ? item.ProductId : '',
        item.id
      ])
    })

    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Products.xlsx'
      link.click()
    })
  }

  const items = [
    {
      key: '1',
      label: (
        <span
          className='dotoption'
          onClick={() => {
            if (Budget_Exist_Status == 0) {
              setIsModalOpenbudget(true)
            } else {
              handleExcelDownload()
            }
          }}
        >
          <UploadOutlined
            style={{ fontSize: '15px', color: '#22AF41', marginRight: '15px' }}
          />{' '}
          Export
        </span>
      )
    },

    {
      key: '2',
      label: (
        <span
          className='dotoption'
          onClick={
            Budget_Exist_Status == 0
              ? () => setIsModalOpenbudget(true)
              : () => printRef.current.handlePrint()
          }
        >
          <PrinterFilled
            style={{ fontSize: '15px', color: '#22AF41', marginRight: '15px' }}
          />{' '}
          Print
        </span>
      )
    }
  ]

  //should be memoized or stable
  const [isModalOpencategory, setIsModalOpenCategory] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpendelete, setIsModalDelete] = useState(false)
  const [isModalOpenstatus, setIsModalOpenStatus] = useState(false)
  const [deleteItemData, setDeleteItemData] = useState({
    restid: '',
    catId: '',
    catName: '',
    itemId: ''
  })

  const showCategorypopup = () => {
    setIsModalOpenCategory(true)
  }
  const handleOkcategory = () => {
    setIsModalOpenCategory(false)
  }
  const handleCancelcategory = () => {
    setIsModalOpenCategory(false)
  }

  const showmodalstatus = (row, e) => {
    setItemStatusData({
      restid: RestID,
      catId: row.catId,
      catName: row.category,
      itemId: row.id,
      status: e ? 1 : 0,
      itemName: row.item
    })
    setStsText(statusText[parseInt(row.Status)])

    setIsModalOpenStatus(true)
  }

  const handleOkstatus = () => {
    mainAxios
      .post('/api/Inventory/updateItemStatus', statusItemData)
      .then(res => {
        if (res.data?.status.toString() === '1') {
          if (statusItemData.status == 1) {
            toast.success('Item Added to Master Countsheet successfully.')
          } else {
            toast.success('Item Removed from Master Countsheet successfully.')
          }

          let Details = {
            ItemId: statusItemData.itemId.toString(),
            Payload: statusItemData
          }
          Logger(RestID, 'Product Status Changed', Details, '')

          setData(pre =>
            pre.map(y => {
              if (y.id.toString() === statusItemData.itemId.toString()) {
                return { ...y, Status: statusItemData['status'] }
              } else {
                return { ...y }
              }
            })
          )
          // getData()
          setItemStatusData({
            restid: '',
            catId: '',
            catName: '',
            itemId: '',
            status: ''
          })
        } else {
          toast.error('Status Changes Failed')
          setItemStatusData({
            restid: '',
            catId: '',
            catName: '',
            itemId: '',
            status: ''
          })
        }
      })
      .catch(err => {
        setItemStatusData({
          restid: '',
          catId: '',
          catName: '',
          itemId: '',
          status: ''
        })
        if (statusItemData.status == 1) {
          toast.error('Failed to Add Item to Master Countsheet.')
        } else {
          toast.error('Failed to Remove Item from Master Countsheet.')
        }
      })
    setIsModalOpenStatus(false)
  }

  const handleCancelstatus = () => {
    setIsModalOpenStatus(false)
    setItemStatusData({
      restid: '',
      catId: '',
      catName: '',
      itemId: '',
      status: ''
    })
  }

  const showmodaldelete = row => {
    setDeleteItemData({
      restid: RestID,
      catId: row.catId,
      catName: row.category,
      itemId: row.id,
      itemName: row.item
    })
    setIsModalDelete(true)
  }

  const handleOkprodbudget = () => {
    setIsModalOpenbudget(false)
  }

  const handleOkdelete = () => {
    mainAxios
      .post('/api/Inventory/deleteBaseItem', deleteItemData)
      .then(res => {
        if (res.data?.status?.toString() === '1') {
          toast.success('Item deleted successfully')
          let Details = {
            ItemId: deleteItemData.itemId.toString(),
            Payload: deleteItemData
          }
          Logger(RestID, 'Product Deleted', Details, '')
          setDeleteItemData({
            restid: '',
            catId: '',
            catName: '',
            itemId: ''
          })
          getData()
          delete rowSelection[
            `${deleteItemData.itemId}~${deleteItemData.catName}~${deleteItemData.catId}`
          ]
          setIsModalDelete(false)
        } else {
          setDeleteItemData({
            restid: '',
            catId: '',
            catName: '',
            itemId: ''
          })
          toast.warn('item delete failed')
          setIsModalDelete(false)
        }
      })
      .catch(err => {
        setDeleteItemData({
          restid: '',
          catId: '',
          catName: '',
          itemId: ''
        })
        console.log(err)
        setIsModalDelete(false)
      })
  }

  const handleCanceldelete = () => {
    setDeleteItemData({
      restid: '',
      catId: '',
      catName: '',
      itemId: ''
    })
    setIsModalDelete(false)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    if (editOrCreate === 'Create New Item') {
      createFunction()
    } else if (editOrCreate === 'Edit Item') {
      editFunction()
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsError(false)
    setIsItemExist(false)
    setallowrow(false)
    setUnitDropDown(dropDownValue)
    setBackupsaveData([])
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'category', //access nested data with dot notation
        header: 'Category',
        size: 130,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'subCategory', //access nested data with dot notation
        header: 'Sub Category',
        size: 160,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        accessorKey: 'item',
        header: 'Item',
        size: 333,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          if (row.original.CreatedAt !== '') {
            const createddate = dayjs(row.original.CreatedAt)

            const nextmonth = createddate.add(31, 'day')

            if (dayjs().isBefore(nextmonth)) {
              return (
                <>
                  <span>{row.original.item}</span> &nbsp; &nbsp;
                  <StarFilled style={{ color: '#ffd700' }} />
                </>
              )
            } else {
              return (
                <>
                  <span>{row.original.item}</span>
                </>
              )
            }
          } else {
            return (
              <>
                <span>{row.original.item}</span>
              </>
            )
          }
        }
      },
      {
        accessorKey: 'unit', //normal accessorKey
        header: 'Unit',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.category != '' ? (
                <span>{row?.original.unit}</span>
              ) : (
                <span>
                  {row?.original.multiName}({row.original.multiQnty}&nbsp;
                  {row.original.unit})
                </span>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'unitPrice',
        header: 'Unit Price',
        size: 130,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'right'
        },
        sortDescFirst: true,
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.unitPrice != '' ? (
                currencyFormatter.format(row?.original.unitPrice)
              ) : (
                <div></div>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'Status',
        header: 'MasterSheet',
        size: 140,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        enableSorting: false,
        filterVariant: 'multi-select',
        filterSelectOptions: [
          { label: 'Active', value: 1 },
          { label: 'De-Active', value: 0 }
        ],
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.category != '' ? (
                <Switch
                  checked={
                    row.original.Status?.toString() === '1' ? true : false
                  }
                  onClick={e => showmodalstatus(row.original, e)}
                  size='small'
                />
              ) : (
                <div></div>
              )}
            </>
          )
        }
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 120,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'center'
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              {row?.original.category != '' ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: 'center'
                  }}
                >
                  <Tooltip title='Edit'>
                    <EditIcon
                      onClick={() => {
                        editItem(row.original)
                      }}
                      style={{
                        color: '#6A8AB9',
                        cursor: 'pointer',
                        fontSize: '20px'
                      }}
                    />
                  </Tooltip>

                  <Tooltip title='Delete'>
                    <FaTrash
                      onClick={() => showmodaldelete(row.original)}
                      style={{ color: '#F86764', cursor: 'pointer' }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div></div>
              )}
            </>
          )
        }
      }
    ],
    [subCategoryRawData, rowSelection]
  )
  const { Option } = Select
  const [data1, setData1] = useState([{}])
  const columns1 = useMemo(
    () => [
      {
        accessorKey: 'UnitName',
        header: 'Name',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status1 == 'label' ? (
                <span>{row.original.UnitName}</span>
              ) : (
                <Input
                  required
                  value={row.original.UnitName}
                  style={{ textAlign: 'left' }}
                  onChange={event =>
                    handleRowChange(row.index, 'UnitName', event.target.value)
                  }
                />
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'Quantity',
        header: 'Qty',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row, index }) => {
          return (
            <>
              {row.original.status1 == 'label' ? (
                <span>{row.original.Quantity}</span>
              ) : (
                <>
                  <Input
                    style={{ textAlign: 'left' }}
                    value={row.original.Quantity}
                    onChange={event =>
                      handleRowChange(row.index, 'Quantity', event.target.value)
                    }
                  />
                  {rowFieldErrors[row.index]?.Quantity && (
                    <div style={{ color: 'red' }}>
                      {rowFieldErrors[row.index]?.Quantity}
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'Name',
        header: 'Unit',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status1 == 'label' ? (
                <span>{row.original.Name}</span>
              ) : (
                <>
                  <Select
                    className='ant-select'
                    value={row.original.Name}
                    placeholder='Select'
                    onChange={value =>
                      handleRowChange(row.index, 'Name', value)
                    }
                    style={{ textAlign: 'left', minWidth: '80px' }}
                    dropdownStyle={{ zIndex: 9999 }}
                    options={UnitDropDown}
                    disabled={row.index == 0}
                  >
                    {/* 
            {unitOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))} */}
                  </Select>

                  {rowFieldErrors[row.index]?.Name && (
                    <div style={{ color: 'red' }}>
                      {rowFieldErrors[row.index]?.Name}
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
      },
      {
        accessorKey: 'Price',
        header: 'Price',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status1 == 'label' ? (
                <span>{row.original.Price}</span>
              ) : (
                <>
                  <Input
                    style={{ textAlign: 'left' }}
                    value={row?.original?.Price?.toString().replace('$', '')}
                    disabled={row.index == 0}
                    onChange={event =>
                      handleRowChange(row.index, 'Price', event.target.value)
                    }
                    onBlur={e => {
                      e.target.value?.length === 0 &&
                      row.original.Price?.toString().replace('$', '')
                        ?.length === 0
                        ? formatMultipleUnitPrice('0', row.index)
                        : formatMultipleUnitPrice(
                            row?.original?.Price?.toString().replace('$', ''),
                            row.index
                          )
                    }}
                  />
                  {rowFieldErrors[row.index]?.Price && (
                    <div style={{ color: 'red' }}>
                      {rowFieldErrors[row.index]?.Price}
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
      },

      {
        // accessorKey: 'action',
        header: 'Action',
        size: 100,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status1 == 'label' ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: 'left'
                  }}
                >
                  <EditIcon
                    style={{
                      color: '#6A8AB9',
                      cursor: 'pointer',
                      fontSize: '20px'
                    }}
                    onClick={() => editIcon(row.index, row.original)}
                  />

                  <DeleteOutlineOutlinedIcon
                    onClick={() => Delete(row.original, row.index)}
                    style={{
                      color: '#F86764',
                      cursor: 'pointer',
                      fontSize: '20px'
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: 'left'
                  }}
                >
                  {row.id == 0 ? (
                    ''
                  ) : (
                    <CheckCircleIcon
                      style={{
                        color: '#6A8AB9',
                        cursor: 'pointer',
                        fontSize: '20px'
                      }}
                      onClick={event =>
                        saveMultcheckDataValueipleUnits(row.index, row.original)
                      }
                    />
                  )}

                  {row.id == 0 ? (
                    ''
                  ) : (
                    <CancelIcon
                      style={{
                        color: '#F86764',
                        cursor: 'pointer',
                        fontSize: '20px'
                      }}
                      disabled={(row.original.id = 1)}
                      onClick={() => cancelIcon(row.index)}
                    />
                  )}
                </div>
              )}
            </>
          )
        }
      }
    ],
    [data1, changeOfAction, UnitDropDown, rowFieldErrors]
  )
  const addnewRow = () => {
    setChangeOfAction(true)
    setallowrow(true)

    //setCounter((prevCounter) => prevCounter + 1);
    setData1(prevData => [
      ...prevData,
      {
        id1: counter, // Adding a UUID to the object
        Name: '',
        Price: '',
        Quantity: '',
        status1: 'edit',
        status2: ''
      }
    ])
    //alert(changeOfAction)
    setCounter(prevCounter => prevCounter + 1)
  }

  const cancelIcon = e => {
    setChangeOfAction(false)

    const needata = data1.map((item, index) => {
      if (index == e && item.status2 == '') {
        return { ...item, Name: '', Price: '', Quantity: '', status1: 'label' }
      } else if (index == e && item.status2 == 'saved') {
        const final = backupsaveData.filter(item => item.index == e)

        return { ...final[0], status1: 'label' }
      } else {
        return item
      }
    })

    setData1(needata)
    // alert(changeOfAction)
  }
  const getSubCategories = () => {
    mainAxios
      .get('api/Inventory/GetSubCategories/' + RestID)
      .then(res => {
        setSubCategoryRawData(res.data.SubCategoryArray)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const editIcon = (e, row) => {
    const needata = data1.map((item, index) => {
      if (index == e) {
        return { ...item, status1: 'edit', index: index }
      } else {
        return item
      }
    })
    const back = needata[e]

    const result = backupsaveData?.filter(item => item.index == e)
    if (result?.length > 0) {
      setBackupsaveData(prev => {
        const newArray = [...prev]
        newArray.map((item, index) => {
          if (item.index === e) {
            newArray[index] = {
              ...item,
              Name: row.Name,
              Price: row.Price,
              Quantity: row.Quantity,
              index: row.index,
              status1: row.status1,
              status2: row.status2
            }
          }
          return item // return the item for other elements in the array
        })
        return newArray // return the updated array
      })
    } else {
      setBackupsaveData(prev => [...prev, back])
    }
    setData1(needata)
    setChangeOfAction(true)
    setIdForAction(e)
    //alert(changeOfAction)
  }

  const Delete = (e, index) => {
    const payData = {
      restid: parseInt(RestID),
      catId: CatId,
      catName: catName,
      itemId: itemid,
      UnitId: e.UnitId
    }

    mainAxios
      .post('/api/Inventory/deleteUnitFromMultiUnits', payData)
      .then(res => {
        if (res.data.status === '1') {
          getData()
          toast.success(res.data.message)
        }

        // Add the respective option to the UnitDropDown array
        setUnitDropDown(prevOptions => [
          ...prevOptions,
          { value: e.Name, label: e.Name }
        ])
        setData1(prevData => {
          const temp = [...prevData]
          temp?.splice(index, 1)
          return temp
        })
      })
      .catch(() => {})
  }

  const handleRowChange = (t, event, value) => {
    if (event === 'Name') {
      setUnitDropDown(prevOptions =>
        prevOptions.filter(option => option.value !== value)
      )
    }
    setData1(prevData => {
      return prevData.map((row, index) => {
        if (index === t) {
          let updatedRow = { ...row, [event]: value }

          // Check if the event is "Quantity" and update Price accordingly
          if (event === 'Quantity') {
            updatedRow.Price = value * popupData.unitPrice
          }

          return updatedRow
        } else {
          return row
        }
      })
    })
  }

  const saveMultcheckDataValueipleUnits = (id, dat) => {
    const errors = {}
    data1.forEach((item, index) => {
      if (index === id) {
        if (!item.Quantity) {
          errors.Quantity = 'Quantity is required'
        }

        if (!item.Name) {
          errors.Name = 'Unit is required'
        }

        if (!item.Price) {
          errors.Price = 'Price is required'
        }
      }
    })

    // Set the row field errors state
    setRowFieldErrors({ ...rowFieldErrors, [id]: errors })

    // Check if there are any errors
    const hasErrors = Object.keys(errors).length > 0

    // If there are errors, return early
    if (hasErrors) {
      return
    }

    // Clear previous row field errors
    setRowFieldErrors({})
    const isAllFieldsFilled = data1.every(
      (item, index) =>
        index === id || (item.Quantity && item.Name && item.Price)
    )

    // Set status1 based on whether all fields are filled
    const needata = data1.map((item, index) => {
      if (index === id) {
        return {
          ...item,
          status1: !hasErrors ? 'label' : 'edit',
          status2: 'saved'
        }
      } else {
        return item
      }
    })

    popupData.Multiple_Unit = needata
    setData1(needata)
    const payData = {
      restid: parseInt(RestID),
      catId: CatId,
      catName: catName,
      itemId: itemid,
      unit_details: data1
    }
    //   mainAxios
    //   .post('/api/Inventory/addNewUnitToMultipleUnits', payData)
    //   .then(res => {
    //  console.log(res,'res')
    //     if(res.data.status==1||2){
    //       const needata=data1.map((item)=>{
    //         if(item.id==id){
    //           alert('hi')
    //         return {...item,status1:'label'}
    //         }
    //         else{
    //           alert('bye')
    //           return item
    //         }
    //        })
    //        setData1(needata)
    //   }
    //   })
    //   .catch(() => {

    //   })
  }
  const editfunction = () => {
    const payData = {
      restid: RestID,
      catId: details.catId,
      catName: details.catName,
      itemId: details.id,
      unit_details: data1
    }
    mainAxios
      .post('/api/Inventory/addNewUnitToMultipleUnits', payData)
      .then(res => {})
      .catch(() => {})
  }

  const handleCheckboxOnChange = e => {
    let temp = { ...popupData }
    temp['IsInMaster'] = e.target.checked
    setPopupData(temp)
  }
  const editItem = e => {
    let temp = { ...popupData }
    temp['itemName'] = e.item
    temp['category'] = `${e.category}~${e.catId}`
    temp['unit'] = e.unit
    temp['unitPrice'] = e.unitPrice.toString().replace('$', '')
    temp['id'] = e.id
    temp['Multiple_Unit'] = e?.Multiple_Unit
    temp['subCategory'] = e?.subCategory
    temp['IsInMaster'] = e?.Status == '1' ? true : false

    if (subCategoryRawData?.length > 0) {
      const subcat = subCategoryRawData.filter(
        u =>
          u.CatId.toString() === e.catId.toString() &&
          u.CatName.toString().trim() === e.category.toString().trim()
      )

      if (subcat.length > 0) {
        const cat = subcat[0].SubCategories.map(x => ({
          label: x.SubCatName,
          value: x.SubCatName
        }))
        setSubCategories(cat)
      } else {
        setSubCategories([])
      }
    }

    setUnitDropDown(prevOptions =>
      prevOptions.filter(option => {
        // Check if option.value is not equal to e.unit
        // and is not present in any of the Multiple_Unit names
        return (
          option.value !== e.unit &&
          !e.Multiple_Unit.some(
            multipleUnit => multipleUnit.Name === option.value
          )
        )
      })
    )

    const Multiple_Unit = e?.Multiple_Unit?.map(item => {
      return { ...item, status1: 'label', status2: 'saved' }
    })
    Multiple_Unit.unshift({
      Name: e.unit,
      Price: e.unitPrice.toString().replace('$', ''),
      Quantity: '',
      index: '',
      status1: '',
      status2: ''
    })

    setData1(Multiple_Unit)
    setPopupData(temp)
    setallowrow(true)
    setBackupPopupData(temp)
    setEditOrCreate('Edit Item')
    showModal(true)
    setCatName(e.category)
    setCatId(e.catId)
    setItemId(e.id)
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: true,
    enableColumnResizing: true,
    enableRowSelection: row =>
      row.original.category !== '' ? row.original.Status == '0' : false,
    getRowId: row => {
      return `${row?.id}~${row?.category}~${row?.catId}`
    },
    enablePagination: true,
    enableColumnFilter: false,
    searchIconVisible: false,
    enableColumnPinning: false,
    autoResetPageIndex: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enablePinning: false,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 50, 100]
    },
    initialState: { density: 'compact', pagination: { pageSize: 10 } },
    onRowSelectionChange: setRowSelection,
    layoutMode: 'semantic',
    state: {
      isLoading,
      rowSelection
    }
  })

  const table1 = useMaterialReactTable({
    columns: columns1,
    data: data1,
    enableTopToolbar: false,
    enableColumnResizing: false,
    enablePagination: false,
    enableColumnFilter: false,
    enableColumnFilterModes: false,
    enableColumnMenu: false,
    initialState: { density: 'compact' },
    layoutMode: 'semantic'
  })
  useEffect(() => {
    if (Budget_Exist_Status == 1) {
      getData()
      getSubCategories()
      getdropvalue()
    } else {
      setIsModalOpenbudget(true)
    }
  }, [])

  const getData = () => {
    setProgressBar(true)
    setTableView(true)
    mainAxios
      .get('/api/Inventory/null/' + RestID)
      .then(res => {
        setIsLoading(false)
        setDynamoData(res.data)
        setProgressBar(false)
        res.data.Data.InventoryDetails.length == 0 && setIsModalOpentwo(true)
      })
      .catch(err => {
        setIsLoading(false)
        setDynamoData([])
        setProgressBar(false)
        setNodata(true)
        console.log(err)
      })
  }
  const getdropvalue = () => {
    mainAxios.get('api/Inventory/Getcategories/' + RestID).then(res => {
      const temp = res.data.Categorydetails
      const newCategoryDropDown = temp.map(item => ({
        label: item.Cat_name,
        value: `${item.Cat_name}~${item.Cat_id}`
      }))
      setCategoryDropDown(newCategoryDropDown)
    })
  }
  const handleSearch = e => {
    setSearchedValue(e)
    if (Budget_Exist_Status == 0) {
      setIsModalOpenbudget(true)
    } else {
      if (e !== '' && e !== undefined && e !== null) {
        const filteredData = backupData.filter(x =>
          x.search?.toLocaleLowerCase()?.includes(e?.toLocaleLowerCase())
        )
        setData(filteredData)
      } else {
        setData(backupData)
      }
    }
  }

  const handleMultipleCheckBox = () => {
    const arr = []
    setAddMasterSheetLoader(true)
    for (const key in rowSelection) {
      if (rowSelection.hasOwnProperty(key)) {
        if (rowSelection[key]) {
          let d = key.split('~')
          let json = {
            restid: RestID,
            catId: d[2],
            catName: d[1],
            itemId: d[0],
            status: 1
          }
          arr.push(json)
        }
      }
    }
    console.log(arr)
    mainAxios
      .post('api/Inventory/AddToMasterCountsheet', arr)
      .then(e => {
        setAddMasterSheetLoader(false)
        setRowSelection({})
        getData()
        if (arr.length == 1) {
          toast.success('Selected Item Added to Master Countsheet')
        } else {
          toast.success('Selected Items Added to Master Countsheet')
        }
      })
      .catch(e => {
        setAddMasterSheetLoader(false)
        console.log(e)
        toast.error('Error while adding selected items to masterCountsheet')
      })
  }

  useEffect(() => {
    if (dynamoData?.Data) {
      if (
        dynamoData.Data?.InventoryDetails &&
        dynamoData.Data?.InventoryDetails?.length > 0
      ) {
        const temp = []
        const da = dynamoData?.Data?.InventoryDetails.map(cat => {
          temp.push({
            label: cat.CatName,
            value: `${cat.CatName}~${cat.CatId}`
          })
          return cat.Items.map(item => {
            if (item?.Multiple_Unit && item?.Multiple_Unit?.length > 0) {
              let temp = item?.Multiple_Unit?.map(mUnit => {
                return {
                  category: '',
                  subCategory: item?.SubCatName,
                  catId: cat.CatId,
                  item: '',
                  unitPrice: mUnit.Price,
                  unit: mUnit.Name,
                  multiQnty: mUnit.Quantity,
                  multiPrice: mUnit.price,
                  multiName: mUnit.UnitName,
                  id: item.id,
                  Status: item.Status.toString(),
                  CreatedAt: '',
                  Multiple_Unit: [],
                  search: item.Name,
                  ProductId: item?.ProductID
                }
              })
              let json = {
                category: cat.CatName,
                subCategory: item?.SubCatName,
                catId: cat.CatId,
                item: item.Name,
                unitPrice: item.Price,
                unit: item.Unit,
                id: item.id,
                Status: item.Status.toString(),
                CreatedAt: item.CreatedAt
                  ? dayjs(item.CreatedAt).format('MM/DD/YYYY')
                  : '',
                Multiple_Unit: item.Multiple_Unit ? item.Multiple_Unit : [],
                search: item.Name,
                ProductId: item?.ProductID
              }
              // temp.push(json)
              return [json, ...temp]
            } else {
              return {
                category: cat.CatName,
                subCategory: item?.SubCatName,
                catId: cat.CatId,
                item: item.Name,
                unitPrice: item.Price,
                unit: item.Unit,
                id: item.id,
                Status: item.Status.toString(),
                CreatedAt: item.CreatedAt
                  ? dayjs(item.CreatedAt).format('MM/DD/YYYY')
                  : '',
                Multiple_Unit: item.Multiple_Unit ? item.Multiple_Unit : [],
                search: item.Name,
                ProductId: item?.ProductID
              }
            }
          })
        })
        //setCategoryDropDown(temp)
        setData(da.flat().flat())

        setIsLoading(false)
        setBackupData(da.flat().flat())
      }
    }
  }, [dynamoData])

  useEffect(() => {
    searchedValue?.length > 0 && handleSearch(searchedValue)
  }, [backupData])

  const AddNewSubCategory = () => {
    let subcat = {
      restid: parseInt(RestID),
      catId: parseInt(popupData.category.split('~')[1]),
      catName: popupData.category.split('~')[0],
      subCatName: newSubCategoryName
    }
    mainAxios.post('api/Inventory/addSubCategory', subcat).then(res => {
      if (res.data == 1) {
        toast.success('Sub Category Added Successfully')
        getSubCategories()
      }
    })
    const checkSubCategories = subCategories.filter(
      e =>
        e.label.toString().toUpperCase().trim() ===
        newSubCategoryName.toString().toUpperCase().trim()
    )
    if (checkSubCategories.length === 0) {
      setSubCategories(prevState => [
        ...prevState,
        { label: newSubCategoryName, value: newSubCategoryName }
      ])
      setNewSubCategoryName('')
    } else {
    }

    setIsModalOpenCategory(false)
  }

  return (
    <>
      {/* {progressBar && (
          <CircularProgress
            className='ExceptionalCircularProgress'
            color='success'
            disableShrink
            style={{ margin: '0 auto', display: 'block' }}
          />
        )} */}
      {nodata && (
        <div
          className=''
          style={{
            height: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img style={{ width: '5em' }} src='/no-item.png' />
            <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
          </div>
        </div>
      )}
      <div className='flexsearchbut'>
        <div className='searchbarpro'>
          {isTableview && (
            <Input
              placeholder='Search'
              style={{ width: '280px' }}
              value={searchedValue}
              onChange={e => {
                handleSearch(e.target.value)
              }}
              prefix={
                <SearchOutlined
                  style={{ fontSize: '20px', color: '#D3D2D6' }}
                />
              }
            />
          )}
        </div>
        <div className='newcountbuttondot'>
          <Button
            style={{
              background: '#22AF41',
              color: '#fff',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              setPopupData(initialPopupData)
              setBackupPopupData(initialPopupData)
              setEditOrCreate('Create New Item')
              showModal()
              setData1([{}])
              setUnitDropDown(dropDownValue)
              setBackupsaveData([])
              setCounter(0)
            }}
            icon={<PlusCircleFilled />}
            disabled={Budget_Exist_Status == 0 ? true : false}
          >
            New Item
          </Button>
          <Dropdown
            menu={{
              items
            }}
          >
            <div
              style={{
                borderRadius: '3px',
                border: '1px solid #C4C8CB',
                padding: '0px 5px',
                height: '32px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <a onClick={e => e.preventDefault()}>
                <FaEllipsisV style={{ color: '#C4C8CB', marginTop: '-3px' }} />
              </a>
            </div>
          </Dropdown>
        </div>
      </div>
      <div
        className='overallhistory product-table'
        style={{ paddingBottom: '25px' }}
      >
        <>
          {' '}
          {Budget_Exist_Status == 0 ? (
            <div
              className='no-inventory'
              style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '5em' }} src='/no-item.png' />
                <h2 style={{ fontSize: '18px' }}>No Items Found</h2>
                {/* <Typography style={{fontSize:'14px'}}>Start adding your products to streamline your Inventory Management.</Typography> */}
              </div>
            </div>
          ) : (
            <ThemeProvider theme={customTheme}>
              {isTableview && <MaterialReactTable table={table} />}
            </ThemeProvider>
          )}
        </>
      </div>
      {Object.keys(rowSelection).length !== 0 &&
        !isModalOpendelete &&
        !isModalOpen && (
          <div
            style={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
              backgroundColor: '#fff',
              marginLeft: '-40px',
              zIndex: '99999'
            }}
          >
            <div className='onetwoline'>
              <div
                className='footercountsheet'
                style={{ justifyContent: 'flex-end' }}
              >
                <div className='threebuttons'>
                  <Button
                    style={{
                      border: '1px solid #2BAB47',
                      backgroundColor: '#2BAB47',
                      color: '#fff'
                    }}
                    loading={addMasterSheetLoader}
                    onClick={() => {
                      handleMultipleCheckBox()
                    }}
                  >
                    Add to Master Sheet
                  </Button>
                  <Button
                    style={{
                      border: '1px solid #DDD',
                      backgroundColor: '#F5F5F5',
                      color: '#212529'
                    }}
                    onClick={() => {
                      setRowSelection({})
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      <Modal
        title='Set Up Your Budget'
        open={IsModalOpenbudget}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprodbudget}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Please establish a budget to activate the Inventory Feature.
        </p>
      </Modal>
      <Modal
        className='Item-popup'
        // title={}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        <div className='Item-popup-child'>
          <div className='item-modal-header'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <p className='item-modal-title'>{editOrCreate}</p>
              <CancelIcon
                onClick={handleCancel}
                style={{ color: '#acacac', fontSize: '20px' }}
              />
            </div>
          </div>
          <div className='item-modal-body'>
            <div className='col-md-12'>
              <div className='row' style={{ marginTop: '3.5em' }}>
                <div className='col-md-6'>
                  <p className='poptext'>Item Name</p>
                  <Input
                    value={popupData.itemName}
                    onChange={e => {
                      PoupuponChange('itemName', e.target.value)
                      setIsError(false)
                    }}
                  />
                  {isError && popupData?.itemName?.length === 0 && (
                    <p style={{ color: 'red', margin: 0 }}>
                      Please Enter Valid Item Name
                    </p>
                  )}
                  {isError &&
                    isItemExist &&
                    popupData?.itemName?.length > 0 && (
                      <p style={{ color: 'red' }}>Item Name Already Exists</p>
                    )}
                </div>
                <div className='col-md-6'>
                  <p className='poptext'>Category</p>
                  <Select
                    style={{
                      width: '100%'
                    }}
                    placeholder='Select'
                    required
                    value={
                      editOrCreate !== 'Create New Item'
                        ? popupData.category?.split('~')[0]
                        : popupData.category
                    }
                    onChange={e => PoupuponChange('category', e)}
                    options={categoryDropdown}
                    disabled={editOrCreate === 'Edit Item'}
                  />
                  {isError && popupData?.category?.length === 0 && (
                    <p style={{ color: 'red', margin: 0 }}>
                      Please Select Valid Category
                    </p>
                  )}
                </div>
              </div>
              <div className='row' style={{ marginTop: '10px' }}>
                <div className='col-md-4'>
                  <p className='poptext'>Sub-category</p>
                  <Select
                    placeholder={
                      popupData?.category.length === 0
                        ? 'Please Select Category'
                        : subCategories.length > 0
                        ? 'Select'
                        : 'Add Sub-category'
                    }
                    style={{
                      width: '100%'
                    }}
                    disabled={popupData?.category.length === 0}
                    required
                    value={
                      editOrCreate !== 'Create New Item'
                        ? popupData.subCategory
                        : popupData.subCategory
                    }
                    onChange={e => PoupuponChange('subCategory', e)}
                    options={subCategories}
                  />
                  {/* {isError && popupData?.subCategory?.length === 0 && (
                    <p style={{ color: 'red', margin: 0 }}>
                      Please Select Valid Sub-category
                    </p>
                  )} */}
                </div>
                <div className='col-md-4'>
                  <p className='poptext'>Unit</p>
                  <Select
                    placeholder='Select'
                    style={{
                      width: '100%'
                    }}
                    required
                    value={
                      editOrCreate !== 'Create New Item'
                        ? popupData.unit
                        : popupData.unit
                    }
                    onChange={e => PoupuponChange('unit', e)}
                    options={UnitDropDown}
                  />
                  {isError && popupData?.unit?.length === 0 && (
                    <p style={{ color: 'red', margin: 0 }}>
                      Please Select Valid Unit
                    </p>
                  )}
                </div>
                <div className='col-md-4'>
                  <p className='poptext'>Unit Price</p>
                  <div className='customInputOuter'>
                    <NumericFormat
                      className='customInput'
                      name='unitPrice'
                      value={popupData.unitPrice?.toString().replace('$', '')}
                      onKeyDown={handleKeyDown}
                      onValueChange={e => PoupuponChange('unitPrice', e.value)}
                      onBlur={e => {
                        e.target.value?.length === 0 &&
                        popupData.unitPrice.toString().replace('$', '')
                          ?.length === 0
                          ? formatUnitPrice('0')
                          : formatUnitPrice(
                              popupData.unitPrice.toString().replace('$', '')
                            )
                      }}
                    />
                  </div>
                  {isError &&
                    popupData?.unitPrice?.toLocaleString()?.length === 0 && (
                      <p style={{ color: 'red' }}>
                        Please Enter Valid Unit Price
                      </p>
                    )}
                </div>
                {/* <div className='col-md-4'>
                  <Input
                    disabled={popupData?.category.length === 0}
                    value={newSubCategoryName}
                    onChange={e => {
                      setNewSubCategoryName(e.target.value)
                    }}
                  />
                  <button
                    disabled={newSubCategoryName.length === 0}
                    onClick={AddNewSubCategory}
                  >
                    add
                  </button>
                </div> */}
              </div>
              <div className='row' style={{ marginTop: '2px' }}>
                <div className='col-md-12'>
                  <div
                    style={{ display: 'flex', gap: '128px', marginTop: '5px' }}
                  >
                    <Button
                      className='unit-btn'
                      type='text'
                      style={{ color: '#19A73F', fontSize: '14px' }}
                      disabled={popupData?.category.length === 0}
                      onClick={showCategorypopup}
                      icon={<PlusCircleFilled style={{ cursor: 'pointer' }} />}
                    >
                      Sub-category
                    </Button>
                    <Button
                      className='unit-btn'
                      type='text'
                      style={{ color: '#19A73F', fontSize: '14px' }}
                      onClick={addnewRow}
                      disabled={popupData.unit == ''}
                      icon={<PlusCircleFilled style={{ cursor: 'pointer' }} />}
                    >
                      Add Inventory Units
                    </Button>
                  </div>
                </div>
              </div>
              <div className='row' style={{ marginTop: '10px' }}>
                {editOrCreate === 'Create New Item' && (
                  <>
                    <div
                      style={{
                        padding: '0',
                        marginTop: '-10px',
                        display: 'flex'
                      }}
                    >
                      <Checkbox
                        onChange={handleCheckboxOnChange}
                        checked={popupData?.IsInMaster}
                      >
                        {popupData?.IsInMaster
                          ? 'Remove From Master CountSheet'
                          : 'Add Item to Master Sheet'}
                      </Checkbox>
                      <span style={{ marginTop: '8px' }}>
                        Add Item to Master Sheet
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className='row' style={{ marginTop: '10px' }}>
                <div className='col-md-12'>
                  {allowrow && (
                    <div className='table-div'>
                      <MaterialReactTable table={table1} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='item-modal-footer'>
            <div
              className='drawer-btn'
              style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
            >
              <Button
                style={{
                  backgroundColor: 'rgb(239 239 239)',
                  color: '#000',
                  borderRadius: '3px',
                  border: '0.5px solid #b2b2b2'
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                key='submit'
                style={{ backgroundColor: '#2BAB47', color: '#fff' }}
                disabled={isClicked}
                onClick={handleOk}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className='delete-popup'
        title='Create Sub-category'
        open={isModalOpencategory}
        onOk={handleOkcategory}
        onCancel={handleCancelcategory}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancelcategory}
            >
              Cancel
            </Button>
            <Button
              disabled={newSubCategoryName?.toString()?.trim().length === 0}
              onClick={AddNewSubCategory}
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
            >
              Save
            </Button>
          </div>
        ]}
      >
        <Input
          disabled={popupData?.category.length === 0}
          value={newSubCategoryName}
          placeholder='Enter Sub-category'
          onChange={e => {
            setNewSubCategoryName(e.target.value)
          }}
          style={{ width: '90%', margin: '0 auto', display: 'block' }}
        />
      </Modal>

      <Modal
        className='delete-popup'
        title='Delete Item'
        open={isModalOpendelete}
        onOk={handleOkdelete}
        onCancel={handleCanceldelete}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCanceldelete}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={handleOkdelete}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>Confirm deletion of the item?</p>
      </Modal>

      <Modal
        className='delete-popup1'
        title={stsText[1]}
        open={isModalOpenstatus}
        onOk={handleOkstatus}
        onCancel={handleCancelstatus}
        maskClosable={false}
        footer={[
          <div
            className='drawer-btn'
            style={{ display: 'flex', gap: '10px', justifyContent: 'end' }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(239 239 239)',
                color: '#000',
                borderRadius: '3px',
                border: '0.5px solid #b2b2b2'
              }}
              onClick={handleCancelstatus}
            >
              Cancel
            </Button>
            <Button
              key='submit'
              style={{ backgroundColor: '#2BAB47', color: '#fff' }}
              onClick={handleOkstatus}
            >
              Confirm
            </Button>
          </div>
        ]}
      >
        <p className='addnewcount1'>{stsText[0]}</p>
      </Modal>
      <Modal
        title='Add Inventory Products'
        open={IsModalOpentwo}
        onOk={handleOkprod}
        onCancel={handleCancelprod}
        maskClosable={false}
        footer={[
          <Button
            key='submit'
            onClick={handleOkprod}
            style={{ backgroundColor: '#2BAB47', color: '#fff' }}
          >
            Got It!
          </Button>
        ]}
      >
        <p className='addnewcount1'>
          Consider uploading recent invoices to assist in setting up your
          inventory.
        </p>
      </Modal>
      <div
        style={{
          position: 'absolute',
          left: '-5000px',
          top: '-5000px',
          overflow: 'hidden',
          width: '0',
          height: '0'
        }}
      >
        <Print ref={printRef} productsData={backupData} />
      </div>
    </>
  )
}

export default Products
